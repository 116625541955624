import React, { useState } from "react";

import Modal from "react-modal";

import { renderButton } from "@shared/FormUtils";

import CustomReportFieldSelect from "./CustomReportFieldSelect";
import CustomReportKeys from "./CustomReportKeys";

Modal.setAppElement("#root");

const CustomReportFieldsModal = (props) => {
  const { keys, selectedKeys, keySelected, updateKeys } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    if (typeSelected()) {
      setModalOpen(!modalOpen);
    } else {
      alert("Keys not found.");
    }
  };

  const typeSelected = () => {
    return keys && Object.keys(keys).length > 0;
  };

  const massKeySelection = (keys, selectAll) => {
    let newSelectedKeys = selectedKeys;
    if (selectAll) {
      keys.forEach((key) => {
        if (!selectedKeys.some((e) => e.value === key.value)) {
          newSelectedKeys.push(key);
        }
      });
    } else {
      keys.forEach((key) => {
        if (selectedKeys.some((e) => e.value === key.value)) {
          newSelectedKeys = newSelectedKeys.filter(function (e) {
            return e.value !== key.value;
          });
        }
      });
    }
    updateKeys(newSelectedKeys);
  };

  const renderModal = () => {
    return (
      <>
        <div className="report-columns-modal-container">
          <div className="report-columns-modal-key-select">
            <div className="report-columns-modal-header">
              <h3>Add Columns</h3>
            </div>
            <CustomReportFieldSelect
              selectedKeys={selectedKeys}
              options={keys}
              onChange={(key) => {
                keySelected(key);
              }}
              massKeySelection={massKeySelection}
            />
            <div className="ml-2 mt-2">
              <div>
                <span onClick={() => massKeySelection(keys, true)} className="sg-mgmt-link cursor-pointer">
                  Check All
                </span>
              </div>
              <div>
                <span onClick={() => updateKeys([])} className="sg-mgmt-link cursor-pointer">
                  Uncheck All
                </span>
              </div>
            </div>
          </div>
          <div className="report-columns-modal-keys">
            <div className="report-columns-modal-header">
              <h3>Selected Columns</h3>
              <p>You can drag the fields to sort them in the order you want the columns to appear.</p>
            </div>
            <CustomReportKeys keys={selectedKeys} updateKeys={updateKeys} selectedKeys={selectedKeys} />
            <div className="mt-2">
              {renderButton("Continue", toggleModal, {
                color: "secondary"
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="report-tool-modifier-link report-tool-edit-link" onClick={toggleModal} disabled={typeSelected()}>
        Add Columns
      </div>
      <Modal
        className="white-background report-modal report-columns-modal disable-scrollbars lg:ml-[100px]"
        overlayClassName="report-modal-overlay"
        style={{}}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        contentLabel=""
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default CustomReportFieldsModal;
