// Positive integer-only Formik field

import React from "react";
import { FastField, useField, useFormikContext } from "formik";

const IntegerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const regex = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const onChange = ele => {
    const { value } = ele.target;
    if (regex.test(value)) {
      setFieldValue(field.name, value);
    }
  };

  return (
    <FastField
      {...field}
      {...props}
      type="number"
      value={field.value || 0}
      onChange={onChange}
    />
  );
};

export default IntegerField;
