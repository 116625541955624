import React from "react";
import EventDetailsIntegrationsMarketo from "./marketo/EventDetailsIntegrationsMarketo";

const EventDetailsIntegrations = () => {
  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <h1>Integrations</h1>
      <p>Marketo:</p>
      <EventDetailsIntegrationsMarketo />
    </div>
  );
};

export default EventDetailsIntegrations;
