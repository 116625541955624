import React from "react";
import PropTypes from "prop-types";

import { useTheme } from '@mui/material/styles';
import clsx from "clsx";

const GrowlTableStatic = props => {
  const {
    columns,
    items,
  } = props;
  const muiTheme = useTheme();

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          {columns.map(col => {
            const align = (col === "Actions") ? "text-right" : "text-left";
            return (
              <th
                className={clsx("py-4 px-4 w-1/6 text-white bg-ui-6 text-sm", align)}
                key={`header-${col}`}
              >
                {col}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  };

  const renderItems = () => {
    return (
      <tbody>
        {items.map(item => (
          <tr
            key={item.key}
          >
            {item.cells.map((cell, idx) => (
              <td
                className="py-4 px-4 border-t text-sm"
                key={`${item.key}-cell-${idx}`}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <table
      className="w-full table-fixed"
      style={{
        fontFamily: muiTheme.typography.fontFamily,
        fontSize: muiTheme.typography.fontSize
      }}
    >
      {renderHeader()}
      {renderItems()}
    </table>
  );
};

GrowlTableStatic.propTypes = {
  columns: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired
};

export default GrowlTableStatic;
