import React, { useContext, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import {
  renderCancelButton,
  renderDateField,
  renderSelectField,
  renderTextField,
  renderTextAreaField,
  renderIntegerField,
  renderSubmitButton
} from "@shared/FormUtils";
import Loading from "@shared/Loading";

const TicketingTicketTypeForm = ({ ticketTypeId, cancel, callbackSuccess, callbackFailure }) => {
  const [ticketType, setTicketType] = useState(null);
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return ticketTypeId;
  };

  useEffect(() => {
    const fetchTicketType = async () => {
      try {
        if (ticketTypeId) {
          const result = await axios(urljoin(apiRoot, `ticketing/ticket_types/${ticketTypeId}`));
          setTicketType(result.data["ticket_type"]);
        } else {
          const result = await axios(urljoin(apiRoot, `ticketing/ticket_types/new`));
          setTicketType(result.data["ticket_type"]);
        }
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTicketType();
  }, []);

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-forms-edit",
        formUrl: urljoin(apiRoot, "/ticketing/ticket_types", `/${ticketTypeId}`),
        method: "PATCH",
        saveButton: "Save",
        buttonProperties: { color: "primary" },
        title: "Edit " + ((ticketType || {}).name || "")
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-forms-add",
      formUrl: urljoin(apiRoot, "/ticketing/ticket_types/"),
      method: "POST",
      saveButton: "Create",
      buttonProperties: { color: "secondary" },
      title: "Create New Ticket Type"
    };
  })();

  const ticketTypeInitialValues = () => {
    return {
      archived: ticketType.archived,
      end_datetime: ticketType.end_datetime,
      name: ticketType.name,
      sort_order: ticketType.sort_order,
      start_datetime: ticketType.start_datetime,
      total_count_type: ticketType.total_count_type,
      total_count: ticketType.total_count,
      notes: ticketType.notes
    };
  };

  const formValidation = (values) => {
    const errors = {};
    if (!values.ticket_type.name) {
      alert("You must enter a unique ticket name.");
      errors.ticket_type = errors.ticket_type || {};
      errors.ticket_type.name = "Required";
    }

    if (
      values.ticket_type.ticket_count_type == "limited" &&
      (isNaN(values.ticket_type.total_count) || values.ticket_type.total_count < 1)
    ) {
      alert("You must enter a total available ticket count.");
      errors.ticket_type = errors.ticket_type || {};
      errors.ticket_type.total_count = "Required";
    }
    console.log({
      errors
    });
    return errors;
  };

  const renderFormFields = (formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Ticket Name", "ticket_type[name]", [], false, true)}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Ticket Count",
                "ticket_type[total_count_type]",
                [
                  { value: "unlimited", label: "Unlimited" },
                  { value: "limited", label: "Limited" }
                ],
                false,
                true
              )}
            </div>
          </div>
          {formikProps.values.ticket_type.total_count_type == "limited" ? (
            <div className="flex">
              <div className="mr-4 w-full">
                {renderIntegerField(
                  "Total Available Tickets",
                  "ticket_type[total_count]",
                  [],
                  0,
                  1,
                  10000000,
                  false,
                  true
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="flex">
            <div className="mr-4 w-1/2">{renderDateField("Start Date (optional)", "ticket_type[start_datetime]")}</div>
            <div className="mr-4 w-1/2">{renderDateField("End Date (optional)", "ticket_type[end_datetime]")}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">{renderTextAreaField("Description", "ticket_type[notes]")}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          ticket_type: ticketTypeInitialValues()
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={(values, { setSubmitting }) => {
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: values
          })
            .then((response) => {
              console.log({
                response
              });
              if (response.data.error == null) {
                callbackSuccess ? callbackSuccess(response) : () => {};
                alertSuccess("Ticket Type Saved");
                setTicketType(response.data.ticket_type);
                setSubmitting(false);
              } else {
                callbackFailure ? callbackFailure(response) : () => {};
                alertError(`Unable to save ticket type: ${response.data.error}`);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting, formConfig.buttonProperties)}
        {renderCancelButton("Cancel", cancel, formConfig.buttonProperties)}
      </Stack>
    );
  };

  return (
    <div className="sg-mgmt-modal-frame">
      <div className={`sg-mgmt-modal-title ${isEdit() ? "sg-mgmt-modal-title-edit" : ""}`}>{formConfig.title}</div>
      <div className="sg-mgmt-modal-content">
        <div>{ticketType ? renderForm() : <Loading />}</div>
      </div>
    </div>
  );
};

export default TicketingTicketTypeForm;
