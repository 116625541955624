import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import { alertError, alertHttpError } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import EventContext from "@event/EventContext";
import { renderButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const EventMeetings1On1HostsAdd = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { hosts, update } = props;
  const [fetched, setFetched] = useState(false);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "participants"));
        setParticipants(result.data.participants);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchParticipants();
  }, []);

  const renderHostSelectOptions = () => {
    if (fetched) {
      const placeholder = [{ label: "Add meeting host", value: 0 }];
      const existingHostIds = hosts.map(host => host.event_participant.id);
      const filteredParticipants = sortBy(
        participants.filter(part => !existingHostIds.includes(part.id)),
        ["name_last", "name_first"]
      );

      return placeholder.concat(
        filteredParticipants.map(part => ({
          label: `${part.name_first} ${part.name_last}`,
          value: part.id
        }))
      );
    }
    return [];
  };

  const renderAddButton = (id, isSubmitting) => {
    if (id > 0) {
      return (
        renderButton("Add", ()=>{}, {
          type: "submit",
          disabled: isSubmitting
        })
      );
    }
    return <></>;
  };

  return (
    <div>
      <Formik
        initialValues={{
          event_participant_id: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(
            "sg-mgmt-meeting-host-select-form"
          );
          const formData = new FormData(form);

          // ignore submitting the placeholder text
          if (parseInt(formData.get("event_participant_id"), 10) === 0) {
            setSubmitting(false);
            return;
          }

          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: urljoin(apiRoot, "/meeting_hosts"),
            method: "POST",
            data: formData
          })
            .then(response => {
              if (response.data.error === null) {
                update(response.data.host);
                setSubmitting(false);
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch(error => {
              alertHttpError(error);
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id="sg-mgmt-meeting-host-select-form">
            <div className="sg-mgmt-meeting-host-select-container w-1/2">
              <Stack direction="row" spacing={2}>
                <SelectField
                  name="event_participant_id"
                  options={renderHostSelectOptions()}
                />
                {renderAddButton(values.event_participant_id, isSubmitting)}
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EventMeetings1On1HostsAdd.defaultProps = {
  hosts: []
};

EventMeetings1On1HostsAdd.propTypes = {
  hosts: PropTypes.array,
  update: PropTypes.func.isRequired
};

export default EventMeetings1On1HostsAdd;
