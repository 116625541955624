import React from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import CommunicationsEmailMailingListImportLog from "./CommunicationsEmailMailingListImportLog";

const CommunicationsEmailMailingListImportLogModal = props => {
  const { listId, modalClose, modalVisible } = props;

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Mailing List Import Log"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={modalClose}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Import Log</div>
        <div className="sg-mgmt-modal-content">
          <CommunicationsEmailMailingListImportLog
            back={modalClose}
            listId={listId}
          />
        </div>
      </div>
    </Modal>
  );
};

CommunicationsEmailMailingListImportLogModal.propTypes = {
  listId: PropTypes.number.isRequired,
  modalClose: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired
};

export default CommunicationsEmailMailingListImportLogModal;
