export default {
  attendee: {
    name_last: "",
    name_first: "",
    job_title: "",
    company: "",
    email: "",
    phone: "",
    address_street_1: "",
    address_street_2: "",
    address_city: "",
    address_state: "",
    address_postcode: ""
  },
  speaker: {
    bio: "",
    photo_url: "",
    data: {
      internal_external: "internal"
    }
  }
};
