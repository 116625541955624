import React, { useContext } from "react";
import { timeFormat, timeParse } from "d3-time-format";
import UserMenu from "@user/UserMenu";
import EventContext from "./EventContext";
import EventUserContext from "./EventUserContext";

const EventBanner = () => {
  const { event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const formatTime = timeFormat("%B %d, %Y");
  const parseTime = timeParse("%Y-%m-%d");

  const renderTile = () => {
    if (event.tile_url) {
      return (
        <div className="sg-mgmt-event-header-tile-container w-[200px] h-full">
          <img
            className="max-h-[154px] object-cover w-full"
            src={event.tile_url}
            alt={`${event.name} tile`}
          />
        </div>
      );
    }
    return (
      <div className="bg-neutral-500 sg-mgmt-event-header-tile-container w-[200px] h-full">
        
      </div>
    );
  };

  const renderDate = () => {
    if (event.date_begin) {
      if (event.date_end && event.date_end !== event.date_begin) {
        return `${formatTime(parseTime(event.date_begin))} - ${formatTime(
          parseTime(event.date_end)
        )}`;
      }
      return formatTime(parseTime(event.date_begin));
    }
    return "";
  };

  const renderHeaderText = () => (
    <div className="sg-mgmt-event-header-text h-[154px] flex center items-center ">
      <div>
        <div className="text-white text-[28px] font-normal font-['Roboto'] ">{event.name}</div>
        <div className="h-16 text-white text-base font-normal font-['Roboto']">
          <div>{renderDate()}</div>
          <div>{event.location}</div>
        </div>
      </div>
    </div>
  );

  const renderControls = () => {
    return (
      <div className="sg-mgmt-event-header-controls mt-[21px] mr-[28px]">
        <UserMenu />
      </div>
    );
  };

  return (
    <div
      className="sg-mgmt-event-header" 
      style={{ backgroundColor: event.color }}
    >
      {renderControls()}
      <div className="sg-mgmt-event-header-content">
        {renderTile()}
        {renderHeaderText()}
      </div>
    </div>
  );
};

export default EventBanner;
