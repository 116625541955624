import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";

import CustomReportCustomize from "./CustomReportCustomize";
import CustomReportSave from "./CustomReportSave";

const CustomReportEdit = (props) => {
  const { goBack, reportId, refreshReports } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [filterChains, setFilterChains] = useState([]);
  const [initialFilterChains, setInitialFilterChains] = useState([]);
  const [reportName, setReportName] = useState("");
  const [sortingCriteria, setSortingCriteria] = useState([]);
  const [report, setReport] = useState({});
  const [phase, setPhase] = useState(1);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [objectKeys, setObjectKeys] = useState({});

  useEffect(() => {
    const fetchReport = async () => {
      try {
        console.log("Fetching report");
        const result = await axios(urljoin(apiRoot, "/reports", `/${reportId}`));
        setReport(result.data.report);
        setObjectKeys(result.data.field_map);
        loadSelectedKeys(result.data.report.custom_report_fields, result.data.field_map);
        loadFilterChains(result.data.report.custom_report_filter_chains);
        setReportName(result.data.report.name);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchReport();
  }, [apiRoot, reportId]);

  useEffect(() => {
    const fetchFieldKeys = async () => {
      try {
        console.log("Fetching field map");
        const result = await axios(urljoin(apiRoot, "/reports/field_map", `/${report.report_type}`));
        setObjectKeys(result.data.objectKeys);
      } catch (error) {
        alertHttpError(error);
      }
    };

    if (report && report.id) {
      fetchFieldKeys();
    }
  }, [apiRoot, report, report.id]);

  const loadSelectedKeys = (fields, fieldMap) => {
    const newKeys = [];
    fields.forEach((field) => {
      if (fieldMap[field.key] !== undefined) {
        const key = {
          id: field.key,
          value: field.key,
          label: fieldMap[field.key].label
        };
        newKeys.push(key);
      }
    });
    setSelectedKeys(newKeys);
  };

  const loadFilterChains = (chains) => {
    const newChains = [];
    for (const chain of chains) {
      const chn = {
        count: chain.custom_report_filters.length,
        filters: loadFilters(chain.custom_report_filters)
      };
      newChains.push(chn);
    }
    setFilterChains(newChains);
    setInitialFilterChains(newChains);
  };

  const loadFilters = (filters) => {
    const newFilters = [];
    for (const filter of filters) {
      const flt = {
        operation: { value: filter.operation },
        operationValue: filter.criterion,
        selectedOption: {
          value: filter.custom_report_field.key
        }
      };
      newFilters.push(flt);
    }
    return newFilters;
  };

  const updateSelectedKeys = (keys) => {
    // force new array to avoid problems with shallow equality preventing re-renders
    setSelectedKeys([...keys]);
  };

  const submitPhase1 = () => {
    console.log("phase 1 submitted");
    setPhase(2);
  };

  const submitPhase2 = () => {
    console.log("phase 2 submitted");
    saveReport();
  };

  const saveReport = () => {
    console.log("Updating report...");
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const keys = selectedKeys.map((key) => key.value);
    axios({
      url: urljoin(apiRoot, "/reports", `/${reportId}`),
      method: "PATCH",
      data: {
        report_name: reportName,
        keys: keys,
        filter_chains: filterChains,
        sorting_criteria: sortingCriteria
      }
    }).then((response) => {
      if (response.data.error === null) {
        console.log("Report updated successfully");
        refreshReports();
        goBack();
      } else {
        console.log(`Error updating report: ${response.data.error}`);
      }
    });
  };

  switch (phase) {
    case 1:
      return (
        <CustomReportCustomize
          edit={true}
          goBack={goBack}
          initialFilterChains={initialFilterChains}
          filterChains={filterChains}
          objectKeys={objectKeys}
          report={report}
          selectedKeys={selectedKeys}
          setFilterChains={setFilterChains}
          setSelectedKeys={updateSelectedKeys}
          setSortingCriteria={setSortingCriteria}
          sortingCriteria={sortingCriteria}
          submit={submitPhase1}
        />
      );
    case 2:
      return <CustomReportSave reportName={reportName} setReportName={setReportName} submit={submitPhase2} />;
    default:
      throw new Error("Invalid phase");
  }
};

export default CustomReportEdit;
