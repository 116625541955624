import React, { useContext } from "react";
import PropTypes from "prop-types";

import {
  Chip,
  Stack
} from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import pickBy from "lodash/pickBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import CheckFieldSmall from "@shared/CheckFieldSmall";
import CsrfToken from "@shared/CsrfToken";
import SelectField from "@shared/forms/SelectField";
import {
  renderCancelButton,
  renderRichTextAreaField,
  renderSelectField,
  renderSubmitButton,
  renderTextField,
  renderButton
} from "@shared/FormUtils";

import EventSessionBlankSession from "../EventSessionBlankSession";
import EventSessionContext from "../EventSessionContext";

const EventSessionFormGeneralInfo = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { config, session, tags, types } = useContext(EventSessionContext);
  const { callbackFailure, callbackSuccess, cancelButton } = props;

  const isEdit = () => {
    return session && session.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-session-edit",
        formUrl: urljoin(apiRoot, "/sessions", `/${session.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit Session"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-session-add",
      formUrl: urljoin(apiRoot, "/sessions"),
      method: "POST",
      saveButton: "Continue",
      title: "Add Session"
    };
  })();

  const renderRichTextArea = (label, field, formatClasses = []) => {
    if (config[`enabled_${field}`]) {
      return renderRichTextAreaField(label, `session[${field}]`, formatClasses);
    }
    return <></>;
  };

  const renderConfiguredField = (label, field, formatClasses = []) => {
    if (config[`enabled_${field}`]) {
      return (
        <div className="sg-mgmt-form-row">
          {renderTextField(label, `session[${field}]`, formatClasses)}
        </div>
      );
    }
    return <></>;
  };

  // const renderSelect = (label, field, formatClasses = []) => {
  //   if (config[`enabled_${field}`]) {
  //     const options = config[`options_${field}`].split("\n");
  //     return renderSelectField(
  //       label,
  //       `session[${field}]`,
  //       options,
  //       formatClasses
  //     );
  //   }
  //   return <></>;
  // };

  const renderTracks = (formatClasses = []) => {
    if (config.enabled_track) {
      const tracks = config.options_track.split("\n").filter(track => track);
      return (
        <div
          className={clsx("sg-mgmt-form-input-container", formatClasses)}
        >
          <label>Session Tracks</label>
          {tracks.map(track => (
            <div key={track} className="sg-mgmt-form-sessions-track">
              <CheckFieldSmall
                fieldName={`session[session_tracks][${track}]`}
                label={track}
              />
            </div>
          ))}
        </div>
      );
    }
    return <></>;
  };

  const renderType = (formatClasses = []) => {
    const sessionTypes = types || [];
    const opts = sessionTypes.map(ele => ({
      label: ele.name,
      value: ele.id
    }));
    opts.unshift({ label: "", value: null });
    return (
      <div
        className={clsx("sg-mgmt-form-input-container", formatClasses)}
      >
        {renderSelectField(
          "Session Type",
          "session[session_type_id]",
          opts,
          formatClasses
        )}
      </div>
    );
  };

  const renderTagSelect = (values, setFieldValue) => {
    const options = tags.filter(x =>
      Object.keys(values.session.tags).filter(key =>
        values.session.tags[key]
      ).indexOf(x.name) === -1
    ).map(tag => ({
      label: tag.name,
      value: tag.name
    }));
    return (
      <Stack spacing={2} direction="row">
        <SelectField disableLabel={true} name={"tag_add"} options={options} />
        {renderTagAddButton(values, setFieldValue)}
      </Stack>
    );
  };

  const renderTagAddButton = (values, setFieldValue) => {
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return (
      renderButton("Add", () => { addTag(tag_add, setFieldValue, values) }, { disabled: disabled, color: "secondary" })
    );
  };

  const addTag = (tag, setFieldValue, values) => {
    tagFieldName = "session[tags]"
    setFieldValue(tagFieldName + `[${tag}]`, true)
    setFieldValue("tag_add", null)
  };

  const removeTag = (tag, setFieldValue) => {
    setFieldValue(`session[tags][${tag}]`, false)
  }

  const renderSelectedTags = (values, setFieldValue) => {
    console.log({
      values
    })
    return (
      <div className="flex mt-2">
        {Object.keys(values.session.tags).filter(key =>
          values.session.tags[key]
        ).map(tag => (
          <Chip
            key={tag}
            className="mr-2"
            color="primary"
            label={tag}
            onDelete={() => removeTag(tag, setFieldValue)}
          />
        ))}
      </div>
    );
  };


  const renderTags = (setFieldValue, values, formatClasses = []) => {
    return (
      <div
        className={clsx("sg-mgmt-form-input-container", formatClasses)}
      >
        <label>Tags</label>
        {renderTagSelect(values, setFieldValue)}
        {
          renderSelectedTags(values, setFieldValue)
        }
      </div>
    );
  };

  const initialSessionTrackValues = () => {
    const tracks = session.session_tracks;
    const trackHash = {};
    if (config.enabled_track) {
      tracks.forEach(track => {
        trackHash[track] = true;
      });
    }
    return trackHash;
  };

  const initialTagValues = () => {
    const sessionTags = session.session_tags;
    const tagHash = {};
    sessionTags.forEach(tag => {
      tagHash[tag] = true;
    });
    return tagHash;
  };

  const initialSessionTypeValue = () => {
    if (session.session_type && session.session_type.id) {
      return session.session_type.id;
    }
    return "";
  };

  const formInitialValues = () => {
    if (session) {
      return {
        title: session.title || "",
        code: session.code || "",
        session_type: session.session_type || "",
        track: session.track || "",
        description: session.description || "",
        length_minutes: session.length_minutes || 0,
        session_type_id: initialSessionTypeValue(),
        session_tracks: initialSessionTrackValues(),
        tags: initialTagValues()
      };
    }
    return EventSessionBlankSession;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          session: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          CsrfToken();
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);

          // fetch description
          formData.set("session[description]", values.session.description);

          const validTracks = config.options_track.split("\n");
          const selectedTracks = Object.keys(
            pickBy(values.session.session_tracks, (value, _key) => value)
          );
          const tracks = selectedTracks.filter(t => validTracks.includes(t));
          formData.set("session[session_tracks]", tracks);

          const selectedTags = Object.keys(
            values.session.tags
          ).filter(x => values.session.tags[x]);
          selectedTags.forEach(tag => {
            formData.append(`session[tags][]`, tag);
          })

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <h2>General Info</h2>
              <div className="sg-mgmt-form-row">
                {renderTextField("Title", "session[title]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderRichTextArea("Description", "description")}
              </div>
              {renderConfiguredField("Code", "code")}
              <div className="sg-mgmt-form-row">{renderType()}</div>
              <div className="sg-mgmt-form-row">{renderTracks()}</div>
              <div className="sg-mgmt-form-row">{renderTags(setFieldValue, values)}</div>
            </div>
            <Stack spacing={2} direction="row">
              {renderSubmitButton(formConfig.saveButton, isSubmitting, {
                color: "secondary"
              })}
              {renderCancelButton("Cancel", cancelButton, {
                color: "secondary"
              })}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

EventSessionFormGeneralInfo.defaultProps = {
  callbackFailure: () => { },
  callbackSuccess: () => { },
  cancelButton: () => { }
};

EventSessionFormGeneralInfo.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func
};

export default EventSessionFormGeneralInfo;
