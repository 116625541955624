import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";
import urljoin from "url-join";
import { renderTextField } from "@shared/FormUtils";
import EventContext from "@event/EventContext";
import SessionsBlankVenue from "./SessionsBlankVenue";
import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const SessionsVenueForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackFailure, callbackSuccess, cancelButton, venue } = props;
  const [image, setImage] = useState(null);
  const [imageDropped, setImageDropped] = useState(false);
  const [previewImageURL, setPreviewImageURL] = useState(null);
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";

  useEffect(() => {
    setPreviewImageURL(venue.image_url);
  }, [venue]);

  const isEdit = () => {
    return venue && venue.id;
  };

  const onDrop = acceptedFiles => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = e => {
        setImage(acceptedFiles[0]);
        setImageDropped(true);
        setPreviewImageURL(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setImage(acceptedFiles[0]);
      setImageDropped(true);
      setPreviewImageURL(null);
    }
  };

  const dropzoneFilename = () => {
    if (imageDropped) {
      return (
        <>
          File attached:
          <br />
          {image.name}
        </>
      );
    }
    return <></>;
  };

  const renderImagePreview = (header = false) => {
    const previewClass = header ? "sg-mgmt-form-tile-preview-header" : "";
    if (previewImageURL) {
      return (
        <div className="sg-mgmt-form-tile-preview-container">
          <img
            className={clsx("sg-mgmt-venue-image-preview", previewClass)}
            src={previewImageURL}
            alt="Venue Preview"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderImageUpload = () => {
    return (
      <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="event[tile]">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={clsx(
                "dropzone",
                "sg-mgmt-form-input-dropzone-tile",
                {
                  "dropzone--isActive": isDragActive
                }
              )}
            >
              <input {...getInputProps()} />
              <div className="sg-mgmt-form-input-dropzone-tile-prompt">
                Drag and Drop
                <br />
                or Click to Choose File
              </div>
              <div className="sg-mgmt-form-input-dropzone-tile-filename">
                {dropzoneFilename()}
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/venues", `/${venue.id}`),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit Field"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/venues"),
      method: "POST",
      saveButton: "Create",
      title: "Create Field"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: venue.name || "",
        address_street_1: venue.address_street_1 || "",
        address_street_2: venue.address_street_2 || "",
        address_city: venue.address_city || "",
        address_state: venue.address_state || "",
        address_postcode: venue.address_postcode || "",
        address_country: venue.address_country || "",
        phone: venue.phone || ""
      };
    }
    return SessionsBlankVenue;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          venue: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          if (image) {
            formData.set("venue[image]", image);
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Venue Name", "venue[name]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Address (line 1)", "venue[address_street_1]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Address (line 2)", "venue[address_street_2]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("City", "venue[address_city]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("State/Province", "venue[address_state]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("ZIP/Postal Code", "venue[address_postcode]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Country", "venue[address_country]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Phone Number", "venue[phone]")}
              </div>
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-input-container">
                  <label>Choose venue image</label>
                  {renderImageUpload()}
                  {renderImagePreview()}
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

SessionsVenueForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

SessionsVenueForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  venue: PropTypes.object.isRequired
};

export default SessionsVenueForm;
