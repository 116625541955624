import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import EventMeetings1On1RequestForm from "./form/EventMeetings1On1RequestForm";

const EventMeetings1On1RequestsModalAdd = props => {
  const { modalVisible, resetModal } = props;

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Add Meeting"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Add Request</div>
        <div className="sg-mgmt-modal-content">
          <EventMeetings1On1RequestForm
            {...props}
            cancel={resetModal}
            callbackSuccess={resetModal}
          />
        </div>
      </div>
    </Modal>
  );
};

EventMeetings1On1RequestsModalAdd.propTypes = {
  addRequest: PropTypes.func.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired
};

export default EventMeetings1On1RequestsModalAdd;
