import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchHousingBlocks = async (apiRoot) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, "/housing/blocks")
  });
};

const addHousingBlock = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/housing/blocks`),
    data: data
  });
};

const addHousingBlockRoomType = async (apiRoot, blockId, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/housing/blocks/${blockId}/add_type`),
    data: data
  });
};

const deleteHousingBlock = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/housing/blocks/${id}`),
  });
};

const deleteHousingBlockRoomType = async (apiRoot, blockId, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/housing/blocks/${blockId}/room_types/${id}`)
  });
};

const updateHousingBlock = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/blocks/${id}`),
    data: data
  });
};

const updateHousingBlockRoomTypeInventory = async (apiRoot, blockId, typeId, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/blocks/${blockId}/room_types/${typeId}/dates/update_all`),
    data: data
  });
};

export const useAddHousingBlock = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addHousingBlock(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBlocks", eventId]);
    }
  });
};

export const useAddHousingBlockRoomType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ blockId, data }) => addHousingBlockRoomType(apiRoot, blockId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBlocks", eventId]);
    }
  });
};

export const useDeleteHousingBlock = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteHousingBlock(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBlocks", eventId]);
    }
  });
};

export const useDeleteHousingBlockRoomType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ blockId, id }) => deleteHousingBlockRoomType(apiRoot, blockId, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBlocks", eventId]);
    }
  });
};

export const useHousingBlocks = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["housingBlocks", eventId],
    queryFn: () => fetchHousingBlocks(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useUpdateHousingBlock = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateHousingBlock(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBlocks", eventId]);
    }
  });
};

export const useUpdateHousingBlockRoomTypeInventory = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ blockId, typeId, data }) => updateHousingBlockRoomTypeInventory(apiRoot, blockId, typeId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingBlocks", eventId]);
    }
  });
};
