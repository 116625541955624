import React from "react";

import clsx from "clsx";

const ContentFrame = (props) => {
  const { className = "", id = "content-frame", children } = props;
  return (
    <div className={clsx("flex-1", "p-12", className)} id={id}>
      {children}
    </div>
  );
};

export default ContentFrame;
