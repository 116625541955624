import React from "react";
import PropTypes from "prop-types";
import GrowlModal from "@shared/GrowlModal";

const EventSessionTag = ({ deleteTag, setEditTag, tag }) => {
  return (
    <div
      className="flex-initial py-1 px-2 mr-2 mb-1 text-white bg-opacity-70 bg-sg-orange"
      key={`tag-${tag.gid}-${tag.id}`}
    >
      {tag.name}
      <div
        className="cursor-pointer"
        style={{ display: "inline", padding: "0 4px" }}
        onClick={() => {console.log(`edit tag ${tag.id}`); setEditTag(tag.id)}}
      >
        <i className="fas fa-light fa-pen" />
      </div>
      <GrowlModal
        content="Are you sure you want to delete this tag?"
        title="Delete Tag"
        trigger={
          <div className="inline-block ml-1 cursor-pointer">&times;</div>
        }
        actions={[
          {
            label: "Cancel",
            close: true
          },
          {
            label: "Delete",
            close: true,
            func: () => {
              deleteTag(tag.id);
            }
          }
        ]}
      />
    </div>
  );
};

EventSessionTag.propTypes = {
  deleteTag: PropTypes.func.isRequired,
  setEditTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired
};

export default EventSessionTag;
