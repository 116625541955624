import React, { useContext, useEffect, useState } from "react";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

const HousingReports = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [hotels, setHotels] = useState([]);
  const [pickerDateTime, setPickerDateTime] = useState(new Date());
  const [fetched, setFetched] = useState("index");

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/hotels"));
        setHotels(result.data.hotels);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHotels();
  }, [apiRoot]);

  const renderReports = () => {
    if (fetched) {
      return (
        <div>
          <div className="mb-4">
            <h2>Bookings</h2>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 pr-4">All Bookings</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/bookings`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
                {hotels.map((hotel) => (
                  <tr key={hotel.gid}>
                    <td className="w-1/3 pr-4">{hotel.name}</td>
                    <td>
                      <a
                        className="sg-mgmt-link"
                        href={`/reports/events/${event.slug}/housing/bookings/hotel/${hotel.id}`}
                        data-turbolinks="false"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <h2>Booking Changes (All)</h2>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 pr-4">Changes to Bookings</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/booking_changes`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 pr-4">Changes to Bookings (Exclude Registration Fields)</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/booking_changes_no_reg`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-4">
            <h2>Booking Changes (From Date)</h2>
            <div className="sg-mgmt-form-row w-full ">
              <div className="sg-mgmt-form-input-container">
                <label>Select Date for Start of Report</label>
                <DateTimePicker
                  className="sg-mgmt-form-input cursor-pointer"
                  dateFormat="MMMM d, yyyy - h:mm aa"
                  selected={pickerDateTime}
                  onChange={(val) => setPickerDateTime(val)}
                  size="small"
                  slotProps={{
                    textField: { size: "small" }
                  }}
                />
              </div>
            </div>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 pr-4">Changes to Bookings</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/booking_changes?from_date=${Math.floor(
                        pickerDateTime.getTime() / 1000
                      )}`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 pr-4">Changes to Bookings (Exclude Registration Fields)</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/booking_changes_no_reg?from_date=${Math.floor(
                        pickerDateTime.getTime() / 1000
                      )}`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <h2>Booking Changes (Post-Finalized)</h2>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 pr-4">Changes to Bookings</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/booking_changes_finalized`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 pr-4">Changes to Bookings (Exclude Registration Fields)</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/booking_changes_no_reg_finalized`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <h2>Occupancy and Availability</h2>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 pr-4">
                    Pick Up Report
                  </td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/pickup`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <h2>Room Types</h2>
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="w-1/3 pr-4">All Hotels</td>
                  <td>
                    <a
                      className="sg-mgmt-link"
                      href={`/reports/events/${event.slug}/housing/hotel/room_types`}
                      data-turbolinks="false"
                    >
                      Download
                    </a>
                  </td>
                </tr>
                {hotels.map((hotel) => (
                  <tr key={hotel.gid}>
                    <td className="w-1/3 pr-4">{hotel.name}</td>
                    <td>
                      <a
                        className="sg-mgmt-link"
                        href={`/reports/events/${event.slug}/housing/hotel/room_types/${hotel.id}`}
                        data-turbolinks="false"
                      >
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <PageHeader text="Housing Reports" />
      {renderReports()}
    </div>
  );
};

export default HousingReports;
