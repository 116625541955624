import React from "react";

import Modal from "react-modal";

import TicketingTicketTypeForm from "./TicketingTicketTypeForm";

const TicketingTicketTypeFormModal = (props) => {
  const { ticketTypeId, modalVisible, resetModal, callbackFailure, callbackSuccess } = props;

  Modal.setAppElement("#root");

  const cancelButton = () => {
    resetModal();
  };

  const renderTicketingForm = () => {
    return (
      <TicketingTicketTypeForm
        cancel={cancelButton}
        callbackSuccess={callbackSuccess}
        callbackFailure={callbackFailure}
        ticketTypeId={ticketTypeId}
      />
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Edit Ticket Type"
    >
      {modalVisible ? renderTicketingForm() : ""}
    </Modal>
  );
};

export default TicketingTicketTypeFormModal;
