import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

const EventMeetings1On1Analytics = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "meeting_hosts/stats"));
        setStats(result.data.stats);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchStats();
  }, [apiRoot]);

  const renderExecLeaderboard = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-1on1-analytics-leaderboard">
          <h2>Top Bookings</h2>
          <table className="sg-mgmt-1on1-analytics-table table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Meetings</th>
              </tr>
            </thead>
            <tbody>
              {stats.exec_leaderboard
                .filter(exec => exec.count > 0)
                .slice(0, 20)
                .map(exec => (
                  <tr key={exec.id}>
                    <td>{exec.name}</td>
                    <td>{exec.count}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <></>;
  };

  const renderSchedulerLeaderboard = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-1on1-analytics-leaderboard">
          <h2>Top Schedulers</h2>
          <table className="sg-mgmt-1on1-analytics-table table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Meetings</th>
              </tr>
            </thead>
            <tbody>
              {stats.scheduler_leaderboard
                .filter(sched => sched[1].count > 0)
                .slice(0, 20)
                .map(sched => (
                  <tr key={sched[0]}>
                    <td>{sched[1].name}</td>
                    <td>{sched[1].count}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <></>;
  };

  const renderTypeBreakdown = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-1on1-analytics-chart">
          <h2>Meeting Types</h2>
        </div>
      );
    }
    return <></>;
  };

  const renderCaseBreakdown = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-1on1-analytics-chart">
          <h2>Use Cases</h2>
        </div>
      );
    }
    return <></>;
  };

  const renderStats = () => {
    if (fetched) {
      return (
        <div>
          <div className="sg-mgmt-1on1-analytics-leaderboards">
            {renderExecLeaderboard()}
            {renderSchedulerLeaderboard()}
          </div>
          <div className="sg-mgmt-1on1-analytics-charts">
            {renderTypeBreakdown()}
            {renderCaseBreakdown()}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <PageHeader text="Analytics" />
      <div className="sg-mgmt-event-meetings-wrapper">{renderStats()}</div>
    </div>
  );
};

export default EventMeetings1On1Analytics;
