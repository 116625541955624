import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { useConfirm } from "material-ui-confirm";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import PeopleEventParticipantsModal from "@people/event_participants/PeopleEventParticipantsModal";
import PeopleEventParticipantsViewModal from "@people/event_participants/PeopleEventParticipantsViewModal";
import { alertError } from "@shared/Alerts";
import GrowlTable from "@shared/GrowlTable";

const TicketingParticipantPackagesTable = (props) => {
  const {
    columns,
    individuals,
    metadataFields,
    participants,
    participantPackages,
    refreshParticipants,
    regFields,
    tags,
    types,
    unassignPackage
  } = props;
  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const confirm = useConfirm();

  const [editParticipantId, setEditParticipantId] = useState(null);
  const [viewParticipantId, setViewParticipantId] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const addIndividual = () => {
    refreshParticipants.mutate();
  };

  const addParticipant = () => {
    refreshParticipants.mutate();
  };

  const updateParticipant = () => {
    refreshParticipants.mutate();
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.participants_edit) {
      return false;
    }
    return true;
  };

  const renderEditAction = (rowData) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(rowData.event_participant.id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderUnassignPackage = (rowData) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            unassignPackageClick(rowData);
          }}
        >
          Unassign Package
        </span>
      </>
    );
  };

  const unassignPackageClick = (rowData) => {
    confirm({
      title: "Confirm delete",
      description: "Are you sure you want to unassign this package?"
    })
      .then(() => {
        unassignPackage(rowData.id, rowData.event_participant_id);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const tableColumns = [
    ...columns,
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 200,
      getActions: (params) => [renderEditAction(params.row), renderUnassignPackage(params.row)]
    }
  ];

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const resetViewModal = () => {
    closeViewModal();
    setViewParticipantId(null);
  };

  const filteredIndividuals = () => {
    const participantIndividualIds = participants.map((p) => p.individual_id);
    return individuals.filter((ind) => !participantIndividualIds.includes(ind.id));
  };

  const mappedTicketParticipants = () => {
    return participantPackages.map((ticket) => {
      const participant = ticket.event_participant || {};
      ticket.role = participant.role;
      ticket.name_first = participant.name_first || "";
      ticket.name_last = participant.name_last || "";
      ticket.email = participant.email || "";
      ticket.company = participant.company || "";
      ticket.status = participant.status || "";
      ticket.test_flag = participant.test_flag || false;
      ticket.tags = participant.tags || "";
      return ticket;
    });
  };

  const renderAddModal = () => (
    <PeopleEventParticipantsModal
      apiRoot={apiRoot}
      closeModal={closeAddModal}
      addIndividual={addIndividual}
      individuals={filteredIndividuals()}
      metadataFields={metadataFields}
      regFields={regFields}
      modalVisible={addModalVisible}
      resetModal={resetAddModal}
      rootUrl={rootUrl}
      tags={tags}
      types={types}
      updateFunc={addParticipant}
    />
  );

  const renderViewModal = () => {
    if (!viewParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsViewModal
        apiRoot={apiRoot}
        participantId={viewParticipantId}
        metadataFields={metadataFields}
        modalVisible={viewModalVisible}
        regFields={regFields}
        resetModal={resetViewModal}
      />
    );
  };

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={addIndividual}
        individuals={[]}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metadataFields}
        regFields={regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={tags}
        types={types}
        updateFunc={updateParticipant}
      />
    );
  };

  return (
    <div>
      <GrowlTable
        columns={tableColumns}
        items={mappedTicketParticipants()}
        sortField="name_last"
        sortDirection="asc"
        tableName={`${event.slug}-participant-tickets`}
      />
      {renderAddModal()}
      {renderEditModal()}
      {renderViewModal()}
    </div>
  );
};

TicketingParticipantPackagesTable.propTypes = {
  columns: PropTypes.array.isRequired,
  individuals: PropTypes.array.isRequired,
  fetchParticipantPackages: PropTypes.func.isRequired,
  metadataFields: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  participantPackages: PropTypes.array.isRequired,
  refreshParticipants: PropTypes.object.isRequired,
  regFields: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  unassignPackage: PropTypes.func.isRequired
};

export default TicketingParticipantPackagesTable;
