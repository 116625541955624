import React from "react";

import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";

import HousingBlankBooking from "./HousingBlankBooking";
import HousingBookingForm from "./HousingBookingForm";

const HousingBookingModal = props => {
  const {
    blocks,
    booking = HousingBlankBooking,
    bookings,
    config,
    hotels,
    instructions,
    modalVisible,
    participants,
    resetModal,
    title
  } = props;

  Modal.setAppElement("#root");

  const success = () => {
    alertSuccess("Booking updated successfully");
    resetModal();
  };

  const failure = (error) => {
    alertError(error);
    resetModal();
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingBookingForm
            blocks={blocks}
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            config={config}
            booking={booking}
            bookings={bookings}
            hotels={hotels}
            instructions={instructions}
            participants={participants}
          />
        </div>
      </div>
    </Modal>
  );
};

export default HousingBookingModal;
