import React, { useContext } from "react";

import { Stack } from "@mui/material";
import { Formik, Form } from "formik";
import { useConfirm } from "material-ui-confirm";

import EventContext from "@event/EventContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import {
  renderCheckField,
  renderInfo,
  renderSelectField,
  renderSelectFieldFilterable,
  renderTextField,
  renderTextAreaField,
  renderDeleteButton,
  renderSubmitButton,
  renderCancelButton,
  renderDateField
} from "@shared/FormUtils";
import {
  useCancelHousingBooking,
  useDeleteHousingBooking,
  useUpdateHousingBooking
} from "@shared/hooks/useHousingBookings";
import { dateObjectFuckTimezones } from "@shared/TimeUtils";

import HousingBlankBooking from "./HousingBlankBooking";

const HousingBookingForm = (props) => {
  const {
    blocks,
    booking = HousingBlankBooking,
    bookings,
    callbackFailure = () => { },
    callbackSuccess = () => { },
    cancelButton = () => { },
    config,
    hotels,
    instructions,
    participants
  } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const confirm = useConfirm();
  const cancelHousingBooking = useCancelHousingBooking(apiRoot, event.id);
  const deleteHousingBooking = useDeleteHousingBooking(apiRoot, event.id);
  const updateHousingBooking = useUpdateHousingBooking(apiRoot, event.id);

  const isEdit = () => {
    return booking && booking.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        formId: "sg-mgmt-form-token-edit",
        saveButton: "Update"
      };
    }
    return {
      formId: "sg-mgmt-form-token-add",
      saveButton: "Create"
    };
  })();

  const defaultCheckInDate = () => {
    if (booking.check_in_date) {
      return dateObjectFuckTimezones(booking.check_in_date);
    }
    if (config.check_in_date_default) {
      return dateObjectFuckTimezones(config.check_in_date_default);
    }
    return "";
  };

  const defaultCheckOutDate = () => {
    if (booking.check_out_date) {
      return dateObjectFuckTimezones(booking.check_out_date);
    }
    if (config.check_out_date_default) {
      return dateObjectFuckTimezones(config.check_out_date_default);
    }
    return "";
  };

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        event_participant_gid: booking.event_participant.gid || "",
        room_block_id: booking.room_block_room_type.room_block_id || "",
        room_block_room_type_gid: booking.room_block_room_type.gid || "",
        hotel_id: booking.room_block_room_type.room_type.hotel_id || "",
        booking: {
          confirmation: booking.confirmation || "",
          paid: booking.paid || "",
          notes: booking.notes || "",
          notes_hotel: booking.notes_hotel || "",
          billing_instructions_other: booking.billing_instructions_other || "",
          billing_instruction_id: booking.billing_instruction_id || "",
          check_in_date: defaultCheckInDate(),
          check_out_date: defaultCheckOutDate(),
          test_flag: booking.test_flag || false
        }
      };
    }
    return {
      event_participant_gid: "",
      room_block_id: "",
      room_block_room_type_gid: "",
      booking: {
        confirmation: "",
        paid: "",
        notes: "",
        notes_hotel: "",
        billing_instructions_other: "",
        check_in_date: defaultCheckInDate(),
        check_out_date: defaultCheckOutDate(),
        test_flag: false
      }
    };
  };

  const performDelete = () => {
    const { id } = booking;
    deleteHousingBooking.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            alertSuccess("Booking deleted successfully");
            cancelButton();
          } else {
            alertError(data.error);
          }
        },
        onError: (error) => {
          alertError(error);
        }
      }
    );
  };

  const performCancel = () => {
    const { id } = booking;
    cancelHousingBooking.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            callbackSuccess();
          } else {
            alertError(data.error);
          }
        },
        onError: (error) => {
          alertError(error);
        }
      }
    );
  };

  const confirmDelete = () => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to delete this Booking? This will remove the booking record from the system. If you want to cancel a booking, use the Mark Cancelled button instead."
    })
      .then(() => {
        performDelete();
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const renderMarkDeleteButton = () => {
    if (isEdit()) {
      return renderDeleteButton("Delete", confirmDelete);
    }
    return <></>;
  };

  const renderMarkCancelButton = () => {
    if (isEdit()) {
      return renderDeleteButton("Mark Cancelled", performCancel);
    }
    return <></>;
  };

  const renderForm = () => {
    const assignedParticipants = bookings.map((bk) => bk.event_participant).map((part) => part.gid);

    const participantOptions = [
      {
        label: "Select participant",
        value: null
      },
      ...participants
        .filter((p) => !assignedParticipants.includes(p.gid))
        .map((part) => ({
          label: `${part.name_first} ${part.name_last}`,
          value: part.gid
        }))
    ];

    // const hotelOptions = [
    //   {
    //     label: "Select hotel",
    //     value: null
    //   },
    //   ...hotels
    //     .filter((h) => h.room_types.length > 0)
    //     .map((hotel) => ({
    //       label: hotel.name,
    //       value: hotel.id
    //     }))
    // ];

    const roomBlockOptions = [
      {
        label: "Select Room Block",
        value: null
      },
      ...blocks
        .sort((a, b) => a.name - b.name)
        .sort((a, b) => b.main - a.main)
        .map((blk) => ({
          label: blk.name,
          value: blk.id
        }))
    ];

    const renderParticipant = () => {
      if (isEdit()) {
        return renderInfo("Participant", booking.event_participant.name_full);
      }
      return renderSelectFieldFilterable("Participant", "event_participant_gid", participantOptions);
    };

    const renderBookingGid = () => {
      if (isEdit()) {
        return renderInfo("Booking GID", booking.gid);
      }
      return <></>;
    };

    const renderHotelAndRoomType = () => {
      if (isEdit()) {
        return (
          <div className="sg-mgmt-form-row">
            {renderInfo("Hotel and Room Type", `${booking.hotel_name}: ${booking.room_type_name}`)}
          </div>
        );
      }
      return <></>;
    };

    return (
      <Formik
        initialValues={formInitialValues()}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);

          if (
            values.room_block_id === "Select Room Block" ||
            values.room_block_id === "" ||
            values.room_block_id === null
          ) {
            setSubmitting(false);
            alertError("You must select a room block");
            return;
          }

          if (values.room_block_room_type_gid === null || values.room_block_room_type_gid === "") {
            setSubmitting(false);
            alertError("You must select a room type");
            return;
          }

          if (values.booking.test_flag === false) {
            formData.set("booking[test_flag]", false);
          }

          updateHousingBooking.mutate(
            {
              id: booking.id,
              data: formData
            },
            {
              onSuccess: () => {
                callbackSuccess();
              },
              onError: (error) => {
                callbackFailure(error);
                setSubmitting(false);
              }
            }
          );
        }}
      >
        {({ values, isSubmitting }) => {
          // const selectedHotelId = parseInt(values.hotel_id, 10);
          // const selectedHotel = hotels.find(h => h.id === selectedHotelId);
          const selectedInstructionId = parseInt(values.booking.billing_instruction_id, 10);
          const selectedInstruction = instructions.find((ele) => ele.id === selectedInstructionId);

          // let roomOptions = [];
          // if (selectedHotel) {
          //   roomOptions = selectedHotel.room_types.map(rt => ({
          //     label: rt.name,
          //     value: rt.gid
          //   }));
          // }

          const selectedRoomBlockId = parseInt(values.room_block_id, 10);
          const selectedRoomBlock = blocks.find((b) => b.id === selectedRoomBlockId);

          const roomMap = {};
          if (selectedRoomBlock) {
            selectedRoomBlock.room_block_room_types.forEach((rbrt) => {
              const hid = rbrt.room_type.hotel_id;
              if (!roomMap[hid]) {
                roomMap[hid] = [];
              }
              roomMap[hid].push(rbrt);
            });
          }

          const billingInstructionsOptions = () => {
            const instructionOptions = instructions.map((ele) => ({
              label: ele.instruction_text,
              value: ele.id
            }));
            return instructionOptions;
          };

          const renderBillingInstructionsOther = () => {
            return (
              <div className="sg-mgmt-form-row">
                {renderTextAreaField("Billing Instructions (Other)", "booking[billing_instructions_other]")}
              </div>
            );
          };

          const roomBlockTypeOptions = () => {
            return Object.keys(roomMap)
              .map((hid) =>
                roomMap[hid].map((rbrt) => ({
                  label: `${rbrt.room_type.hotel_name}: ${rbrt.room_type.name}`,
                  value: rbrt.gid,
                  hotel: hotels.find((h) => h.id === parseInt(hid, 10)).name
                }))
              )
              .flat();
          };

          const hotelRoomTypeLabel = () => {
            if (isEdit()) {
              return "Change Hotel and Room Type";
            }
            return "Hotel and Room Type";
          };

          return (
            <Form className="sg-mgmt-form" id={formConfig.formId}>
              <div className="sg-mgmt-form-container">
                <div className="sg-mgmt-form-row">{renderParticipant()}</div>
                <div className="sg-mgmt-form-row">{renderBookingGid()}</div>
                <div className="sg-mgmt-form-row">
                  {renderSelectField("Room Block", "room_block_id", roomBlockOptions)}
                </div>
                {renderHotelAndRoomType()}
                <div className="sg-mgmt-form-row">
                  <div className="sg-mgmt-form-input-container">
                    <label>{hotelRoomTypeLabel()}</label>
                    <SelectField
                      name="room_block_room_type_gid"
                      options={roomBlockTypeOptions()}
                      groupBy={(option) => option.hotel}
                    />
                  </div>
                </div>
                <div className="sg-mgmt-form-row">{renderDateField("Check-in Date", "booking[check_in_date]")}</div>
                <div className="sg-mgmt-form-row">{renderDateField("Check-out Date", "booking[check_out_date]")}</div>
                <div className="sg-mgmt-form-row">
                  <div className="sg-mgmt-form-input-container">
                    <label>Billing Instructions</label>
                    <SelectField
                      name="booking[billing_instruction_id]"
                      options={billingInstructionsOptions()}
                      includeBlank
                      defaultValue={
                        selectedInstruction
                          ? {
                            label: selectedInstruction.instruction_text,
                            value: selectedInstruction.id
                          }
                          : { label: null, value: null }
                      }
                    />
                  </div>
                </div>
                {renderBillingInstructionsOther()}
                <div className="sg-mgmt-form-row">{renderTextAreaField("Notes (for admin use)", "booking[notes]")}</div>
              </div>
              <div className="sg-mgmt-form-container">
                <h2>Hotel Fields</h2>
                <div className="sg-mgmt-form-row">{renderTextField("Confirmation #", "booking[confirmation]")}</div>
                <div className="sg-mgmt-form-row">{renderCheckField("Finalized", "booking[paid]")}</div>
                <div className="sg-mgmt-form-row">
                  {renderTextAreaField("Notes (for hotel use)", "booking[notes_hotel]")}
                </div>
              </div>
              <div className="sg-mgmt-form-container">
                <h2>Testing</h2>
                <div className="sg-mgmt-form-row">
                  {renderCheckField("Test Flag (exclude from reports)", "booking[test_flag]")}
                </div>
              </div>
              <Stack direction="row" spacing={2}>
                {renderSubmitButton(formConfig.saveButton, isSubmitting)}
                {renderCancelButton("Cancel", cancelButton)}
                {renderMarkCancelButton()}
                {renderMarkDeleteButton()}
              </Stack>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default HousingBookingForm;
