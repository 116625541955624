import React, { useContext, useEffect, useState } from "react";
import {useSortable} from '@dnd-kit/sortable';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Button, Stack } from "@mui/material";
import {CSS} from '@dnd-kit/utilities';
import { useConfirm } from "material-ui-confirm";
import TemplateQuestionFormModal from "./TemplateQuestionFormModal";
import { alertHttpError, alertSuccess } from "@/shared/Alerts";
import axios from "axios";
import { 
  getReadableContentType
} from "@/contents/ContentTypes";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { renderCreateButton } from "@/shared/FormUtils";

const TemplateQuestionsForm = (props) => {
  const { template, onChange } = props;
  const { apiRoot } = useContext(EventContext).values;
  const sensors = useSensors(useSensor(PointerSensor));
  const confirm = useConfirm();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalQuestionId, setEditModalQuestionId] = useState(null);

  const [ 
    questions,
    setQuestions
  ] = useState(props.questions||[]);

  const sortedQuestions = () => {
    return questions.sort((a, b) => a.sort_order - b.sort_order);
  }

  renderQuestions = () => {
    const renderedQuestions = sortedQuestions().map((question) => (
      <QuestionRow
        key={question.id}
        onDelete={() => attemptDelete(question)}
        onEdit={()=> openModal(question)}
        question={question}
      />
    ));
    return (
      <table className="table w-full table-fixed mb-8">
        <thead className="thead-light bg-slate-300 text-slate-800">
          <tr>
            <th>Order</th>
            <th>Form Field Title</th>
            <th>Content Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderedQuestions}
        </tbody>
      </table>
    )
  }

  const attemptDelete = (question) => {
    confirm({
      description: `Are you sure you want to delete this question? This cannot be undone.`,
      confirmationText: "Delete",
    }).then(async () => {
      try {
        const result = await axios.delete(
          urljoin(apiRoot, `/contents/web_page_template_questions/${question.id}`)
        );
        alertSuccess("Question successfully deleted");
        setQuestions(questions.filter((q) => q.id !== question.id));
      } catch (error) {
        alertHttpError(error);
      }
    });
  }

  const handleQuestionDragEnd = (event) => {
    const {active, over} = event;
    const items = sortedQuestions();
    if (active.id !== over.id) {
      onChange();
      const activeIndex = items.findIndex((item) => item.id === active.id);
      const overIndex = items.findIndex((item) => item.id === over.id);
      const newItems =arrayMove(items, activeIndex, overIndex)
      const idArray = newItems.map(x=> x.id);
      newItems.map((x, i)=> x.sort_order = i);
      setQuestions(newItems);

      axios.post(
        urljoin(apiRoot, `/contents/web_page_templates/${template.id}/order_questions`),
          {
            question_ids: idArray,
          }
      ).catch((error) => {
        alertHttpError(error);
      });
    }
  };

  const openModal = (question) => {
    setEditModalOpen(true);
    setEditModalQuestionId(question.id);
  };

  const closeModal = () => {
    setEditModalOpen(false);
    setEditModalQuestionId(null);
  };

  return (
    <>
    {renderCreateButton("Add New Form Field", ()=>{
      openModal({});
    })}
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={(event) => handleQuestionDragEnd(event)}
    >
      <SortableContext items={sortedQuestions().map((x) => x.id)} strategy={verticalListSortingStrategy}>
        {renderQuestions()}
      </SortableContext>
    </DndContext>
    <TemplateQuestionFormModal
      modalVisible={editModalOpen}
      template={template}
      modalClose={closeModal}
      id={editModalQuestionId}
      sortOrder={
        editModalQuestionId ? undefined : sortedQuestions().length
      }
      callbackSuccess={(response) => {
        const question = response.data["web_page_template_question"];
        const index = questions.findIndex((q) => q.id === question.id);
        if(index > -1) {
          questions[index] = question;
        } else {
          questions.push(question);
        }
        setQuestions(questions);
        closeModal();
        onChange();
      }}
    />
    </>
  );
};

const QuestionRow = ({
  question,
  onEdit,
  onDelete
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: question.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td>
        <img className="cursor-pointer" src="/images/icons/drag_dots.svg" {...listeners} />
      </td>
      <td>{question.name}</td>
      <td>
        {
          getReadableContentType(question.content_type)
        }
      </td>
      <td>
        <Stack direction="row" spacing={2} >
          <Button
            type="button"
            variant="contained"
            onClick={onEdit}
          >
            Edit
          </Button>
          <Button
            type="button"
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            Delete
          </Button>
        </Stack>
      </td>
    </tr>
  );
}

  

export default TemplateQuestionsForm;