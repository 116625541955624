import React, { useContext, useState } from "react";

import EventContext from "@event/EventContext";
import { alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import GrowlTable from "@shared/GrowlTable";
import { useUpdateHousingBooking } from "@shared/hooks/useHousingBookings";
import { formatDateFriendlyNoZone } from "@shared/TimeUtils";

import HousingBlankBooking from "./HousingBlankBooking";
import HousingBookingModal from "./HousingBookingModal";

const HousingBookingsList = (props) => {
  const {
    bookings,
    blocks,
    config,
    editBooking = HousingBlankBooking,
    goToFinalize,
    hotels,
    instructions,
    participants,
    setEditBooking
  } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [warnModal, setWarnModal] = useState(false);
  const [warnModalText, setWarnModalText] = useState("");
  const updateHousingBooking = useUpdateHousingBooking(apiRoot, event.id);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const modalEditReset = () => {
    setModalEditVisible(false);
  };

  const resubmit = (id) => {
    const formData = new FormData();
    formData.append("booking[resubmit]", true);

    updateHousingBooking.mutate(
      { id, data: formData },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            alertSuccess("Booking submitted successfully");
          } else {
            setWarnModalText(data.error);
            setWarnModal(true);
          }
        },
        onError: (error) => {
          alertHttpError(error);
        }
      }
    );
  };

  const renderEdit = (booking) => (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          setEditBooking(booking);
          setModalEditVisible(true);
        }}
      >
        Edit
      </span>
    </>
  );

  const renderResubmit = (booking) => {
    if (!booking.status === "pending") {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            resubmit(booking.id);
          }}
        >
          Resubmit
        </span>
      </>
    );
  };

  const renderParticipantType = (gid) => {
    const participant = participants.find((ele) => ele.gid === gid);
    if (participant) {
      return participant.type;
    }
    return "";
  };

  const columns = [
    {
      field: "guest_name",
      headerName: "Participant Name",
      flex: 1
    },
    {
      field: "type",
      headerName: "Participant Type",
      flex: 1,
      renderCell: (params) => {
        return renderParticipantType(params.row.event_participant.gid);
      }
    },
    {
      field: "room_block_name",
      headerName: "Room Block",
      flex: 1
    },
    {
      field: "hotel_name",
      headerName: "Hotel",
      flex: 1
    },
    {
      field: "room_type_name",
      headerName: "Room Type",
      flex: 1
    },
    {
      field: "check_in_date",
      headerName: "Check-in Date",
      flex: 1,
      renderCell: (params) => <span>{formatDateFriendlyNoZone(params.value)}</span>
    },
    {
      field: "check_out_date",
      headerName: "Check-out Date",
      flex: 1,
      renderCell: (params) => <span>{formatDateFriendlyNoZone(params.value)}</span>
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1
    },
    {
      field: "confirmation",
      headerName: "Confirmation #",
      flex: 1
    },
    {
      headerName: "Test?",
      field: "test_flag",
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
      sortComparator: (a, b) => {
        const aFlag = a.test_flag ? 1 : 0;
        const bFlag = b.test_flag ? 1 : 0;
        return aFlag - bFlag;
      }
    },
    {
      headerName: "Finalized?",
      field: "paid",
      searchable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
      sortComparator: (a, b) => {
        const aFlag = a.paid ? 1 : 0;
        const bFlag = b.paid ? 1 : 0;
        return aFlag - bFlag;
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      getActions: (params) => [renderEdit(params.row), renderResubmit(params.row)]
    }
  ];

  const renderNewBookingButton = () => {
    return renderCreateButton("Create New Booking", () => setModalAddVisible(true));
  };

  return (
    <div>
      {renderNewBookingButton()}
      <div>
        <span className="sg-mgmt-link" onClick={goToFinalize}>
          Mass Finalize
        </span>
      </div>
      <GrowlTable columns={columns} items={bookings} tableName={`${event.slug}-bookings`} />
      <HousingBookingModal
        blocks={blocks}
        bookings={bookings}
        booking={HousingBlankBooking}
        config={config}
        hotels={hotels}
        instructions={instructions}
        modalVisible={modalAddVisible}
        participants={participants}
        resetModal={modalAddReset}
        title="Create Booking"
      />
      <HousingBookingModal
        blocks={blocks}
        bookings={bookings}
        booking={editBooking}
        config={config}
        hotels={hotels}
        instructions={instructions}
        modalVisible={modalEditVisible}
        participants={participants}
        resetModal={modalEditReset}
        title="Edit Booking"
      />
      <GrowlModal
        content={
          <div>
            <p>There is insufficient inventory on one or more of the requested dates to create this booking.</p>
            <p>
              This booking will remain in &quot;pending&quot; mode, and can be resubmitted when inventory is made
              available.
            </p>
            <p>Error details: {warnModalText}</p>
          </div>
        }
        title="Insufficient Inventory"
        open={warnModal}
        actions={[
          {
            label: "Close",
            close: true
          }
        ]}
      />
    </div>
  );
};

export default HousingBookingsList;
