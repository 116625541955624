import React from "react";

import CustomReportCheckBox from "./CustomReportCheckBox";

const CustomReportFieldSelect = (props) => {
  const { selectedKeys, options, onChange } = props;

  const optionClicked = (e, option) => {
    onChange(option);
  };

  const renderOptions = () => {
    const selected = selectedKeys.map((ele) => ele.value);
    return (
      <>
        {options.map((option) => (
          <div key={option.value} className="report-field-select-option pb-1" onClick={(e) => optionClicked(e, option)}>
            <CustomReportCheckBox disabled={false} inline={true} value={selected.includes(option.value)} />
            <div className="inline">{option.label}</div>
          </div>
        ))}
      </>
    );
  };

  return <div>{renderOptions()}</div>;
};

export default CustomReportFieldSelect;
