import React, { useContext, useState } from "react";
import clsx from "clsx";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import SubpageTab from "@shared/SubpageTab";
import EventSessions from "./EventSessions";
import EventSessionsSettings from "./EventSessionsSettings";
import SessionsVenues from "./venues/SessionsVenues";

import TabbedPage from "@shared/TabbedPage";
import {TabSettingsIcon} from "@shared/tabs/TabIcons";
const Sessions = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      {label: 'Sessions', component: EventSessions},
      {label: 'Venues', component: SessionsVenues},
    ]

    if (user && (user.role != "basic" || user.permission.sessions_edit)) {
      tabs.push(
        {label: 'Settings', component: EventSessionsSettings, icon: TabSettingsIcon},
      )
    }

    return tabs
  }
  return (
    <TabbedPage
      tabs={getTabs()}
    />
  );
};

export default Sessions;
