import React from "react";
import PropTypes from "prop-types";
import GrowlModal from "@shared/GrowlModal";

const EventSessionType = props => {
  const { deleteType, setEditType, sessionType } = props;
  return (
    <div
      className="flex w-96 bg-sg-orange text-white mr-2"
      key={`sessionType-${sessionType.gid}-${sessionType.id}`}
      data-gid={sessionType.gid}
    >
      <div className="flex-grow px-2 py-2">{sessionType.name}</div>
      <div className="flex-initial pr-4 py-2">
        <span
          className="cursor-pointer"
          onClick={() => {
            setEditType(sessionType.id);
          }}
        >
          <i className="fas fa-light fa-pen" />
        </span>
      </div>
      <div className="flex-initial pr-4 py-2">
        <span className="cursor-pointer">
          <GrowlModal
            content="Are you sure you want to delete this session type?"
            title="Delete Type"
            trigger={
              <div className="inline-block ml-1 cursor-pointer">&times;</div>
            }
            actions={[
              {
                label: "Cancel",
                close: true
              },
              {
                label: "Delete",
                close: true,
                func: () => {
                  deleteType(sessionType.id);
                }
              }
            ]}
          />
        </span>
      </div>
    </div>
  );
};

EventSessionType.propTypes = {
  deleteType: PropTypes.func.isRequired,
  setEditType: PropTypes.func.isRequired,
  sessionType: PropTypes.object.isRequired
};

export default EventSessionType;
