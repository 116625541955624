import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";

const EventAnalyticsChartRegistrationGraph = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [values, setValues] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchRegGraph = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "charts/reg_graph"));
        setValues(result.data.values);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchRegGraph();
  }, []);

  const renderChart = () => {
    if (fetched) {
      return (
        <div>
          <h2>Registration Activity</h2>
        </div>
      );
    }
    return <Loading />;
  };

  return <div>{renderChart()}</div>;
};

export default EventAnalyticsChartRegistrationGraph;
