import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import CheckFieldSmall from "@shared/CheckFieldSmall";
import {
  renderSubmitButton,
  renderTextField,
  renderTextAreaField,
  renderCancelButton
} from "@shared/FormUtils";
import Stack from "@mui/material/Stack";
import SelectField from "@shared/SelectField";
import EventSpeakersBlankSpeaker from "./EventSpeakersBlankSpeaker";

const EventSpeakersForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { reset, speaker, update } = props;
  const [photo, setPhoto] = useState(null);
  const [photoDropped, setPhotoDropped] = useState(false);
  const [previewPhotoUrl, setPreviewPhotoUrl] = useState(speaker.photo_url);
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";

  const config = (() => {
    if (speaker && speaker.id) {
      return {
        alert: "updated",
        edit: true,
        formId: "sg-mgmt-form-speaker-edit",
        formUrl: urljoin(apiRoot, "/speakers", `/${speaker.id}`),
        method: "PATCH",
        title: "Edit Speaker"
      };
    }
    return {
      alert: "added",
      edit: false,
      formId: "sg-mgmt-form-speaker-add",
      formUrl: urljoin(apiRoot, "/speakers"),
      method: "POST",
      title: "Add Speaker"
    };
  })();

  const onDrop = acceptedFiles => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = e => {
        setPhoto(acceptedFiles[0]);
        setPhotoDropped(true);
        setPreviewPhotoUrl(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setPhoto(acceptedFiles[0]);
      setPhotoDropped(true);
      setPreviewPhotoUrl(null);
    }
  };

  const dropzoneFilename = () => {
    if (photoDropped) {
      return (
        <>
          File attached:
          <br />
          {photo.name}
        </>
      );
    }
    return <>Upload Photo</>;
  };

  const renderInternalExternalSelect = (formatClasses = []) => {
    const options = [
      { label: "Internal", value: "internal" },
      { label: "External", value: "external" }
    ];
    return (
      <div
        className={clsx("sg-mgmt-form-input-container", formatClasses)}
      >
        <label>Internal/External</label>
        <SelectField
          fieldName="speaker[data][internal_external]"
          options={options}
        />
      </div>
    );
  };

  const renderShowOnSpeakersPage = (formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <CheckFieldSmall
        fieldName="speaker[data][speaker_show]"
        label="Show on Speaker Page?"
      />
    </div>
  );

  const renderPhoto = () => {
    if (previewPhotoUrl) {
      return (
        <img
          className="sg-mgmt-form-speaker-photo-container"
          src={previewPhotoUrl}
          alt="Speaker"
        />
      );
    }
    return (
      <div className="sg-mgmt-form-input-dropzone-tile-prompt">
        Drag and Drop
        <br />
        or Click to Choose File
      </div>
    );
  };

  const renderPhotoField = () => {
    return (
      <div>
        <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="speaker[photo]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className={clsx(
                    "dropzone",
                    "sg-mgmt-form-input-dropzone-tile",
                    {
                      "dropzone--isActive": isDragActive
                    }
                  )}
                >
                  <input {...getInputProps()} />
                  {renderPhoto()}
                </div>
                <div className="sg-mgmt-form-speaker-photo-filename">
                  {dropzoneFilename()}
                </div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  /* eslint-disable dot-notation */
  const internalExternalValue = () => {
    if (!speaker.data) {
      return "internal";
    }

    return speaker.data["internal_external"]
      ? speaker.data["internal_external"]
      : "internal";
  };

  const speakerShowValue = () => {
    if (!speaker.data) {
      return false;
    }

    return speaker.data["speaker_show"] ? speaker.data["speaker_show"] : false;
  };
  /* eslint-enable dot-notation */

  const formInitialValues = () => {
    if (speaker && speaker.id) {
      return {
        speaker: {
          bio: speaker.bio || "",
          photo_url: "",
          data: {
            internal_external: internalExternalValue(),
            speaker_show: speakerShowValue()
          }
        },
        attendee: {
          name_last: "",
          name_first: "",
          job_title: "",
          company: "",
          email: "",
          phone: "",
          address_street_1: "",
          address_street_2: "",
          address_city: "",
          address_state: "",
          address_postcode: ""
        }
      };
    }
    return EventSpeakersBlankSpeaker;
  };

  const renderFormFields = () => {
    if (config.edit) {
      return (
        <>
          <div className="sg-mgmt-form-row">
            Edit personal detail fields in speaker&apos;s Attendee record
          </div>
          <div className="sg-mgmt-form-row">{renderPhotoField()}</div>
          <div className="sg-mgmt-form-row">
            {renderTextAreaField("Speaker Bio", "speaker[bio]")}
          </div>
          <div className="sg-mgmt-form-row">
            {renderInternalExternalSelect()}
          </div>
          <div className="sg-mgmt-form-row">{renderShowOnSpeakersPage()}</div>
        </>
      );
    }
    return (
      <>
        <div className="sg-mgmt-form-speaker-container">
          <div className="sg-mgmt-form-speaker-container-details">
            <div className="sg-mgmt-form-row">
              {renderTextField("First Name (required)", "attendee[name_first]")}
              {renderTextField("Last Name (required)", "attendee[name_last]")}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextField("Company", "attendee[company]")}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextField("Job Title", "attendee[job_title]")}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextAreaField("Speaker Bio", "speaker[bio]")}
            </div>
            <div className="sg-mgmt-form-row">
              {renderInternalExternalSelect()}
            </div>
            <div className="sg-mgmt-form-row">{renderShowOnSpeakersPage()}</div>
            <div className="sg-mgmt-form-row">
              {renderTextField("Email Address (required)", "attendee[email]")}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextField("Phone Number", "attendee[phone]")}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextField(
                "Address (line 1)",
                "attendee[address_street_1]"
              )}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextField(
                "Address (line 2)",
                "attendee[address_street_2]"
              )}
            </div>
            <div className="sg-mgmt-form-row">
              {renderTextField("City", "attendee[address_city]")}
              {renderTextField("State/Province", "attendee[address_state]", [
                "sg-mgmt-form-input-container-state"
              ])}
              {renderTextField(
                "ZIP/Postal Code",
                "attendee[address_postcode]",
                ["sg-mgmt-form-input-container-postcode"]
              )}
            </div>
          </div>
          <div className="sg-mgmt-form-speaker-container-photo">
            {renderPhotoField()}
          </div>
        </div>
      </>
    );
  };

  const renderAddEditSpeakerModal = () => {
    return (
      <Formik
        initialValues={formInitialValues()}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(config.formId);
          const formData = new FormData(form);

          if (document.body.dataset.environment !== "test") {
            const token = document.querySelector("[name=csrf-token]").content;
            axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          }

          if (photo) {
            formData.set("speaker[photo]", photo);
          }

          axios({
            url: config.formUrl,
            method: config.method,
            data: formData
          })
            .then(response => {
              if (response.data.error === null) {
                update(response.data.speaker);
                reset();
                alertSuccess(`Speaker ${config.alert} successfully`);
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch(error => {
              alertHttpError(error);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={config.formId}>
            <div className="sg-mgmt-form-container">{renderFormFields()}</div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton("Save", isSubmitting)}
              {renderCancelButton("Cancel", reset)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderAddEditSpeakerModal()}</>;
};

EventSpeakersForm.defaultProps = {
  speaker: EventSpeakersBlankSpeaker
};

EventSpeakersForm.propTypes = {
  reset: PropTypes.func.isRequired,
  speaker: PropTypes.object,
  update: PropTypes.func.isRequired
};

export default EventSpeakersForm;
