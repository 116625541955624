import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import Loading from "@shared/Loading";
import { alertError, alertHttpError } from "@shared/Alerts";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import SessionsVenueForm from "./SessionsVenueForm";

const SessionsVenue = props => {
  const { editVenueId, goIndex, setEditVenueId, updateVenues } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [venue, setVenue] = useState(false);
  const { user } = useContext(EventUserContext);

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/venues", `/${editVenueId}`)
        );
        setVenue(result.data.venue);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchVenue();
  }, [apiRoot, editVenueId]);

  const success = response => {
    updateVenues(response.data.venue);
    goIndex();
    setEditVenueId(null);
  };

  const failure = () => {
    alertError("Failed to update venue");
  };

  const renderVenueForm = () => {
    if (fetched) {
      return (
        <SessionsVenueForm
          callbackFailure={failure}
          callbackSuccess={success}
          cancelButton={goIndex}
          venue={venue}
        />
      );
    }
    return <Loading />;
  };

  return <div>{renderVenueForm()}</div>;
};

SessionsVenue.propTypes = {
  editVenueId: PropTypes.number.isRequired,
  goIndex: PropTypes.func.isRequired,
  setEditVenueId: PropTypes.func.isRequired,
  updateVenues: PropTypes.func.isRequired
};

export default SessionsVenue;
