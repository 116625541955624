import React from "react";

import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";

import HousingRoomBlockRoomTypeInventoryForm from "./HousingRoomBlockRoomTypeInventoryForm";

const HousingRoomBlockRoomTypeInventoryModal = props => {
  const {
    config,
    block,
    blocks,
    modalVisible,
    resetModal,
    roomBlockRoomType,
    title
  } = props;

  Modal.setAppElement("#root");

  const success = () => {
    alertSuccess("Inventory updated successfully");
    resetModal();
  };

  const failure = () => {
    alertError("Failed to update inventory");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div>
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingRoomBlockRoomTypeInventoryForm
            config={config}
            blocks={blocks}
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            fetched
            block={block}
            roomBlockRoomType={roomBlockRoomType}
          />
        </div>
      </div>
    </Modal >
  );
};

export default HousingRoomBlockRoomTypeInventoryModal;
