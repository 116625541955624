import React, { useRef } from "react";

import GrowlTableBoundary from "./growltable/GrowlTableBoundary";
import GrowlTableComponent from "./growltable/GrowlTableComponent";

const GrowlTable = (props) => {
  const { componentRef = {}, tableName = "Table", microform = false, ...restProps } = props;

  // We don't want to have to pass a ref to the component every time, only when
  // we need the parent able to access the table's row selections. We pass an
  // unused ref so that forwardRef doesn't complain when a ref is absent.
  const dummyRef = useRef();

  return (
    <GrowlTableBoundary tableName={tableName}>
      <GrowlTableComponent
        {...restProps}
        microform={microform}
        ref={Object.keys(componentRef).length === 0 ? dummyRef : componentRef}
        tableName={tableName}
      />
    </GrowlTableBoundary>
  );
};

export default GrowlTable;
