import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useHousingHotelAmenities } from "@shared/hooks/useHousingHotels";
import Loading from "@shared/Loading";

import HousingHotelAmenitiesForm from "./HousingHotelAmenitiesForm";
import HousingHotelAmenity from "./HousingHotelAmenity";

const HousingHotelAmenities = (props) => {
  const { hotel } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const hotelAmenitiesQuery = useHousingHotelAmenities(apiRoot, event.id, hotel.id);

  const renderAmenities = () => {
    return (
      <div>
        <ul>
          {hotelAmenitiesQuery.data.amenities.map((amenity) => (
            <li key={amenity.id}>
              <HousingHotelAmenity apiRoot={apiRoot} event={event} amenity={amenity} hotel={hotel} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if (hotelAmenitiesQuery.isLoading) {
    return <Loading />;
  }

  if (hotelAmenitiesQuery.isError) {
    return <div>Error loading hotel amenities: {hotelAmenitiesQuery.error}</div>;
  }

  return (
    <div>
      {renderAmenities()}
      <div className="my-4">
        <div className="text-bold text-sg-orange">Add New Amenity:</div>
        <HousingHotelAmenitiesForm hotel={hotel} />
      </div>
    </div>
  );
};

export default HousingHotelAmenities;
