export function ticketTotalCount(ticket) {
  if (ticket && ticket.total_count_type == "limited") {
    return ticket.total_count;
  }
  return null;
}

export function ticketTotalAvailable(ticket, assigned_count = null) {
  const total = ticketTotalCount(ticket);
  const total_assigned = assigned_count ? assigned_count : (ticket.participant_tickets || []).length;
  if (isNaN(total) || total === null) {
    return null;
  }
  return total - total_assigned;
}
