import React from "react";

import clsx from "clsx";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const GrowlModal = (props) => {
  const { actions = [], content, title, trigger = null, open = null } = props;

  const renderActions = (modalClose) => {
    return actions.map((action) => {
      let onClickCb = () => { };
      let btnClass = "sg-mgmt-modal-btn-select";
      if (action.close && action.func) {
        onClickCb = (...args) => {
          action.func(args);
          modalClose();
        };
      } else if (action.func) {
        onClickCb = action.func;
      } else if (action.close) {
        btnClass = "sg-mgmt-modal-btn-cancel";
        onClickCb = modalClose;
      }

      return (
        <button className={clsx("sg-mgmt-modal-btn", btnClass)} key={action.label} type="button" onClick={onClickCb}>
          {action.label}
        </button>
      );
    });
  };

  if (open) {
    return (
      <Popup open={open} modal nested>
        {(close) => (
          <div className="sg-mgmt-growl-modal">
            <button type="button" className="sg-mgmt-growl-modal-close" onClick={close}>
              &times;
            </button>
            <div className="sg-mgmt-growl-modal-header">{title}</div>
            <div className="sg-mgmt-growl-modal-content">{content}</div>
            <div className="sg-mgmt-growl-modal-actions">{renderActions(close)}</div>
          </div>
        )}
      </Popup>
    );
  }

  return (
    <Popup trigger={trigger} modal nested>
      {(close) => (
        <div className="sg-mgmt-growl-modal">
          <button type="button" className="sg-mgmt-growl-modal-close" onClick={close}>
            &times;
          </button>
          <div className="sg-mgmt-growl-modal-header">{title}</div>
          <div className="sg-mgmt-growl-modal-content">{content}</div>
          <div className="sg-mgmt-growl-modal-actions">{renderActions(close)}</div>
        </div>
      )}
    </Popup>
  );
};

export default GrowlModal;
