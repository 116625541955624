import React from "react";
import PropTypes from "prop-types";
import { alertError } from "@shared/Alerts";
import EventSessionFormGeneralInfo from "./form/EventSessionFormGeneralInfo";

const EventSessionGeneralInfoEdit = props => {
  const { setMode, update } = props;

  const success = response => {
    update(response.data.session);
    setMode("view");
  };

  const failure = response => {
    alertError(`Failed to update session info: ${response.data.error}`);
  };

  const cancel = () => {
    setMode("view");
  };

  return (
    <div>
      <EventSessionFormGeneralInfo
        callbackSuccess={success}
        callbackFailure={failure}
        cancelButton={cancel}
      />
    </div>
  );
};

EventSessionGeneralInfoEdit.propTypes = {
  setMode: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default EventSessionGeneralInfoEdit;
