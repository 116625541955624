import React, { 
  useState,
  useEffect,
  useContext
} from "react";
import EventContext from "@event/EventContext";
import axios from "axios";
import urljoin from "url-join";
import Chip from "@mui/material/Chip";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import { Formik, Form, Field } from "formik";
import { Stack } from "@mui/material";
import {
  renderTextField,
  renderButton,
  renderSubmitButton
} from "@shared/FormUtils";
import { useConfirm } from "material-ui-confirm";

const WebPageTags = () => {
  const [tags, setTags] = useState([]);
  const [fetched, setFetched] = useState(false);
  const apiRoot = useContext(EventContext).values.apiRoot;
  const confirm = useConfirm();
  const fetchTags = async () => {
    try {
      const result = await axios(urljoin(apiRoot, "contents/web_page_tags"));
      setTags(result.data.web_page_tags);
      setFetched(true);
    } catch (error) {
      alertHttpError(error);
    }
  };

  useEffect(() => {
    fetchTags();
  },[])

  const renderTags = () => {
    if (fetched) {
      return (
        <div>
          <div className="mr-2 text-black">Web Page Tags:</div>
          <div className="my-2">
            {tags.map(tag => (
              <Chip
                key={tag.id}
                className="mr-2"
                color="primary"
                label={tag.name}
                onDelete={() => attemptDelete(tag.id)}
              />
            ))}
          </div>
        </div>
      );
    }
    return <h2>Loading</h2>;
  }

  attemptDelete = (id) => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to remove this tag? This cannot be undone."
    }).then(() => {
      performDelete(id);
    })
    .catch(err => {
      alertError(err);
    });
  }

  const performDelete = (id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/contents/web_page_tags/${id}`),
      method: "DELETE"
    })
    .then(response => {
      if (response.data.error == null) {
        setTags(tags.filter(i => i.id !== id));
        alertSuccess("Tag deleted successfully");
      } else {
        alertError(response.data.error);
      }
    })
    .catch(error => {
      alertHttpError(error);
    });
  };

  const renderTagForm = () => {
    return (
    <Formik
      initialValues={{
        tag: {
          name: ""
        }
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const form = document.getElementById(
          "sg-mgmt-participant-tags-form"
        );
        const formData = new FormData(form);

        // ignore submitting the placeholder text
        if (formData.get("tag[name]").length === 0) {
          setSubmitting(false);
          return;
        }

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: urljoin(apiRoot, "/contents/web_page_tags"),
          method: "POST",
          data: formData
        })
          .then(response => {
            if (!response.data.error) {
              setTags([...tags, response.data.web_page_tag]);
              alertSuccess("Tag created");
              setSubmitting(false);
              resetForm();
            } else {
              alertError(response.data.error);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className="sg-mgmt-form" id="sg-mgmt-participant-tags-form">
          <Stack direction="row" spacing={2}>
            {renderTextField("Name", "tag[name]")}
            <div className="sg-mgmt-form-input-container">
              <label className="mb-[0.5px]"> &nbsp;</label>
              {
                renderButton(
                  "Add", ()=>{},
                  {
                    type:"submit",
                    disabled: isSubmitting
                  }
                )
              }
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
    )
  }

  return (
    <div>
      <h2 className="font-normal">Manage Tags</h2>
      <div className="mb-4 mt-2">
        Tags allow you to identify or group different web pages.
      </div>
      {renderTagForm()}
      {renderTags()}
    </div>
  )
}

export default WebPageTags