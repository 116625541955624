import React from "react";
import PropTypes from "prop-types";

import { Route, Switch, withRouter } from "react-router-dom";

import Communications from "@communications/Communications";
import StaticFiles from "@contents/static_files/StaticFiles";
import WebPages from "@contents/web_pages/WebPages";
import WebPagesEdit from "@contents/web_pages/WebPagesEdit";
import TemplateEdit from "@contents/web_pages/templates/TemplateEdit";
import TemplateWebPages from "@contents/web_pages/templates/TemplateWebPages";
import Dashboard from "@dashboard/Dashboard";
import FeatureFlags from "@feature_flags/FeatureFlags";
import Forms from "@forms/Forms";
import FormsEdit from "@forms/FormsEdit";
import FormsReports from "@forms/FormsReports";
import Housing from "@housing/Housing";
import PeopleEventParticipantsImport from "@people/event_participants/import/PeopleEventParticipantsImport";
import PeopleEventParticipants from "@people/event_participants/PeopleEventParticipants";
import PeopleEventParticipantTags from "@people/event_participants/PeopleEventParticipantTags";
import Reporting from "@reporting/Reporting";
import Footer from "@shared/Footer";
import { useAppsignal } from "@shared/useAppsignal";
import Ticketing from "@ticketing/Ticketing";
import TicketingPackageAssignment from "@ticketing/TicketingPackageAssignment";
import TicketingTicketAssignment from "@ticketing/TicketingTicketAssignment";

import EventAnalytics from "./analytics/EventAnalytics";
import EventApis from "./apis/EventApis";
import Caches from "./caches/Caches";
import EventDetails from "./details/EventDetails";
import EventEdit from "./edit/EventEdit";
import EventBanner from "./EventBanner";
import EventMeetings1On1 from "./meetings1on1/EventMeetings1On1";
import EventMenu from "./menu/EventMenu";
import EventMetadata from "./metadata/EventMetadata";
import EventOverview from "./overview/EventOverview";
import EventSession from "./sessions/EventSession";
import EventSessionsSettings from "./sessions/EventSessionsSettings";
import Sessions from "./sessions/Sessions";
import EventSpeakers from "./speakers/EventSpeakers";
import EventSurveys from "./surveys/EventSurveys";
import EventUsers from "./users/EventUsers";

const Event = (props) => {
  const { appsignal, ErrorBoundary } = useAppsignal();
  const { event } = props;

  const dashboardEnabled = event?.features?.find((f) => f.slug === "dashboard")?.status === "enabled" || false;

  return (
    <div className="sg-mgmt-container">
      <EventBanner />
      <div className="sg-mgmt-body-container">
        <div className="sg-mgmt-body">
          <EventMenu />
          <Switch>
            <Route path="/reporting">
              <Reporting />
            </Route>
            <Route path="/cache">
              <Caches />
            </Route>
            <Route path="/communications">
              <Communications />
            </Route>
            <Route exact path="/content/static_files">
              <StaticFiles />
            </Route>
            <Route exact path="/content/web_pages">
              <WebPages />
            </Route>
            <Route exact path="/content/web_pages/:id">
              <WebPagesEdit />
            </Route>
            <Route exact path="/content/web_page_templates/:id">
              <TemplateEdit />
            </Route>
            <Route exact path="/content/web_page_templates/:id/web_pages">
              <TemplateWebPages />
            </Route>
            <Route path="/details">
              <EventDetails />
            </Route>
            <Route path="/feature_flags">
              <FeatureFlags />
            </Route>
            <Route path="/housing">
              <Housing />
            </Route>
            {/* <Route path="/meetings/settings"> */}
            {/*   <EventMeetingsSettings /> */}
            {/* </Route> */}
            <Route path="/meetings">
              <EventMeetings1On1 />
            </Route>
            <Route exact path="/forms">
              <Forms />
            </Route>
            <Route exact path="/forms/:form_type">
              <Forms />
            </Route>
            <Route path="/forms/:form_type/:id/view">
              <FormsReports />
            </Route>
            <Route path="/forms/:form_type/:id">
              <FormsEdit />
            </Route>
            <Route path="/participants/tags">
              <PeopleEventParticipantTags />
            </Route>
            <Route path="/participants/import">
              <PeopleEventParticipantsImport />
            </Route>
            <Route path="/participants">
              <PeopleEventParticipants />
            </Route>
            <Route path="/sessions/settings">
              <EventSessionsSettings />
            </Route>
            <Route path="/sessions">
              <Sessions />
            </Route>
            <Route path="/ticketing/tickets/:id">
              <TicketingTicketAssignment />
            </Route>
            <Route path="/ticketing/packages/:id">
              <TicketingPackageAssignment />
            </Route>
            <Route path="/ticketing">
              <Ticketing />
            </Route>
            <Route path="/session">
              <EventSession />
            </Route>
            <Route path="/speakers">
              <EventSpeakers />
            </Route>
            <Route path="/surveys">
              <EventSurveys />
            </Route>
            <Route path="/users">
              <EventUsers />
            </Route>
            <Route path="/apis">
              <EventApis />
            </Route>
            <Route path="/edit">
              <EventEdit />
            </Route>
            <Route path="/metadata">
              <EventMetadata />
            </Route>
            <Route path="/">
              {dashboardEnabled ? (
                <ErrorBoundary
                  key={`error-boundary-dashboard`}
                  instance={appsignal}
                  tags={{ tag: "dashboard" }}
                  fallback={(_error) => <EventOverview error={_error} />}
                >
                  <Dashboard />
                </ErrorBoundary>
              ) : (
                  <EventOverview />
                )}
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Event.propTypes = {
  event: PropTypes.object.isRequired
};

export default withRouter(Event);
