import React, { useContext, useState } from "react";

import EventContext from "@event/EventContext";
import { useHousingBlocks, useHousingHotels, useHousingBillingInstructions } from "@shared/hooks/useHousing";
import { useHousingBookings } from "@shared/hooks/useHousingBookings";
import { useParticipantsTable } from "@shared/hooks/useParticipants";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import HousingBookingsFinalize from "./HousingBookingsFinalize";
import HousingBookingsList from "./HousingBookingsList";

const HousingBookings = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [page, setPage] = useState("index");
  const [editBooking, setEditBooking] = useState(null);

  const bookingsQuery = useHousingBookings(apiRoot, event.id);
  const blocksQuery = useHousingBlocks(apiRoot, event.id);
  const hotelsQuery = useHousingHotels(apiRoot, event.id);
  const instructionsQuery = useHousingBillingInstructions(apiRoot, event.id);
  const participantsQuery = useParticipantsTable(apiRoot, event.id);

  const queryError = (label, error) => (
    <div>
      {`An error occurred loading ${label}: ${error}`}
    </div>
  );

  const renderList = () => {
    if (bookingsQuery.isLoading || blocksQuery.isLoading || hotelsQuery.isLoading || instructionsQuery.isLoading || participantsQuery.isLoading) {
      return <Loading />;
    }

    if (bookingsQuery.isError) { return queryError("bookings", bookingsQuery.error); }
    if (blocksQuery.isError) { return queryError("blocks", blocksQuery.error); }
    if (hotelsQuery.isError) { return queryError("hotels", hotelsQuery.error); }
    if (instructionsQuery.isError) { return queryError("instructions", instructionsQuery.error); }
    if (participantsQuery.isError) { return queryError("participants", participantsQuery.error); }

    if (page === "index") {
      return (
        <HousingBookingsList
          bookings={bookingsQuery.data.bookings}
          blocks={blocksQuery.data.blocks}
          config={bookingsQuery.data.config}
          editBooking={editBooking}
          fetched
          goToFinalize={() => setPage("finalize")}
          hotels={hotelsQuery.data.hotels}
          instructions={instructionsQuery.data}
          participants={participantsQuery.data.participants}
          setEditBooking={setEditBooking}
        />
      );
    }

    if (page === "finalize") {
      return (
        <HousingBookingsFinalize
          bookings={bookingsQuery.data.bookings}
          fetched
          goToIndex={() => setPage("index")}
        />
      );
    }

    return <div>Cannot render unknown page: {page}</div>;
  };

  return (
    <div>
      <PageHeader text="Bookings" />
      {renderList()}
    </div>
  );
};

export default HousingBookings;
