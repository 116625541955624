import React, { useContext } from "react";


import EventContext from "@event/EventContext";

const StandardReportsTicketing = () => {
  const { event } = useContext(EventContext).values;
  const reports = [
    { label: "All Tickets", path: `/reporting/events/${event.slug}/ticketing` },
    { label: "Packages", path: `/reporting/events/${event.slug}/ticketing/packages` },
  ];

  const renderReport = (label, path) => {
    const url = new URL(path, window.location.origin);
    url.searchParams.append("format", "csv");

    return (
      <div className="flex my-1" key={label}>
        <div className="w-[300px]">{label}</div>
        <div className="px-4">
          <a className="sg-mgmt-link" href={path}>
            XLSX
          </a>
        </div>
        <div className="px-4">
          <a className="sg-mgmt-link" href={url}>
            CSV
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="my-4">
      <h2>Ticketing</h2>
      {reports.map((report) => renderReport(report.label, report.path))}
    </div>
  );
};

export default StandardReportsTicketing;
