import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { alertError, alertSuccess } from "@shared/Alerts";
import MetadataCopyEventForm from "./MetadataCopyEventForm";

const MetadataCopyEventModal = props => {
  const { 
    modalVisible,
    title,
    updateFunc,
    fieldType,
    closeModal,
    callbackSuccess,
    callbackFailure
  } = props;

  Modal.setAppElement("#root");

  const success = response => {
    callbackSuccess(response);
  };

  const failure = () => {
    alertError("Failed to save field");
    callbackFailure();
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={closeModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <MetadataCopyEventForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={closeModal}
            fieldType={fieldType}
          />
        </div>
      </div>
    </Modal>
  );
};

MetadataCopyEventModal.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

MetadataCopyEventModal.propTypes = {
  fieldType: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  callbackSuccess: PropTypes.func.isRequired,
  callbackFailure: PropTypes.func.isRequired
};

export default MetadataCopyEventModal;
