import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { renderButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

import EventSessionAttendanceTable from "./EventSessionAttendanceTable";
import EventSessionTitle from "../EventSessionTitle";

const EventSessionAttendance = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { update } = props;
  const [participants, setParticipants] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [mode, setMode] = useState("assigned");

  useEffect(() => {
    const fetchAttendees = async () => {
      const result = await axios(urljoin(apiRoot, "/participants"));
      setParticipants(result.data.participants);
      setFetched(true);
    };

    fetchAttendees();
  }, [apiRoot]);

  const renderButtons = () => {
    return (
      <div className="sg-mgmt-session-attend-btn-container">
        {renderButton("Assigned Attendees", () => {
          setMode("assigned");
        })}
        {renderButton("Available Attendees", () => {
          setMode("available");
        })}
      </div>
    );
  };

  const renderAttendees = () => {
    if (fetched) {
      return <EventSessionAttendanceTable items={participants} mode={mode} update={update} />;
    }
    return <Loading />;
  };

  return (
    <div>
      <EventSessionTitle />
      <h2 className="mb-1 mt-2">Attendee Assignment</h2>
      {renderButtons()}
      {renderAttendees()}
    </div>
  );
};

EventSessionAttendance.propTypes = {
  update: PropTypes.func.isRequired
};

export default EventSessionAttendance;
