import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const RadioField = ({ fieldName, options, integer }) => {
  const renderTextOption = (field,form, label, value) => {
    return (
      <div className="sg-mgmt-form-event-type" key={`${fieldName}-${value}`}>
        <FormControlLabel
          checked={field.value === value}
          value={value}
          control={<Radio />}
          label={label}
          onChange={(e)=>{
            form.setFieldValue(field.name, value);
          }}
        />
      </div>
    );
  };

  const renderIntegerOption = (field,form, label, value) => (
    <div className="sg-mgmt-form-event-type" key={`${fieldName}-${value}`}>
      <label className="sg-mgmt-radio-container">
        <FormControlLabel
          checked={field.value === value}
          value={value}
          control={<Radio />}
          label={label}
          onChange={(e)=>{

            form.setFieldValue(field.name, e.target.value);
          }}
        />
      </label>
    </div>
  );

  const renderOption = (field,form, label, value) =>
    integer
      ? renderIntegerOption(field,form, label, value)
      : renderTextOption(field,form, label, value);

  return (
    <Field name={fieldName}>
      {({ field, form}) => {
        return (
          <RadioGroup
            name={fieldName}
            className="flex-row"
          >
            {options.map(opt => renderOption(field,form, opt.label, opt.value))}
          </RadioGroup>
        );
      }}
    </Field>
  );
};

RadioField.defaultProps = {
  integer: false
};

RadioField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  integer: PropTypes.bool
};

export default RadioField;
