import React from "react";

const EventSessionContext = React.createContext({
  config: null,
  session: null,
  tags: null,
  venues: []
});

export default EventSessionContext;
