import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import EventSessionBlankSession from "./EventSessionBlankSession";
import EventSessionContext from "./EventSessionContext";
import EventSessionFormAdd from "./form/EventSessionFormAdd";

const EventSessionsModalAdd = props => {
  const { config, modalVisible, resetModal, tags, venues } = props;
  const [session, setSession] = useState(EventSessionBlankSession);

  Modal.setAppElement("#root");

  const context = () => ({
    config,
    session,
    tags,
    venues
  });

  return (
    <EventSessionContext.Provider value={context()}>
      <Modal
        className="sg-mgmt-modal"
        contentLabel="Add Session"
        isOpen={modalVisible}
        overlayClassName="sg-mgmt-modal-overlay"
        onRequestClose={resetModal}
      >
        <div className="sg-mgmt-modal-frame">
          <div className="sg-mgmt-modal-title">Add Session</div>
          <div className="sg-mgmt-modal-content">
            <EventSessionFormAdd {...props} setFormSession={setSession} />
          </div>
        </div>
      </Modal>
    </EventSessionContext.Provider>
  );
};

EventSessionsModalAdd.propTypes = {
  config: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  updateFunc: PropTypes.object.isRequired,
  venues: PropTypes.array.isRequired
};

export default EventSessionsModalAdd;
