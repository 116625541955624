export default {
  meeting_type: "",
  length_minutes: 45,
  capacity: 0,
  meeting_hosts: [],
  attendee_id: "",
  combo: false,
  choice_1: {
    meeting_hosts: {
      1: { id: "", date_and_time: "" }
    }
  },
  choice_2: {
    meeting_hosts: {
      1: { id: "", date_and_time: "" }
    }
  },
  data: {
    choice_1: {
      meeting_hosts: []
    },
    choice_2: {
      meeting_hosts: []
    },
    combo: false,
    objective_1: "",
    objective_2: "",
    objective_3: "",
    outcome: "",
    use_case: "",
    attendee_details: {},
    stage_account: "",
    potential_revenue: "",
    close_date: "",
    notes: ""
  }
};
