import React from "react";

import { DateTimePicker } from "@mui/x-date-pickers";
import { useField, useFormikContext } from "formik";

const DateTimePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const onChange = (val) => {
    setFieldValue(field.name, val);
  };

  return (
    <DateTimePicker
      {...field}
      {...props}
      showTimeSelect
      dateFormat="MMMM d, yyyy - h:mm aa"
      selected={(field.value && new Date(field.value)) || null}
      onChange={onChange}
    />
  );
};

export default DateTimePickerField;
