import React from "react";

export const getParticipantFields = (options = {}) => {
  let participant_fields = [
    { label: "Attended", key: "participant.attended" },
    { label: "Bio", key: "participant.bio" },
    { label: "Company", key: "participant.company" },
    { label: "Declined At", key: "participant.declined_at" },
    { label: "Deleted", key: "participant.deleted" },
    { label: "Email", key: "participant.email" },
    { label: "Job Title", key: "participant.job_title" },
    { label: "Name First", key: "participant.name_first" },
    { label: "Name Full", key: "participant.name_full" },
    { label: "Name Last", key: "participant.name_last" },
    { label: "Opt Out", key: "participant.opt_out" },
    { label: "Opt Out Global", key: "participant.opt_out_global" },
    { label: "Override Bio", key: "participant.override_bio" },
    { label: "Override Company", key: "participant.override_company" },
    { label: "Override Job Title", key: "participant.override_job_title" },
    { label: "Override Name First", key: "participant.override_name_first" },
    { label: "Override Name First Preferred", key: "participant.override_name_first_preferred" },
    { label: "Override Name Last", key: "participant.override_name_last" },
    { label: "Override Name Middle", key: "participant.override_name_middle" },
    { label: "Override Name Prefix", key: "participant.override_name_prefix" },
    { label: "Override Name Suffix", key: "participant.override_name_suffix" },
    { label: "Override Personal Address City", key: "participant.override_personal_address_city" },
    { label: "Override Personal Address Country", key: "participant.override_personal_address_country" },
    { label: "Override Personal Address Postcode", key: "participant.override_personal_address_postcode" },
    { label: "Override Personal Address State", key: "participant.override_personal_address_state" },
    { label: "Override Personal Address Street 1", key: "participant.override_personal_address_street_1" },
    { label: "Override Personal Address Street 2", key: "participant.override_personal_address_street_2" },
    { label: "Override Personal Phone", key: "participant.override_personal_phone" },
    { label: "Override Work Address City", key: "participant.override_work_address_city" },
    { label: "Override Work Address Country", key: "participant.override_work_address_country" },
    { label: "Override Work Address Postcode", key: "participant.override_work_address_postcode" },
    { label: "Override Work Address State", key: "participant.override_work_address_state" },
    { label: "Override Work Address Street 1", key: "participant.override_work_address_street_1" },
    { label: "Override Work Address Street 2", key: "participant.override_work_address_street_2" },
    { label: "Override Work Phone", key: "participant.override_work_phone" },
    { label: "Personal Address City", key: "participant.personal_address_city" },
    { label: "Personal Address Country", key: "participant.personal_address_country" },
    { label: "Personal Address Postcode", key: "participant.personal_address_postcode" },
    { label: "Personal Address State", key: "participant.personal_address_state" },
    { label: "Personal Address Street 1", key: "participant.personal_address_street_1" },
    { label: "Personal Address Street 2", key: "participant.personal_address_street_2" },
    { label: "Personal Phone", key: "participant.personal_phone" },
    { label: "Role", key: "participant.role" },
    { label: "Status", key: "participant.status" },
    { label: "Test Flag", key: "participant.test_flag" },
    { label: "Type", key: "participant.type" },
    { label: "Work Address City", key: "participant.work_address_city" },
    { label: "Work Address Country", key: "participant.work_address_country" },
    { label: "Work Address Postcode", key: "participant.work_address_postcode" },
    { label: "Work Address State", key: "participant.work_address_state" },
    { label: "Work Address Street 1", key: "participant.work_address_street_1" },
    { label: "Work Address Street 2", key: "participant.work_address_street_2" },
    { label: "Work Phone", key: "participant.work_phone" }
  ];

  participant_fields = participant_fields.concat(
    (options.metadata || []).map((field) => {
      return {
        label: field.label,
        key: "participant.metadata." + field.slug,
        metadata: field,
        defaultOptions: field.options
      };
    })
  );
  if (options.isParentField) {
    participant_fields = participant_fields.concat({ label: "Ticket Type", key: "participant.ticket_type" });
    participant_fields = participant_fields.concat({ label: "Package Type", key: "participant.package_type" });
  }

  return participant_fields;
};

export const getQuestionTypes = () => {
  return [
    {
      id: "text",
      label: "Text Input - Single Line",
      has_label: true
    },
    {
      id: "textarea",
      label: "Text Input - Multiple Line",
      has_label: true,
      defaultSize: 1
    },
    {
      id: "paragraph",
      label: "Text - Read Only",
      readOnly: true,
      has_content: true,
      has_label: false,
      defaultSize: 1
    },
    {
      id: "divider",
      label: "Divider",
      readOnly: true,
      has_label: false,
      defaultSize: 1
    },
    {
      id: "date",
      label: "Date",
      has_date_range: true,
      has_label: true,
      optionsHeader: (
        <div className="pb-4">
          <div className="pb-4 font-bold text-sg-orange">Date Options</div>
          <div>Select a start and end date if you want to set the date range attendees can choose from.</div>
        </div>
      )
    },
    {
      id: "select",
      label: "Dropdown List",
      options: true,
      has_label: true,
      optionsHeader: (
        <div className="pb-4">
          <div className="pb-4 font-bold text-sg-orange">Dropdown Options</div>
          <div>Only one option can be selected</div>
        </div>
      )
    },
    {
      id: "radio",
      label: "Radio List",
      options: true,
      has_label: true,
      optionsHeader: (
        <div className="pb-4">
          <div className="pb-4 font-bold text-sg-orange">Radio Options</div>
          <div>Only one option can be selected</div>
        </div>
      ),
      defaultSize: 1
    },
    {
      id: "phonenumber",
      label: "Phone Number"
    },
    {
      id: "checkbox",
      label: "Checkbox List",
      options: true,
      has_label: true,
      has_single_multi_selection: true,
      has_selection_range: true,
      optionsHeader: (
        <div className="pb-2">
          <div className="pb-4 font-bold text-sg-orange">Checkbox Options</div>
          <div>Select how many options can be selected</div>
        </div>
      ),
      defaultSize: 1
    },
    {
      id: "tickets",
      label: "Ticket List",
      has_label: false,
      form_type: ["registration"],
      defaultSize: 1
    },
    {
      id: "button",
      label: "Button",
      readOnly: true,
      has_label: true,
      has_function: true,
      function_options: [
        { value: "submit", label: "Submit" },
        { value: "next_page", label: "Next Page" },
        { value: "previous_page", label: "Previous Page" },
        { value: "link", label: "Link", href: true },
        { value: "cancel", label: "Cancel" },
        { value: "other", label: "Other", custom_fields: true },
        { value: "custom", label: "Custom (Advanced)", custom_fields: true }
      ]
    },
    {
      id: "page_signifiers",
      label: "Page Signifiers",
      has_label: false,
      readOnly: true
    },
    {
      id: "html",
      label: "Custom HTML (expert only)",
      has_label: false,
      has_html_content: true
    }
  ];
};

export const getTicketFields = (ticketTypes) => {
  return ticketTypes.map((ticketType) => {
    return { label: ticketType.name, value: ticketType.gid };
  });
};

export const getPackageFields = (packageTypes) => {
  return packageTypes.map((packageType) => {
    return { label: packageType.name, value: packageType.gid };
  });
};
