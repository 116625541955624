import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";

import CustomReportCustomize from "./CustomReportCustomize";
import CustomReportSave from "./CustomReportSave";
import CustomReportType from "./CustomReportType";

const CustomReportNew = (props) => {
  const { goBack, refreshReports } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [filterChains, setFilterChains] = useState([]);
  const [reportName, setReportName] = useState("");
  const [sortingCriteria, setSortingCriteria] = useState([]);
  const [reportType, setReportType] = useState({ id: null });
  const [phase, setPhase] = useState(1);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [objectKeys, setObjectKeys] = useState([]);

  console.log("Rendering ReportNew");

  useEffect(() => {
    const fetchFieldKeys = async () => {
      try {
        console.log("Fetching field map");
        const result = await axios(urljoin(apiRoot, "/reports/field_map", `/${reportType.id}`));
        setObjectKeys(result.data.objectKeys);
      } catch (error) {
        alertHttpError(error);
      }
    };

    if (reportType.id !== null) {
      fetchFieldKeys();
    }
  }, [apiRoot, reportType.id]);

  const reportTypeList = [
    {
      id: 0,
      key: "event_participant",
      label: "Participants"
    },
    {
      id: 1,
      key: "session",
      label: "Sessions"
    },
    {
      id: 3,
      key: "participant_ticket",
      label: "Tickets"
    }
    // {
    //   id: 2,
    //   key: "session_participant",
    //   label: "Session Participants"
    // }
  ];

  const updateSelectedKeys = (keys) => {
    // force new array to avoid problems with shallow equality preventing re-renders
    setSelectedKeys([...keys]);
  };

  const submitPhase1 = () => {
    console.log("phase 1 submitted");
    setPhase(2);
  };

  const submitPhase2 = () => {
    console.log("phase 2 submitted");
    setPhase(3);
  };

  const submitPhase3 = () => {
    console.log("phase 3 submitted");
    saveReport();
  };

  const saveReport = () => {
    console.log("Saving report...");
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const keys = selectedKeys.map((key) => key.value);
    axios({
      url: urljoin(apiRoot, "/reports"),
      method: "POST",
      data: {
        report_type: reportType,
        report_name: reportName,
        keys: keys,
        filter_chains: filterChains,
        sorting_criteria: sortingCriteria
      }
    }).then((response) => {
      if (response.data.error === null) {
        console.log("Report saved successfully");
        refreshReports();
        goBack();
      } else {
        alertError(`Error saving report: ${response.data.error}`);
      }
    });
  };

  switch (phase) {
    case 1:
      return (
        <CustomReportType
          goBack={goBack}
          reportType={reportType}
          reportTypes={reportTypeList}
          setReportType={setReportType}
          submit={submitPhase1}
        />
      );
    case 2:
      return (
        <CustomReportCustomize
          edit={false}
          filterChains={filterChains}
          goBack={goBack}
          objectKeys={objectKeys}
          selectedKeys={selectedKeys}
          setFilterChains={setFilterChains}
          setSelectedKeys={updateSelectedKeys}
          setSortingCriteria={setSortingCriteria}
          sortingCriteria={sortingCriteria}
          submit={submitPhase2}
        />
      );
    case 3:
      return (
        <CustomReportSave
          goBack={goBack}
          // reportEditable={reportEditable}
          // reportLocation={reportLocation}
          reportName={reportName}
          // setReportEditable={setReportEditable}
          // setReportLocation={setReportLocation}
          setReportName={setReportName}
          submit={submitPhase3}
        />
      );
    default:
      throw new Error("Invalid phase");
  }
};

export default CustomReportNew;
