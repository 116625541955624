import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import EventContext from "@event/EventContext";
import EventSessionContext from "../EventSessionContext";
import { renderDeleteButton, renderButton } from "@shared/FormUtils";

const EventSessionMeeting = props => {
  const { update } = props;
  const { apiRoot } = useContext(EventContext).values;
  const { session } = useContext(EventSessionContext);
  const [showOverride, setShowOverride] = useState(false);

  const generateMeeting = () => {
    const postData = {
      override: {
        host: null,
        cohosts: null,
        require_reg: null
      }
    };
    if (showOverride) {
      postData.override.host = document.getElementById(
        "sg-mgmt-session-mtg-host"
      ).value;
      postData.override.cohosts = document.getElementById(
        "sg-mgmt-session-mtg-cohosts"
      ).value;
      postData.override.require_reg = document.getElementById(
        "sg-mgmt-session-mtg-require-reg"
      ).checked;
    }
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/zoom"),
      method: "POST",
      data: postData
    })
      .then(response => {
        if (response.data.error === null) {
          update(response.data.session);
          alertSuccess("Generated Zoom meeting");
        } else {
          alertError("Failed generating Zoom meeting");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const generateWebex = () => {
    const webexUrl = document.getElementById("sg-mgmt-session-webex-url").value;
    const webexKey = document.getElementById("sg-mgmt-session-webex-key").value;

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/webex"),
      method: "POST",
      data: { webex_url: webexUrl, webex_key: webexKey }
    })
      .then(response => {
        if (response.data.error === null) {
          update(response.data.session);
          alertSuccess("Saved Webex URL");
        } else {
          alertError("Failed saving Webex URL");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const deleteMeeting = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/zoom"),
      method: "DELETE",
      data: {}
    })
      .then(response => {
        if (response.data.error === null) {
          update(response.data.session);
          alertSuccess("Deleted Zoom meeting");
        } else {
          alertError("Failed deleting Zoom meeting");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const meetingLink = url => (
    <a className="sg-mgmt-link sg-mgmt-zoom-url" href={url}>
      {url}
    </a>
  );

  /* eslint-disable dot-notation */
  const hasMeeting = () => {
    return !!session.data["zoom"];
  };

  const renderError = () => {
    if (session.data["zoom_failure"]) {
      return (
        <div>
          <b>Error:</b> {session.data["zoom_failure"]}
        </div>
      );
    }
    return <></>;
  };
  /* eslint-enable dot-notation */

  const toggleOverride = () => {
    setShowOverride(!showOverride);
  };

  const renderOverrideForm = () => {
    if (showOverride) {
      return (
        <div>
          <div className="sg-mgmt-form-row">
            <div className="sg-mgmt-form-input-container">
              <label>Host email</label>
              <input
                className="sg-mgmt-form-input"
                id="sg-mgmt-session-mtg-host"
                type="text"
                name="host"
                placeholder="Enter host email"
              />
            </div>
          </div>
          <div className="sg-mgmt-form-row">
            <div className="sg-mgmt-form-input-container">
              <label>Cohost emails (comma separated)</label>
              <input
                className="sg-mgmt-form-input"
                id="sg-mgmt-session-mtg-cohosts"
                type="text"
                name="cohost"
                placeholder="Enter cohost emails"
              />
            </div>
          </div>
          <div className="sg-mgmt-form-row">
            <div className="sg-mgmt-form-input-container">
              <label>Require Registration? (check to enable)</label>
              <input
                className="sg-mgmt-form-input"
                id="sg-mgmt-session-mtg-require-reg"
                type="checkbox"
                name="require_reg"
              />
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const overrideLabel = () => {
    if (showOverride) {
      return (
        <>
          <i className="fas fa-angle-down" /> Hide override fields
        </>
      );
    }
    return (
      <>
        <i className="fas fa-angle-right" /> Show override fields
      </>
    );
  };

  const renderWebexUrl = () => {
    if (session.webex_url) {
      return (
        <>
          <div>
            <b>Webex URL:</b> {meetingLink(session.webex_url)}
          </div>
          <div>
            <b>Webex host key:</b> {session.webex_key}
          </div>
        </>
      );
    }
    return (
      <div>
        <b>Webex URL:</b> (not set)
      </div>
    );
  };

  const renderOverrideToggle = () => {
    return (
      <div>
        <span className="sg-mgmt-1on1-request-toggle" onClick={toggleOverride}>
          {overrideLabel()}
        </span>
      </div>
    );
  };

  const renderMeetingDetails = () => {
    if (hasMeeting()) {
      return (
        <div>
          <div>
            <b>Host URL:</b> {meetingLink(session.meeting_host_url)}
          </div>
          <div>
            <b>Attendee URL:</b> {meetingLink(session.meeting_url)}
          </div>
          <div>
            {renderDeleteButton(
              "Delete Zoom Meeting",
              deleteMeeting
            )}
          </div>
        </div>
      );
    }
    return (
      <div>
        <p>
          <i>No meeting generated</i>
        </p>
        {renderError()}
        {renderButton("Generate Zoom Meeting", generateMeeting)}
        {renderOverrideToggle()}
        {renderOverrideForm()}
      </div>
    );
  };

  const renderWebexForm = () => {
    return (
      <div>
        <div className="sg-mgmt-form-row">
          <div className="sg-mgmt-form-input-container">
            <label>Webex URL</label>
            <input
              className="sg-mgmt-form-input"
              id="sg-mgmt-session-webex-url"
              type="text"
              name="webex_url"
              placeholder="Enter Webex URL"
            />
          </div>
          <div className="sg-mgmt-form-input-container">
            <label>Webex Host Key</label>
            <input
              className="sg-mgmt-form-input"
              id="sg-mgmt-session-webex-key"
              type="text"
              name="webex_key"
              placeholder="Enter Webex host key"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderWebexDetails = () => {
    return (
      <div>
        {renderWebexUrl()}
        {renderWebexForm()}
        {renderButton("Set Webex URL", generateWebex)}
      </div>
    );
  };

  return (
    <div>
      <h1>Meeting Details</h1>
      {renderMeetingDetails()}
      <h1>Webex Details</h1>
      {renderWebexDetails()}
    </div>
  );
};

EventSessionMeeting.propTypes = {
  update: PropTypes.func.isRequired
};

export default EventSessionMeeting;
