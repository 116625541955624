import React, { useContext, useEffect, useState } from "react";

import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventAnalyticsExternalReportForm from "@event/analytics/reports/EventAnalyticsExternalReportForm";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { isDeveloper } from "@shared/UserUtils";

import StandardReportsParticipants from "./StandardReportsParticipants";
import StandardReportsSessions from "./StandardReportsSessions";
import StandardReportsTicketing from "./StandardReportsTicketing";

const StandardReports = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [addExternal, setAddExternal] = useState(false);
  const [editExternal, setEditExternal] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [reports, setReports] = useState([]);
  const [historyDateTime, setHistoryDateTime] = useState(new Date());
  const confirm = useConfirm();

  useEffect(() => {
    const fetchReports = async () => {
      const result = await axios(urljoin(apiRoot, "/external_reports"));
      setReports(result.data.reports);
      setFetched(true);
    };

    fetchReports();
  }, [apiRoot]);

  const renderAddButton = () => {
    if (isDeveloper(user)) {
      return renderButton("Add External Report", () => setAddExternal(true));
    }
    return <></>;
  };

  const returnToReports = () => {
    setAddExternal(false);
  };

  const finishEdit = () => {
    setEditExternal(false);
    returnToReports();
  };

  const editReport = (report) => {
    setEditExternal(report);
  };

  const deleteReport = (report) => {
    setReports(reports.filter((r) => r.id !== report.id));
  };

  const performDelete = (report) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/external_reports", `/${report.id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteReport(report);
          alertSuccess("Report deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const confirmDelete = (report) => {
    confirm({
      title: "Confirm delete",
      description: "Are you sure you want to delete this external report?"
    })
      .then(() => {
        performDelete(report);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const renderExternalReports = () => {
    return (
      <div>
        {reports.map((r) => (
          <div key={r.gid}>
            <a href={r.url} className="sg-mgmt-link" data-turbolinks="false">
              {r.name}
            </a>
            {isDeveloper(user) ? (
              <>
                &nbsp;(
                <span className="sg-mgmt-link" onClick={() => editReport(r)}>
                  Edit
                </span>
                ) &nbsp;(
                <span className="sg-mgmt-link" onClick={() => confirmDelete(r)}>
                  Delete
                </span>
                )
              </>
            ) : (
                <></>
              )}
          </div>
        ))}
      </div>
    );
  };

  const renderReports = () => {
    return (
      <>
        {renderExternalReports()}
        <StandardReportsParticipants />
        <StandardReportsSessions />
        <StandardReportsTicketing />
      </>
    );
  };

  const renderContent = () => {
    if (!fetched) return <Loading />;

    if (addExternal) {
      return (
        <>
          <EventAnalyticsExternalReportForm cancel={returnToReports} callbackSuccess={returnToReports} />
        </>
      );
    }

    if (editExternal) {
      return (
        <>
          <EventAnalyticsExternalReportForm
            cancel={finishEdit}
            callbackSuccess={finishEdit}
            externalReport={editExternal}
          />
        </>
      );
    }

    return renderReports();
  };

  return (
    <div>
      <PageHeader text="Standard Reports" />
      <div className="mt-4">
        {renderContent()}
        {renderAddButton()}
      </div>
    </div>
  );
};

export default StandardReports;
