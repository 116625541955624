import React, { useContext, useState, useEffect } from "react";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import PageHeader from "@shared/PageHeader";
import { Link } from "react-router-dom";
import urljoin from "url-join";
import { renderCreateButton } from "@/shared/FormUtils";
import WebPageTable from "@/contents/web_pages/WebPageTable";
import { useParams } from "react-router-dom";
import ContentFrame from "@shared/ContentFrame";
import axios from "axios";

const TemplateWebPages = () => {
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { id } = useParams();
  const [ template, setTemplate ] = useState(null);
  const fetchTemplate = async () => {
    const response = await axios.get(urljoin(apiRoot, `/contents/web_page_templates/${id}`));
    setTemplate(response.data.web_page_template);
  }
  useEffect(() => {
    fetchTemplate();
  },[]);
  
  const renderAddWebPagesButton = () => {
    if(editEnabled()) {
      return (
        <Link to={`/content/web_pages/new?contents_web_page_template_id=${id}`}>
          {renderCreateButton("Add Web Page", ()=>{})}
        </Link>
      );
    }
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.static_files_edit) {
      return false;
    }
    return true;
  };


  const renderWebPagesTable = () => {
    return (<WebPageTable
      fetchUrl={urljoin(apiRoot, `/contents/web_page_templates/${id}/web_pages`)}
    />)
  };

  return (
    <ContentFrame>
      <PageHeader text={`${template ? template.name : "" } Web Pages`}  />
      {renderAddWebPagesButton()}
      <br />
      <br />
      {renderWebPagesTable()}
    </ContentFrame>
  );
};

export default TemplateWebPages;