import React, { useContext } from "react";

import EventContext from "@event/EventContext";

const EventDetailsEventInfo = () => {
  const eventContext = useContext(EventContext).values;
  const { event } = eventContext;

  const renderEventType = () => {
    switch (event.event_type) {
      case "physical":
        return "Physical";
      case "virtual":
        return "Virtual";
      case "hybrid":
        return "Hybrid";
      default:
        return "";
    }
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <div className="sg-mgmt-details-section">
        <div className="sg-mgmt-info-wrapper">
          <span className="sg-mgmt-info-label">Event Name:</span>
          &nbsp;
          {event.name}
        </div>
        <div className="sg-mgmt-info-wrapper">
          <span className="sg-mgmt-info-label">Event Type:</span>
          &nbsp;
          {renderEventType()}
        </div>
        <div className="sg-mgmt-info-wrapper">
          <span className="sg-mgmt-info-label">Site URL:</span>
          &nbsp;
          <a href={event.site_url}>{event.site_url}</a>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsEventInfo;
