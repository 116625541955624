import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { add, formatDistanceToNowStrict, isFuture, parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import EventMeetings1On1RequestsModalAdd from "./EventMeetings1On1RequestsModalAdd";
import EventMeetings1On1RequestsModalReview from "./EventMeetings1On1RequestsModalReview";

const EventMeetings1On1Requests = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { reviewRequestId, setReviewRequestId } = props;
  const [fetchedAttendees, setFetchedAttendees] = useState(false);
  const [fetchedHosts, setFetchedHosts] = useState(false);
  const [fetchedRequests, setFetchedRequests] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [requests, setRequests] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [showRejected, setShowRejected] = useState(false);

  useEffect(() => {
    const fetchHosts = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "meeting_hosts"));
        setHosts(result.data.hosts);
        setFetchedHosts(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchAttendees = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "attendees"));
        setAttendees(result.data);
        setFetchedAttendees(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchRequests = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "meeting1on1s/requests"));
        setRequests(result.data.requests);
        setFetchedRequests(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHosts();
    fetchAttendees();
    fetchRequests();
  }, []);

  const isReady = () => {
    return fetchedAttendees && fetchedHosts && fetchedRequests;
  };

  const modalAddOpen = () => {
    setModalAdd(true);
  };

  const modalAddClose = () => {
    setModalAdd(false);
  };

  const modalReviewReset = () => {
    setReviewRequestId(null);
  };

  const forceInt = integer => parseInt(integer, 10);

  const addRequest = request => {
    setRequests([request].concat(requests));
  };

  const removeRequest = requestId => {
    setRequests(
      requests.filter(req => forceInt(req.id) !== forceInt(requestId))
    );
  };

  const choiceText = choice => (forceInt(choice) === 1 ? "First" : "Second");

  const timeRemaining = timestamp => {
    const submitTime = parseISO(timestamp);
    const targetTime = add(submitTime, { hours: 24 });
    if (isFuture(targetTime)) {
      return formatDistanceToNowStrict(targetTime);
    }
    return "overdue!";
  };

  const dateTimeFormat = datetime => {
    const parsedDatetime = utcToZonedTime(datetime, event.time_zone);
    return format(parsedDatetime, "MMMM do, h:mm aaaa", {
      timeZone: event.time_zone
    });
  };

  const renderAddButton = () => {
    if (user.role === "executive") {
      return (
        <div className="sg-mgmt-content-header-button" onClick={modalAddOpen}>
          <img src="/images/icons/plus-orange-round.svg" alt="Add Meeting" />
        </div>
      );
    }
    return <></>;
  };

  const renderChoiceStatus = (request, choice) => {
    if (request.status === "submitted") {
      return (
        <span
          className="sg-mgmt-link"
          onClick={() => {
            setReviewRequestId(request.id);
          }}
        >
          Review
        </span>
      );
    }

    if (request.status === "approved") {
      if (parseInt(request.choice_approved, 10) === parseInt(choice, 10)) {
        return <span>approved</span>;
      }

      return <span>other choice approved</span>;
    }

    return <span>{request.status}</span>;
  };

  const renderRequestChoice = (request, choice) => {
    const host = request.hosts.find(
      h => forceInt(h.choice) === forceInt(choice)
    );
    if (!host) {
      return <></>;
    }

    // don't render the non-selected choices of approved requests
    if (
      request.status === "approved" &&
      parseInt(request.choice_approved, 10) !== parseInt(choice, 10)
    ) {
      return <></>;
    }

    return (
      <tr key={`${request.gid}-${choice}`}>
        <td>{`${request.attendee.name_first} ${request.attendee.name_last}`}</td>
        <td>
          {host
            ? `${host.meeting_host.name_first} ${host.meeting_host.name_last}`
            : "(host missing)"}
        </td>
        <td>{choiceText(choice)}</td>
        <td>
          {host ? dateTimeFormat(host.date_and_time) : "(time missing)"} PST
        </td>
        <td>{dateTimeFormat(request.created_at)} PST</td>
        {request.status === "submitted" ? (
          <td>{timeRemaining(request.created_at)}</td>
        ) : (
          <td>{dateTimeFormat(request.updated_at)}</td>
        )}
        <td>{renderChoiceStatus(request, choice)}</td>
      </tr>
    );
  };

  const renderComboRequest = request => {
    return (
      <tr key={`${request.gid}`}>
        <td>{`${request.attendee.name_first} ${request.attendee.name_last}`}</td>
        <td>
          {request.hosts.map(h => (
            <div key={h.id}>
              {`${h.meeting_host.name_first} ${h.meeting_host.name_last}`}
            </div>
          ))}
        </td>
        <td>Combo</td>
        <td>
          {request.hosts && request.hosts[0]
            ? dateTimeFormat(request.hosts[0].date_and_time)
            : "(time missing)"}{" "}
          PST
        </td>
        <td>{dateTimeFormat(request.created_at)} PST</td>
        {request.status === "submitted" ? (
          <td>{timeRemaining(request.created_at)}</td>
        ) : (
          <td>{dateTimeFormat(request.updated_at)}</td>
        )}
        <td>
          {request.status === "submitted" ? (
            <span
              className="sg-mgmt-link"
              onClick={() => {
                setReviewRequestId(request.id);
              }}
            >
              Review
            </span>
          ) : (
            <span>{request.status}</span>
          )}
        </td>
      </tr>
    );
  };

  const renderRequest = request => {
    if (request.combo) {
      return renderComboRequest(request);
    }
    return (
      <React.Fragment key={`${request.gid}`}>
        {renderRequestChoice(request, 1)}
        {renderRequestChoice(request, 2)}
      </React.Fragment>
    );
  };

  const renderNoRequests = () => {
    const filteredRequests = requests.filter(req => req.status === "submitted");
    if (filteredRequests.length === 0) {
      return (
        <div className="sg-mgmt-1on1-request-none">
          <p>No pending requests</p>
        </div>
      );
    }
    return <></>;
  };

  const toggleRequestsApproved = () => {
    const arrow = showApproved ? (
      <i className="fas fa-angle-down" />
    ) : (
      <i className="fas fa-angle-right" />
    );
    return (
      <div>
        <span
          className="sg-mgmt-1on1-request-toggle"
          onClick={() => {
            setShowApproved(!showApproved);
          }}
        >
          {arrow}&nbsp;List of approved requests
        </span>
      </div>
    );
  };

  const toggleRequestsRejected = () => {
    const arrow = showApproved ? (
      <i className="fas fa-angle-down" />
    ) : (
      <i className="fas fa-angle-right" />
    );
    return (
      <div>
        <span
          className="sg-mgmt-1on1-request-toggle"
          onClick={() => {
            setShowRejected(!showRejected);
          }}
        >
          {arrow}&nbsp;List of declined requests
        </span>
      </div>
    );
  };

  const renderRequests = status => {
    if (isReady()) {
      const filteredRequests = requests.filter(req => req.status === status);
      return (
        <div>
          <table className="sg-mgmt-table sg-mgmt-table-sessions sg-mgmt-1on1-request-table">
            <thead>
              <tr>
                <th>Request By</th>
                <th>Executive Requested</th>
                <th>Pick</th>
                <th>Meeting Date/Time</th>
                <th>Submitted At</th>
                {status === "submitted" ? (
                  <th>Time Left</th>
                ) : (
                  <th>Reviewed At</th>
                )}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{filteredRequests.map(req => renderRequest(req))}</tbody>
          </table>
        </div>
      );
    }
    return <Loading />;
  };

  const renderRequestsApproved = () => {
    if (isReady() && showApproved) {
      return <div>{renderRequests("approved")}</div>;
    }
    return <></>;
  };

  const renderRequestsRejected = () => {
    if (isReady() && showRejected) {
      return <div>{renderRequests("rejected")}</div>;
    }
    return <></>;
  };

  const renderContent = () => {
    if (isReady()) {
      return (
        <>
          {renderRequests("submitted")}
          {renderNoRequests()}
          <div className="sg-mgmt-1on1-request-closed-container">
            {toggleRequestsApproved()}
            {renderRequestsApproved()}
            {toggleRequestsRejected()}
            {renderRequestsRejected()}
          </div>
        </>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <div className="sg-mgmt-content-header">
        <PageHeader text="1:1 Meeting Requests" />
        {renderAddButton()}
      </div>
      <div className="sg-mgmt-event-meetings-wrapper">
        <p>
          You have 24 hours from when a request was submitted to approve it.
        </p>
        {renderContent()}
      </div>
      <EventMeetings1On1RequestsModalAdd
        addRequest={addRequest}
        attendees={attendees}
        hosts={hosts}
        modalVisible={modalAdd}
        resetModal={modalAddClose}
      />
      <EventMeetings1On1RequestsModalReview
        requestId={reviewRequestId}
        modalVisible={!!reviewRequestId}
        removeRequest={removeRequest}
        resetModal={modalReviewReset}
      />
    </div>
  );
};

EventMeetings1On1Requests.defaultProps = {
  reviewRequestId: null
};

EventMeetings1On1Requests.propTypes = {
  reviewRequestId: PropTypes.any,
  setReviewRequestId: PropTypes.func.isRequired
};

export default EventMeetings1On1Requests;
