import React from "react";

import { Field } from "formik";

const SelectField = (props) => {
  const { blankLabel = "Select option", fieldName, includeBlank = false, options, onChange = null } = props;

  const renderOption = (label, value) => (
    <option value={value} key={`${fieldName}-${value}`}>
      {label}
    </option>
  );

  const blankOption = () => {
    if (includeBlank) {
      return <option value="">{blankLabel}</option>;
    }
    return <></>;
  };

  const parseOption = (option) => {
    // string value, use string as both label and value
    if (typeof option === "string" || option instanceof String) {
      return renderOption(option, option);
    }

    // array value, expect first element label, second element value
    if (Array.isArray(option)) {
      return renderOption(option[0], option[1]);
    }

    // object value, expect label and value keys
    const { label, value } = option;
    return renderOption(label, value);
  };

  // HACK: figure out better way to conditionally pass onChange
  if (onChange) {
    return (
      <Field name={fieldName} onChange={onChange} as="select" className="sg-mgmt-form-input">
        {blankOption()}
        {options.map((opt) => parseOption(opt))}
      </Field>
    );
  }

  return (
    <Field name={fieldName} as="select" className="sg-mgmt-form-input">
      {blankOption()}
      {options.map((opt) => parseOption(opt))}
    </Field>
  );
};

export default SelectField;
