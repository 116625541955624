import React, { useState } from "react";
import PropTypes from "prop-types";
import { renderRegexField, renderTextField } from "@shared/FormUtils";

const EventDetailsRegistrationFieldFormOptions = props => {
  const { field } = props;
  const blankField = { slug: "", label: "" };
  const defaultOptions = field && field.options && field.options.length > 0
    ? field.event_field_options
    : [blankField];
  const [options, setOptions] = useState(defaultOptions);

  const addOption = () => {
    setOptions(prevOptions => prevOptions.concat([blankField]));
  };

  const renderOptionsField = idx => {
    return (
      <div className="sg-mgmt-form-row">
        <div className="flex w-1/2">
          <div className="sg-mgmt-form-input-container">
            {renderRegexField(
              "Slug (lowercase letters, numbers, hyphens only",
              `field[event_field_options][${idx}][slug]`,
              RegExp(/^[0-9a-z-]*$/)
            )}
          </div>
          <div className="sg-mgmt-form-input-container">
            {renderTextField(
              "Label (question)",
              `field[event_field_options][${idx}][label]`
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderOptionsFields = () => {
    return (
      <div>
        <p>Enter options for dropdown/checkboxes:</p>
        {options.map((opt, idx) => renderOptionsField(idx))}
        <span className="sg-mgmt-link" onClick={addOption}>
          + Add another option
        </span>
      </div>
    );
  };

  return renderOptionsFields();
};

EventDetailsRegistrationFieldFormOptions.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

EventDetailsRegistrationFieldFormOptions.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  field: PropTypes.object.isRequired
};

export default EventDetailsRegistrationFieldFormOptions;
