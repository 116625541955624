import React, { useState } from "react";
import clsx from 'clsx';
import EventMeetings1On1Analytics from "./analytics/EventMeetings1On1Analytics";
import EventMeetings1On1Hosts from "./hosts/EventMeetings1On1Hosts";
import EventMeetings1On1Schedule from "./schedule/EventMeetings1On1Schedule";
import EventMeetings1On1Requests from "./requests/EventMeetings1On1Requests";
import TabbedPage from "@shared/TabbedPage";

const EventMeetings1On1 = () => {
  const [reviewRequestId, setReviewRequestId] = useState(null);


  return (
    <TabbedPage
      tabs={[
        { label: "Meeting Schedule", component: EventMeetings1On1Schedule, componentProps: {setReviewRequestId: setReviewRequestId} },
        { label: "Meeting Requests", component: EventMeetings1On1Requests, componentProps: {reviewRequestId: reviewRequestId, setReviewRequestId: setReviewRequestId} },
        { label: "Manage Hosts", component: EventMeetings1On1Hosts, componentProps: {} },
        { label: "Analytics", component: EventMeetings1On1Analytics, componentProps: {} },
      ]}
    />
  );
};

export default EventMeetings1On1;
