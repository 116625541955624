import React, { useContext } from "react";

import { Stack } from "@mui/material";
import { Formik, Form } from "formik";

import EventContext from "@event/EventContext";
import { alertSuccess, alertError } from "@shared/Alerts";
import { renderCancelButton, renderSubmitButton, renderTextField } from "@shared/FormUtils";
import { useAddHousingHotelAmenity, useUpdateHousingHotelAmenity } from "@shared/hooks/useHousingHotels";

const HousingHotelAmenitiesForm = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { amenity, callbackFailure = () => { }, callbackSuccess = () => { }, cancelButton = () => { }, hotel } = props;
  const addHousingHotelAmenity = useAddHousingHotelAmenity(apiRoot, event.id, hotel.id);
  const updateHousingHotelAmenity = useUpdateHousingHotelAmenity(apiRoot, event.id, hotel.id);

  const isEdit = () => {
    return amenity && amenity.id;
  };

  const formInitialValues = () => {
    return {
      name: amenity?.name || "",
    };
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          amenity: formInitialValues()
        }}
        onSubmit={(_values, { setSubmitting, resetForm }) => {
          const form = document.getElementById("sg-mgmt-form-amenity");
          const formData = new FormData(form);

          if (isEdit()) {
            updateHousingHotelAmenity.mutate(
              {
                hotelId: hotel.id,
                amenityId: amenity.id,
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    alertSuccess("Amenity saved successfully");
                    resetForm();
                    callbackSuccess();
                  } else {
                    callbackFailure();
                    alertError(data.error);
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertError(error);
                }
              }
            );
          } else {
            addHousingHotelAmenity.mutate(
              {
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                    resetForm();
                  } else {
                    callbackFailure();
                    alertError(data.error);
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertError(error);
                }
              }
            );
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id="sg-mgmt-form-amenity">
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">{renderTextField("Name", "amenity[name]")}</div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton("Save", isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default HousingHotelAmenitiesForm;
