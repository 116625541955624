import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import { parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { Field, Formik, Form } from "formik";
// import range from "lodash/range";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import DateTimePickerField from "@shared/DateTimePickerField";
import IntegerField from "@shared/IntegerField";
import SelectField from "@shared/SelectField";
import EventContext from "@event/EventContext";
import EventMeetings1On1BlankMeeting from "./EventMeetings1On1BlankMeeting";
import EventMeetings1On1Context from "../EventMeetings1On1Context";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const EventMeetings1On1FormEdit = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { meeting } = useContext(EventMeetings1On1Context);
  const {
    callbackFailure,
    callbackSuccess,
    cancel,
    hosts,
    updateHosts
  } = props;

  const isEdit = () => {
    return meeting && meeting.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-meeting-edit",
        formUrl: urljoin(apiRoot, "/meeting1on1s", `/${meeting.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit Meeting"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-meeting-add",
      formUrl: urljoin(apiRoot, "/meeting1on1s"),
      method: "POST",
      saveButton: "Continue",
      title: "Add Meeting"
    };
  })();

  const dateTimeInitialValue = () => {
    if (meeting.date_and_time_local) {
      return parseISO(meeting.date_and_time_local);
    }
    if (event.date_begin) {
      return parseISO("2021-09-29");
    }
    return new Date();
  };

  const renderDataField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <Field
        className="sg-mgmt-form-input"
        type="text"
        name={`meeting[data][${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderDateTimeField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <DateTimePickerField
        className={clsx("sg-mgmt-form-input", "sg-mgmt-form-input-time")}
        selected={new Date("2021-09-29T07:00:00")}
        minDate={new Date("2021-09-29T00:00:00")}
        maxDate={new Date("2021-10-01T00:00:00")}
        minTime={new Date(2000, 1, 1, 7, 0)}
        maxTime={new Date(2000, 1, 1, 19, 30)}
        timeIntervals={60}
        name={`meeting[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderIntegerField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <IntegerField
        className="sg-mgmt-form-input"
        name={`meeting[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderMeetingType = () => {
    const options = ["General (AR/PR)", "Prospect", "Customer", "Partner"];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Meeting Type</label>
        <SelectField fieldName="meeting[meeting_type]" options={options} />
      </div>
    );
  };

  const renderUseCase = () => {
    const options = [
      "DevSecOps for Kubernetes",
      "Tired Analytics/Credit Based Licensing",
      "Security Intelligence",
      "Solution (Cloud SIEM, SOC Analytics & Automation)",
      "Observability",
      "Other"
    ];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Meeting Type</label>
        <SelectField fieldName="meeting[data][use_case]" options={options} />
      </div>
    );
  };

  const renderHostsAttendees = () => (
    <div className="sg-mgmt-1on1-view-attendees">
      {meeting.meeting_hosts.map(h => (
        <div key={`host-${h.id}`}>
          <span className="sg-mgmt-1on1-view-label">Executive:&nbsp;</span>
          {`${h.name_first} ${h.name_last}`}
        </div>
      ))}
      {meeting.event_participants.map(a => (
        <div key={`att-${a.id}`}>
          <span className="sg-mgmt-1on1-view-label">Attendee:&nbsp;</span>
          {`${a.name_first} ${a.name_last}`}
        </div>
      ))}
    </div>
  );

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        meeting_url: meeting.meeting_url,
        date_and_time: dateTimeInitialValue(),
        length_minutes: 45,
        meeting_hosts: [],
        event_participants: [],
        data: {
          objective_1: meeting.data.objective_1,
          objective_2: meeting.data.objective_2,
          objective_3: meeting.data.objective_3,
          outcome: meeting.data.outcome,
          use_case: meeting.data.use_case,
          attendee_details: {},
          stage_account: meeting.data.stage_account,
          potential_revenue: meeting.data.potential_revenue,
          close_date: meeting.data.close_date,
          notes: meeting.data.notes
        }
      };
    }
    const initialVals = EventMeetings1On1BlankMeeting;
    initialVals.date_and_time = parseISO(`2021-11-30T09:00:00`);
    return EventMeetings1On1BlankMeeting;
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        meeting: formInitialValues()
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);

        // convert times to UTC for submission to back-end
        formData.set(
          "meeting[date_and_time]",
          zonedTimeToUtc(values.meeting.date_and_time, event.time_zone)
        );

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              updateHosts(response.data.hosts);
              callbackSuccess();
              alertSuccess("Meeting updates");
            } else {
              callbackFailure(response);
              alertError(response.data.error);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              <h2>Meeting Details</h2>
              {renderHostsAttendees()}
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-row-column">
                  {renderMeetingType()}
                  {renderDataField("Objective 1", "objective_1")}
                  {renderDataField("Objective 2", "objective_2")}
                  {renderDataField("Objective 3", "objective_3")}
                  {renderDataField("Desired Outcome", "outcome")}
                </div>
                <div className="sg-mgmt-form-row-column">
                  {renderUseCase()}
                  {renderDataField("Company Overview", "company_overview")}
                  {renderDataField("Stage of Account", "stage_account")}
                  {renderDataField("Potential Revenue", "potential_revenue")}
                  {renderDataField("Close Date", "close_date")}
                  {renderDataField("Notes/Comments", "notes")}
                </div>
              </div>
            </div>
            <div className="sg-mgmt-form-section">
              <h2>Meeting Date and Time</h2>
              <div className="sg-mgmt-form-row">
                {renderDateTimeField(
                  `Date and Start Time (timezone: ${event.time_zone})`,
                  "date_and_time",
                  "sg-mgmt-form-input-date-time"
                )}
              </div>
            </div>
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton(formConfig.saveButton, isSubmitting)}
            {renderCancelButton("Cancel", cancel)}
          </Stack>
        </Form>
      )}
    </Formik>
  );

  return <>{renderForm()}</>;
};

EventMeetings1On1FormEdit.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {}
};

EventMeetings1On1FormEdit.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancel: PropTypes.func.isRequired,
  hosts: PropTypes.array.isRequired,
  updateHosts: PropTypes.func.isRequired
};

export default EventMeetings1On1FormEdit;
