import React from "react";

import TabbedPage from "@shared/TabbedPage";

import Metadata from "../details/metadata/Metadata";
import EventDetailsRegistration from "../details/registration/EventDetailsRegistration";

const EventMetadata = () => {
  return (
    <TabbedPage
      tabs={[
        { label: "Custom Fields", component: Metadata, componentProps: {} },
        {
          label: "Reg Questions (Old)",
          component: EventDetailsRegistration,
          componentProps: {}
        }
      ]}
    />
  );
};

export default EventMetadata;
