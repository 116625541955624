
import React from 'react'

import WidgetDownloadIcon from "@dashboard/form/WidgetDownloadIcon";

const StandardReportDownload = (props) => {
  const { url, label, key } = props;
  return (
    <div className="mb-1 flex" key={key}>
      <div style={{ width: "fit-content" }}>
        <a href={url}>
          <span className="cursor-pointer">
            <WidgetDownloadIcon size={20} />
          </span>
        </a>
      </div>
      <div className="ml-1">
        <a href={url}>
          <span className="cursor-pointer">
            {label}
          </span>
        </a>
      </div>
    </div>
  );
}

export default StandardReportDownload;
