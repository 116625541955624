import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const EventSessionTypeEdit = ({ setEditType, updateType, sessionType }) => {
  const inputField = useRef(null);

  // submit on enter key
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (inputField.current === document.activeElement) {
          submitType();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const submitType = () => {
    const name = inputField.current.value;
    updateType(sessionType.id, name);
    setEditType(null);
  };

  return (
    <div
      className="flex w-96 text-white bg-opacity-70 bg-sg-orange mr-2"
      key={`sessionType-${sessionType.gid}-${sessionType.id}`}
      data-gid={sessionType.gid}
    >
      <div className="flex-grow py-2 px-2">
        <input
          ref={inputField}
          type="text"
          name="sessionTypeName"
          defaultValue={sessionType.name}
        />
      </div>
      <div className="flex-initial py-2 pr-4">
        <span className="cursor-pointer" onClick={() => setEditType(null)}>
          <i className="fas fa-solid fa-ban" />
        </span>
      </div>
      <div
        className="flex-initial cursor-pointer"
        style={{ display: "inline", padding: "0 4px" }}
        onClick={() => submitType()}
      >
        <i className="fas fa-solid fa-check" />
      </div>
    </div>
  );
};

EventSessionTypeEdit.propTypes = {
  setEditType: PropTypes.func.isRequired,
  updateType: PropTypes.func.isRequired,
  sessionType: PropTypes.object.isRequired
};

export default EventSessionTypeEdit;
