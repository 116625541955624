import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import EventUsersForm from "./EventUsersForm";

const EventUsersModal = props => {
  const {
    eventUsers,
    modalVisible,
    resetModal,
    title,
    updateFunc,
    user,
    users
  } = props;

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <EventUsersForm
            eventUsers={eventUsers}
            reset={resetModal}
            update={updateFunc}
            user={user}
            users={users}
          />
        </div>
      </div>
    </Modal>
  );
};

EventUsersModal.defaultProps = {
  eventUsers: [],
  users: []
};

EventUsersModal.propTypes = {
  eventUsers: PropTypes.array,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  users: PropTypes.array
};

export default EventUsersModal;
