import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import clsx from 'clsx';
import { Field, Formik, Form } from "formik";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderTextField } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import { Stack } from "@mui/material";
import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";

const EventDetailsIntegrationsMarketo = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [marketo, setMarketo] = useState({});

  const fetchMarketo = async () => {
    try {
      const result = await axios(
        urljoin(apiRoot, "/details/integrations/marketo")
      );
      setMarketo(result.data.marketo);
      setFetched(true);
    } catch (error) {
      alertHttpError(error);
    }
  };

  useEffect(() => {
    fetchMarketo();
  }, []);

  const formConfig = {
    alert: "added",
    formId: "sg-mgmt-form-integration-marketo",
    formUrl: urljoin(apiRoot, `/details/integrations/marketo/${marketo.id}`),
    method: "PATCH",
    saveButton: "Update",
    title: "Marketo Integration"
  };

  const formInitialValues = () => {
    return {
      host: marketo.host || "",
      client_id: marketo.client_id || "",
      client_secret: marketo.client_secret || "",
      partner_id: marketo.partner_id || "",
      authorized_user: marketo.authorized_user || "",
      retry_authentication: marketo.retry_authentication || true,
      retry_authentication_count: marketo.retry_authentication_count || 3,
      retry_authentication_wait_seconds:
        marketo.retry_authentication_wait_seconds || 1
    };
  };

  const renderForm = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <Formik
        initialValues={{
          marketo: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              alertSuccess("Marketo integration updated");
              setSubmitting(false);
            } else {
              alertError(
                `Failed updating Marketo integration: ${response.data.error}`
              );
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Host", "marketo[host]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Client ID", "marketo[client_id]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Client Secret", "marketo[client_secret]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField(
                  "Parther ID (optional)",
                  "marketo[partner_id]"
                )}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Authorized User", "marketo[authorized_user]")}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default EventDetailsIntegrationsMarketo;
