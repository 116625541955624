import React, { useEffect, useRef, useState } from "react";

import Modal from "react-modal";
import Cookies from "universal-cookie";

import WidgetDownloadIcon from "@dashboard/form/WidgetDownloadIcon";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    minWidth: "400px"
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000
  }
};

Modal.setAppElement("#root");

const CustomReportDownload = (props) => {
  const { url, useIcon = false, label = "Download" } = props;
  const downloadUrlRef = useRef(null);
  const pollUrlRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [pollUrl, setPollUrl] = useState(null);
  const [pollCount, setPollCount] = useState(0);
  const [downloadError, setDownloadError] = useState(null);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    pollUrlRef.current = pollUrl;
    downloadUrlRef.current = downloadUrl;
  }, [downloadUrl, pollUrl]);

  const openModal = () => {
    setModalOpen(true);
    generateDownloadRequest();
  };

  const closeModal = () => {
    setModalOpen(false);
    setDownloadUrl(null);
    setPollUrl(null);
    setPollCount(0);
    setDownloadError(null)
    setDownloading(false);
  };

  const generateDownloadRequest = () => {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(url, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.error == null) {
          const poll_url = json.fetch_path;
          setPollUrl(poll_url);
          setTimeout(fetchDownload, 10000);
        } else {
          setDownloadError(json.error);
          console.log(json.error);
        }
      })
      .catch((error) => {
        console.log(error);
        setDownloadError(error);
      });
  };

  const fetchDownload = () => {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(pollUrlRef.current, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.error !== null) {
          setDownloadError(json.error);
          console.log(json.error);
        } else {
          if (json.complete) {
            setDownloadUrl(json.url);
          } else {
            setPollCount(pollCount + 1);
            setTimeout(fetchDownload, 10000);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setDownloadError(error);
      });
  };

  const closeAfterDownload = () => {
    setDownloading(true);
    setTimeout(() => {
      setModalOpen(false);
      setDownloadUrl(false);
      setDownloading(false);
    }, 1000);
  };

  const renderModal = () => {
    if (downloading) {
      return (
        <>
          <p>Starting download...</p>
        </>
      );
    }

    if (downloadUrl) {
      return (
        <>
          <p>Your report is ready.</p>
          <a
            className="sg-mgmt-link cursor-pointer"
            onClick={closeAfterDownload}
            href={downloadUrl}
            target="_blank"
            rel="noreferrer"
          >
            Click here to download report
          </a>
        </>
      );
    }

    if (downloadError) {
      return (
        <>
          <p>
            <b>Sorry!</b>
          </p>
          <p>There was an issue generating the report.</p>
          <p>Please try again later.</p>
          <p>Error: {downloadError}</p>
        </>
      );
    }

    if (pollCount > 30) {
      return (
        <>
          <p>
            <b>Sorry!</b>
          </p>
          <p>There was an issue generating the report.</p>
          <p>Please try again later.</p>
        </>
      );
    }

    return (
      <>
        <p>Generating the requested report</p>
        <p>Please wait...</p>
      </>
    );
  };

  const downloadLink = () => {
    if (useIcon) {
      return (
        <span className="cursor-pointer" onClick={openModal}>
          <WidgetDownloadIcon size={20} />
        </span>
      );
    }

    return (
      <span className="cursor-pointer" onClick={openModal}>
        {label}
      </span>
    );
  };

  return (
    <>
      {downloadLink()}
      <Modal isOpen={modalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="pdfs">
        {renderModal()}
      </Modal>
    </>
  );
};

export default CustomReportDownload;
