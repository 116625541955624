import { useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchEventMetadata = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/meta")
  });
};

export const useEventMetadata = (eventId) => {
  const apiRoot = `/-/events/${eventId}/`;
  return useQuery({
    queryKey: ["eventMetadata", eventId],
    queryFn: () => fetchEventMetadata(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useRefreshEventMetadata = (eventId) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ["eventMetadata", eventId] });
  };
};
