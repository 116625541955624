import React, { useContext, useEffect, useState } from "react";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import ContentFrame from "@shared/ContentFrame";
import WebPageForm from "./forms/WebPageForm";
import { useHistory, useParams } from "react-router-dom";

const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const WebPagesEdit = () => {
  const history = useHistory();
  const { id } = useParams();




  const templateId = getUrlParameter('contents_web_page_template_id');


  return (
    <ContentFrame>
      <WebPageForm 
        webPageId={id != "new" ? id : null}
        cancel={() => history.push(`/content/web_pages`)}
        templateId={id == "new" && templateId ? templateId : null} 
      />
    </ContentFrame>
  );
}

export default WebPagesEdit