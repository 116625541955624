import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import clsx from "clsx";
import { Path } from "path-parser";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import TabbedPage from "@shared/TabbedPage";
import EventContext from "@event/EventContext";
import EventSessionContext from "./EventSessionContext";
import EventSessionDetails from "./EventSessionDetails";
import EventSessionGeneralInfo from "./EventSessionGeneralInfo";
import EventSessionAttendance from "./attendance/EventSessionAttendance";
import EventSessionMeeting from "./meeting/EventSessionMeeting";
import EventSessionSpeakers from "./speakers/EventSessionSpeakers";

const EventSession = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [config, setConfig] = useState({});
  const [session, setSession] = useState(null);
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    const path = new Path(`/${event.slug}/session/:id`);
    const parsedPath = path.test(window.location.pathname);
    const fetchSession = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/sessions", `/${parsedPath.id}`)
        );
        setConfig(result.data.config);
        setSession(result.data.session);
        setTags(result.data.tags);
        setTypes(result.data.types);
        setVenues(result.data.venues);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchSession();
  }, [apiRoot, event]);

  const updateSession = ses => {
    setSession(ses);
  };

  const context = () => ({
    config,
    session,
    tags,
    types,
    venues
  });

  return (
    <EventSessionContext.Provider value={context()}>
      <TabbedPage
        tabs={[
          { label: "General Info", component: EventSessionGeneralInfo, componentProps: { update: updateSession }},
          { label: "Details",      component: EventSessionDetails,     componentProps: { update: updateSession }},
          { label: "Attendance",   component: EventSessionAttendance,  componentProps: { update: updateSession }},
          { label: "Speakers",     component: EventSessionSpeakers,    componentProps: { update: updateSession }},
          { label: "Meeting",      component: EventSessionMeeting,     componentProps: { update: updateSession }},
        ]}
      />
    </EventSessionContext.Provider>
  );
};

export default EventSession;
