import React from "react";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const Bubble = (props) => {
  const {
    colorBack = "#D2450F",
    colorText = "#ffffff",
    downloadLink,
    label = "",
    marginLeft = 4,
    marginRight = 4,
    scaleFactor = 0.2666,
    size = 150,
    twClasses = "",
    value = 0
  } = props;

  const renderDownloadBadge = () => {
    const badgeSize = Math.round(size * scaleFactor);

    if (downloadLink) {
      return (
        <div
          className="number-circle-badge absolute bottom-0 right-0 overflow-hidden text-center"
          style={{
            backgroundColor: "#1ba310",
            borderRadius: Math.round(badgeSize * 0.5),
            color: "#ffffff",
            fontSize: Math.round(badgeSize * 0.5),
            lineHeight: `${badgeSize}px`,
            height: badgeSize,
            width: badgeSize
          }}
        >
          <FontAwesomeIcon icon={faFileDownload} />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div
      className="number-circle-container relative"
      style={{
        marginLeft: marginLeft,
        marginRight: marginRight,
        height: size,
        width: size
      }}
    >
      <div
        className={clsx("number-circle flex items-center justify-center overflow-hidden text-center", twClasses)}
        style={{
          backgroundColor: colorBack,
          color: colorText,
          borderRadius: Math.round(size * 0.5),
          height: size,
          width: size
        }}
      >
        <div>
          <div style={{ fontSize: Math.round(size * scaleFactor) }}>{value}</div>
          <div className="px-3 leading-tight" style={{ fontSize: Math.round(Math.max(size * 0.1, 10)) }}>
            {label}
          </div>
        </div>
      </div>
      {renderDownloadBadge()}
    </div>
  );
};

export default Bubble;
