import React from "react";

import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";
import { requiredProp } from "@shared/PropUtils";

import HousingRoomBlockForm from "./HousingRoomBlockForm";

const HousingRoomBlockModal = props => {
  const {
    block,
    modalVisible,
    resetModal,
    title
  } = props;
  requiredProp("block", block);
  requiredProp("resetModal", resetModal);
  requiredProp("title", title);

  Modal.setAppElement("#root");

  const success = () => {
    alertSuccess("Room Block saved successfully");
    resetModal();
  };

  const failure = () => {
    alertError("Failed to save room block");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingRoomBlockForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            block={block}
          />
        </div>
      </div>
    </Modal>
  );
};

export default HousingRoomBlockModal;
