import React, { useState } from "react";
import PropTypes from "prop-types";
import EventSessionGeneralInfoEdit from "./EventSessionGeneralInfoEdit";
import EventSessionGeneralInfoView from "./EventSessionGeneralInfoView";

const EventSessionGeneralInfo = props => {
  const [mode, setMode] = useState("view");

  switch (mode) {
    case "view":
      return <EventSessionGeneralInfoView {...props} setMode={setMode} />;
    case "edit":
      return <EventSessionGeneralInfoEdit {...props} setMode={setMode} />;
    default:
      throw new Error("invalid session details mode");
  }
};

EventSessionGeneralInfo.propTypes = {
  update: PropTypes.func.isRequired
};

export default EventSessionGeneralInfo;
