import React, { useEffect, useState, useContext } from "react";

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Stack, Link } from "@mui/material";

import EventContext from "@event/EventContext";
import { defaultButtons, uploaderConfig } from "@shared/forms/RichTextEditorConfig";
import {
  renderHtmlEditorField,
  renderTextField,
  renderRichTextAreaField,
  renderSelectFieldFilterable,
  renderCheckField,
  renderRadioField,
  renderDateField,
  renderCheckFieldForcedValue
} from "@shared/FormUtils";
import HelperIcon from "@shared/helpers/HelperIcon";

import FormsFormPageQuestionImportOptions from "./FormsFormPageQuestionImportOptions";
import FormsFormPageQuestionOption from "./FormsFormPageQuestionOption";
import FormsFormDataPointHelperContent from "./helpers/FormsFormDataPointHelperContent";
import FormsFormDataPointHelperContentNonReg from "./helpers/FormsFormDataPointHelperContentNonReg";
import FormsFormFieldTypeHelperContent from "./helpers/FormsFormFieldTypeHelperContent";
import FormsFormOptionsHelperContent from "./helpers/FormsFormOptionsHelperContent";
import { getParticipantFields, getQuestionTypes, getTicketFields, getPackageFields } from "./utils/QuestionUtils";

const FormsFormPageQuestionForm = ({
  question_key,
  question,
  form,
  metaData,
  ticketTypes,
  packageTypes,
  openMetaDataModal,
  formikProps,
  expandRetractSettings
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.gid });
  const { apiRoot } = useContext(EventContext).values;
  const [optionImportModalOpen, setOptionImportModalOpen] = useState(false);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (expandRetractSettings && expandRetractSettings.value) {
      switch (expandRetractSettings.value) {
        case "expand":
          setOpen(true);
          break;
        case "retract":
          setOpen(false);
          break;
      }
    }
  }, [expandRetractSettings.updatedAt]);

  //I HATE THIS
  useEffect(() => {
    const date = question.properties.maximum_date;
    if (date && typeof date === "string") {
      question.properties.maximum_date = new Date(date);
    }
  }, [question.properties.maximum_date]);

  useEffect(() => {
    const date = question.properties.minimum_date;
    if (date && typeof date === "string") {
      question.properties.minimum_date = new Date(date);
    }
  }, [question.properties.minimum_date]);

  const toggleRichTextLabel = () => {
    formikProps.setFieldValue(
      `${question_key}[properties][use_rich_text_label]`,
      !question.properties.use_rich_text_label
    );
    formikProps.setFieldValue(`${question_key}[label]`, "");
  };

  useEffect(() => {
    const questionType = getQuestionType();
    if (questionType.has_single_multi_selection && !question.properties.single_multi_selection_type) {
      question.properties.single_multi_selection_type = "multiple";
    }
  }, [question.question_type]);

  const renderOptions = () => {
    return (
      <div className="flex w-full">
        <div className="mr-4 w-2/3">
          {/*Left Menu*/}
          <div className="mr-4 w-full">
            {renderSelectFieldFilterable(
              "Field Type",
              `${question_key}[question_type]`,
              getQuestionTypes()
                .filter((question_type) => {
                  if (question_type.form_type) {
                    return question_type.form_type.indexOf(form.form_type) != -1;
                  }
                  return true;
                })
                .map((x) => {
                  return {
                    value: x.id,
                    label: x.label
                  };
                }),
              [],
              [],
              true,
              {
                helper: true,
                placeholder: "Please select a field type",
                helperProperties: {
                  modalProps: {
                    header: "Field Types",
                    content: <FormsFormFieldTypeHelperContent />
                  }
                }
              }
            )}
          </div>
          {getQuestionType().has_label ? (
            <div className="mr-4 w-full">
              {question.properties.use_rich_text_label
                ? renderRichTextAreaField("Label", `${question_key}[label]`, [], {
                    enableLinks: true
                  })
                : renderTextField("Label", `${question_key}[label]`, [], false, false, {})}
              <div className="-mt-1 mb-2">
                <Link className="no-underline" component="button" type="button" onClick={toggleRichTextLabel}>
                  {question.properties.use_rich_text_label
                    ? "Change label to simple label"
                    : "Change label to rich text"}
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="mr-4 w-full">
            {!getQuestionType().readOnly ? (
              form.form_type == "registration" ? (
                <>
                  {renderSelectFieldFilterable(
                    "Select Data Point",
                    `${question_key}[attribute_id]`,
                    getParticipantFields({ metadata: metaData }).map((x) => {
                      return {
                        value: x.key,
                        label: x.label
                      };
                    }),
                    [],
                    [],
                    true,
                    {
                      labelAdditions: renderDataPointModal(),
                      helper: true,
                      helperProperties: {
                        modalProps: {
                          header: "Data Points",
                          content: <FormsFormDataPointHelperContent openMetaDataModal={openMetaDataModal} />
                        }
                      }
                    }
                  )}
                </>
              ) : (
                renderTextField("Select Data Point", `${question_key}[attribute_id]`, [], false, true, {
                  helper: true,
                  helperProperties: {
                    modalProps: {
                      header: "Data Points",
                      content: <FormsFormDataPointHelperContentNonReg />
                    }
                  }
                })
              )
            ) : (
              ""
            )}
          </div>
          {getQuestionType().optionsHeader || ""}
          {getQuestionType().has_single_multi_selection ? (
            <Stack direction="row" spacing={2}>
              {renderCheckFieldForcedValue(
                "Multiple Selections",
                `${question_key}[properties][single_multi_selection_type]`,
                "multiple"
              )}
              {renderCheckFieldForcedValue(
                "Single Selection",
                `${question_key}[properties][single_multi_selection_type]`,
                "single"
              )}
            </Stack>
          ) : (
            ""
          )}
          {question.properties.single_multi_selection_type == "multiple" && getQuestionType().has_selection_range ? (
            <Stack direction="row" spacing={2}>
              {renderTextField(
                "Minimum Selections",
                `${question_key}[properties][minimum_selections]`,
                [],
                false,
                false,
                { fieldProperties: { type: "number" } }
              )}
              {renderTextField(
                "Maximum Selections",
                `${question_key}[properties][maximum_selections]`,
                ["pb-4"],
                false,
                false,
                { fieldProperties: { type: "number" } }
              )}
            </Stack>
          ) : (
            ""
          )}
          {getQuestionType().options ? renderQuestionOptions() : ""}
          {question.use_default_value
            ? renderTextField("Default Value", `${question_key}[default_value]`, [], false, true)
            : ""}

          <Stack direction="row" spacing={2}>
            {getQuestionType().has_date_range ? (
              <div className="w-1/2">
                {renderDateField("Minimum Date (Optional)", `${question_key}[properties][minimum_date]`)}
              </div>
            ) : (
              ""
            )}

            {getQuestionType().has_date_range ? (
              <div className="w-1/2">
                {renderDateField("Maximum Date (Optional)", `${question_key}[properties][maximum_date]`)}
              </div>
            ) : (
              ""
            )}
          </Stack>

          {question.use_custom_class
            ? renderTextField("Custom Classes (HTML)", `${question_key}[custom_class]`, [], false, true)
            : ""}
          {question.use_custom_css
            ? renderHtmlEditorField("Custom Styles (CSS)", `${question_key}[[custom_css]`, [], false, true)
            : ""}

          {getQuestionType().has_content && !getQuestionType().has_html_content
            ? renderRichTextAreaField("Content", `${question_key}[content]`, [], {
                enableLinks: true,
                fieldProperties: {
                  config: {
                    ...uploaderConfig(apiRoot),
                    buttons: defaultButtons.concat(["image"])
                  }
                }
              })
            : ""}

          {getQuestionType().has_html_content ? renderHtmlEditorField("HTML Content", `${question_key}[content]`) : ""}

          {getQuestionType().has_function
            ? renderSelectFieldFilterable(
                "Function",
                `${question_key}[properties][function_type]`,
                getQuestionType().function_options || [],
                false,
                true
              )
            : ""}
          {getQuestionType().has_function &&
          (getQuestionType().function_options.find((x) => x.value == (question.properties || {}).function_type) || {})
            .custom_fields ? (
            <>
              {renderTextField(
                "Custom Function ID",
                `${question_key}[properties][custom_function_id]`,
                [],
                false,
                true
              )}
            </>
          ) : (
            ""
          )}
          {getQuestionType().has_function &&
          (getQuestionType().function_options.find((x) => x.value == (question.properties || {}).function_type) || {})
            .href ? (
            <>
              {renderTextField("Link URL", `${question_key}[properties][href]`, [], false, true)}
              {renderSelectFieldFilterable(
                "Open link in new window",
                `${question_key}[properties][open_link_in_new_window]`,
                [
                  { label: "Yes", value: true },
                  { label: "No", value: false }
                ]
              )}
            </>
          ) : (
            ""
          )}
          {question.use_custom_size ? renderCustomSizeOptions() : ""}
          {question.use_parent_child ? renderParentChildOptions() : ""}
        </div>

        <div className="w-1/3">
          <b>Field Options</b>
          <HelperIcon
            modalProps={{
              header: "Field Options",
              content: <FormsFormOptionsHelperContent />
            }}
          />
          <br />
          {renderCheckField("Required Field", `${question_key}[required]`, ["mb-0"])}
          {renderCheckField("Default Value", `${question_key}[use_default_value]`, ["mb-0 -mt-2"])}
          {renderCheckField("Hide from Reports", `${question_key}[hide_on_reports]`, ["mb-0 -mt-2"])}
          {renderCheckField("Child Field", `${question_key}[use_parent_child]`, ["mb-0 -mt-2"])}
          {renderCheckField("Disabled Field", `${question_key}[disabled]`, ["mb-0 -mt-2"])}
          {renderCheckField("Hidden Field", `${question_key}[hidden]`, ["mb-0 -mt-2"])}
          {renderCheckField("Custom Size", `${question_key}[use_custom_size]`, ["mb-0 -mt-2"])}
          {renderCheckField("Custom Classes (HTML)", `${question_key}[use_custom_class]`, ["mb-0 -mt-2"])}
          {renderCheckField("Custom Styles (CSS)", `${question_key}[use_custom_css]`, ["mb-0 -mt-2"])}
        </div>
      </div>
    );
  };

  const renderCustomSizeOptions = () => {
    return (
      <div>
        <div>
          <b className="text-sg-orange">Custom Size Options </b>
        </div>
        <div className="flex">
          {renderCustomSize(0)}
          {renderCustomSize(1)}
          {renderCustomSize(2)}
          {renderCustomSize(3)}
          {renderCustomSize(4)}
        </div>
      </div>
    );
  };

  const renderCustomSize = (size) => {
    const active = question.custom_size == size;

    return (
      <div
        className="mr-2 w-full cursor-pointer"
        onClick={() => formikProps.setFieldValue(`${question_key}[custom_size]`, size)}
      >
        <div
          className={`*:w-full rounded-lg p-3 text-center font-bold ${active ? "bg-ui-7 text-white" : "bg-[#e1f5fe] text-ui-6"}`}
        >
          <div className="flex w-full p-2">
            {!size ? <div className="invisible m-1 w-full rounded-sm border-4" /> : ""}
            {[...Array(size).keys()].map((index) => (
              <div
                className="m-1 w-full rounded-sm border-4 border-indigo-300"
                key={`question-custom-size-${question_key}-${index}`}
              />
            ))}
          </div>
          {size ? `1/${size} width` : "Auto"}
        </div>
      </div>
    );
  };

  const renderDataPointModal = () => {
    return (
      <div className={`sg-mgmt-helper-icon h-[18px] w-[18px]`}>
        <img
          src={"/images/icons/small-add-filled-gray.svg"}
          className="sg-mgmt-helper-icon-image-normal sg-mgmt-helper-icon-image"
          onClick={openMetaDataModal}
        />
        <img
          src={"/images/icons/small-add-filled-blue.svg"}
          className="sg-mgmt-helper-icon-image-hovered sg-mgmt-helper-icon-image"
          onClick={openMetaDataModal}
        />
      </div>
    );
  };

  const renderParentValue = (option, index) => {
    if (option.attribute_id == "participant.ticket_type") {
      return renderSelectFieldFilterable(
        "Value",
        `${question_key}[parent_child_options][fields][${index}][value]`,
        getTicketFields(ticketTypes["ticket_types"])
      );
    }
    if (option.attribute_id == "participant.package_type") {
      return renderSelectFieldFilterable(
        "Value",
        `${question_key}[parent_child_options][fields][${index}][value]`,
        getPackageFields(packageTypes["package_types"])
      );
    }
    return renderTextField("Value", `${question_key}[parent_child_options][fields][${index}][value]`);
  };

  const renderParentChildOptions = () => {
    const options = [
      { label: "Show if ANY parent field is selected", value: "any" },
      { label: "Show if ALL parent fields are selected", value: "all" },
      { label: "Show if NONE of parent fields are selected", value: "notany" },
      { label: "Show if ANY parent field are NOT selected", value: "notall" }
    ];

    return (
      <div>
        <div>
          <b className="text-sg-orange"> Parent/Child Options</b>
        </div>

        <div className="flex">
          {renderRadioField(`${question_key}[parent_child_options][field_requirement]`, options, false)}
        </div>
        {(question.parent_child_options.fields || []).map((option, index) => (
          <div key={`parent-child-option-${question_key}-${index}`}>
            <div className="flex">
              <div className="mr-2 w-1/2">
                {form.form_type == "registration" ? (
                  <>
                    {renderSelectFieldFilterable(
                      "Parent Field",
                      `${question_key}[parent_child_options][fields][${index}][attribute_id]`,
                      getParticipantFields({ metadata: metaData, isParentField: true }).map((x) => {
                        return {
                          value: x.key,
                          label: x.label
                        };
                      })
                    )}
                  </>
                ) : (
                  renderTextField(
                    "Parent Field",
                    `${question_key}[parent_child_options][fields][${index}][attribute_id]`,
                    [],
                    false,
                    true
                  )
                )}
              </div>
              <div className="w-1/2">{renderParentValue(option, index)}</div>
              <button
                style={{ padding: "10px" }}
                type="button"
                onClick={() => {
                  removeParentChildOption(index);
                }}
              >
                {" "}
                ✖{" "}
              </button>
            </div>
          </div>
        ))}
        <div>
          <Link type="button" className="no-underline" component="button" onClick={addParentChildOption}>
            + Add Parent Field
          </Link>
        </div>
      </div>
    );
  };

  const addParentChildOption = () => {
    formikProps.setFieldValue(
      `${question_key}[parent_child_options][fields]`,
      (question.parent_child_options.fields || []).concat([defaultOption()])
    );
  };

  const removeParentChildOption = (index) => {
    formikProps.setFieldValue(
      `${question_key}[parent_child_options][fields]`,
      (question.parent_child_options.fields || []).filter((x, i) => i != index)
    );
  };

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragOptionsEnd = (event) => {
    const { active, over } = event;
    console.log({ active, over });
    if (active.id !== over.id) {
      const items = question.options;
      const oldIndex = items.map((x) => x.id).indexOf(active.id);
      const newIndex = items.map((x) => x.id).indexOf(over.id);
      const newArray = arrayMove(items, oldIndex, newIndex);
      console.log({ items, newArray, oldIndex, newIndex });
      formikProps.setFieldValue(`${question_key}[options]`, newArray);
    }
  };

  const renderQuestionOptions = () => {
    //TODO Drag and drop AGAIN
    //TODO Auto pull in options from metadata
    if (!question.options || question.options.length == 0) {
      addOption();
    }
    return (
      <div className="pb-4">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragOptionsEnd}>
          <SortableContext items={question.options || []} strategy={verticalListSortingStrategy}>
            {(question.options || []).map((option, index) => (
              <FormsFormPageQuestionOption
                key={`question-option-${question_key}-${index}`}
                question={question}
                option={option}
                index={index}
                question_key={question_key}
                removeOption={removeOption}
                formikProps={formikProps}
              />
            ))}
          </SortableContext>
        </DndContext>

        <Stack direction="row" spacing={2}>
          <Link type="button" component="button" className="no-underline" onClick={addOption}>
            + Add Option
          </Link>
          <Link type="button" component="button" className="no-underline" onClick={toggleOptionImportModal}>
            Import Options
          </Link>
          <Link type="button" className="text-sg-orange no-underline" component="button" onClick={removeAllOptions}>
            Remove All Options
          </Link>
        </Stack>
        <FormsFormPageQuestionImportOptions
          modalVisible={optionImportModalOpen}
          resetModal={toggleOptionImportModal}
          update={importOptions}
        />
      </div>
    );
  };

  const importOptions = (options) => {
    formikProps.setFieldValue(
      `${question_key}[options]`,
      (question.options || []).concat(
        options.map((x) => {
          return { value: x, label: x };
        })
      )
    );
    setOptionImportModalOpen(!optionImportModalOpen);
  };

  const toggleOptionImportModal = () => {
    setOptionImportModalOpen(!optionImportModalOpen);
  };
  const removeOption = (index) => {
    formikProps.setFieldValue(
      `${question_key}[options]`,
      (question.options || []).filter((x, i) => i != index)
    );
  };

  const addOption = () => {
    formikProps.setFieldValue(`${question_key}[options]`, (question.options || []).concat([defaultOption()]));
  };

  const removeAllOptions = () => {
    if (confirm("Are you sure? Once saved, this cannot be undone.")) {
      formikProps.setFieldValue(`${question_key}[options]`, []);
    }
  };

  const defaultOption = () => {
    return {
      id: uuidv4(),
      value: "",
      label: ""
    };
  };

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  }

  const getQuestionType = () => {
    return getQuestionTypes().find((x) => x.id == question.question_type) || {};
  };

  const archiveQuestion = () => {
    if (!confirm("Are you sure you wish to remove this question? Once saved, this cannot be undone.")) {
      return;
    }
    formikProps.setFieldValue(`${question_key}[archived]`, true);
  };

  const renderForm = () => (
    <div onClick={() => setOpen(true)} className={`sg-mgmt-form-forms-question-container ${open ? "open" : "closed"}`}>
      <div className="sg-mgmt-form-section flex">
        <div className="sg-mgmt-forms-question-drag-icon-container flex">
          <img src="/images/forms/drag_dots.svg" className="sg-mgmt-forms-question-drag-icon" {...listeners} />
        </div>
        <div className="flex w-full">
          <div className="sg-mgmt-forms-question-label w-2/3">
            {renderTextField("", `${question_key}[name]`, [], false, true, {
              placeholder: "Enter Field Title (For Reference Only)",
              disableLabel: true,
              fieldProperties: {
                sx: {
                  "& fieldset": { border: open ? "" : "none" }
                }
              }
            })}
          </div>
          <div className="w-1/3">
            <div className="sg-mgmt-forms-form-label-buttons">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(!open);
                }}
                className="sg-mgmt-forms-link-button"
                type="button"
              >
                {open ? "MINIMIZE" : "EXPAND"}
              </button>
              |
              <button
                onClick={archiveQuestion}
                className="sg-mgmt-forms-link-button sg-mgmt-forms-link-button-negative"
                type="button"
              >
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="sg-mgmt-form-section" style={{ paddingLeft: "34px" }}>
        <div className="flex w-full">{open ? renderOptions() : ""}</div>
      </div>
    </div>
  );

  return (
    <li ref={setNodeRef} style={style} {...attributes} className="sg-mgmt-forms-form-question-sortable-container">
      <div className="sg-mgmt-form-forms-question-divider"></div>
      {renderForm()}
    </li>
  );
};

export default FormsFormPageQuestionForm;
