import React from "react";

import TabbedPage from "@shared/TabbedPage";

import EventDetailsEventInfo from "./EventDetailsEventInfo";
import EventDetailsIntegrations from "./integrations/EventDetailsIntegrations";
import Metadata from "./metadata/Metadata";
import EventDetailsRegistration from "./registration/EventDetailsRegistration";
import EventDetailsTokens from "./tokens/EventDetailsTokens";

const EventDetails = () => {
  return (
    <TabbedPage
      tabs={[
        { label: "Event Info", component: EventDetailsEventInfo, componentProps: {} },
        { label: "Registration", component: EventDetailsRegistration, componentProps: {} },
        { label: "Integrations", component: EventDetailsIntegrations, componentProps: {} },
        { label: "API Management", component: EventDetailsTokens, componentProps: {} },
        { label: "Custom Fields", component: Metadata, componentProps: {} }
      ]}
    />
  );
};

export default EventDetails;
