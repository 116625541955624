import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

const PageHeader = (props) => {
  const { subHeader = false, text } = props;

  return (
    <div className={clsx("text-sg-orange", "font-normal", subHeader ? "text-xl" : "text-2xl")}>{text}</div>
  );
};

PageHeader.propTypes = {
  text: PropTypes.string.isRequired
};

export default PageHeader;
