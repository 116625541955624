import React from "react";

import TabbedPage from "@shared/TabbedPage";

import EventAnalyticsCharts from "./charts/EventAnalyticsCharts";
import EventAnalyticsReports from "./reports/EventAnalyticsReports";

const EventAnalytics = () => {

  return (
    <TabbedPage
      tabs={[
        { label: "Charts", component: EventAnalyticsCharts },
        { label: "Reports", component: EventAnalyticsReports },
      ]}
    />
  );
};

export default EventAnalytics;
