import React from "react";

import CurrencyTextField from '@lupus-ai/mui-currency-textfield';
import { Field } from "formik";

const CurrencyField = ({ fieldName, symbol = "$" }) => {
  return (
    <Field name={fieldName}>
      {({ field }) => (
        <CurrencyTextField
          {...field}
          currencySymbol={symbol}
          minimumValue="0.00"
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          variant="outlined"
          size="small"
        />
      )}
    </Field>
  );
};

export default CurrencyField;
