import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import EventDetailsRegistrationFieldModal from "./EventDetailsRegistrationFieldModal";
import EventDetailsBlankField from "./EventDetailsBlankField";
import EventDetailsRegistrationPreview from "./EventDetailsRegistrationPreview";

const EventDetailsRegistration = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [fields, setFields] = useState({
    fetched: false,
    data: []
  });
  const [editSection, setEditSection] = useState({
    editing: false,
    section: 0
  });

  const sortFields = fieldsToSort =>
    sortBy(fieldsToSort, ["section", "sort_order"]);

  const fetchFields = async () => {
    try {
      const result = await axios(urljoin(apiRoot, "/details/registration"));
      setFields({
        fetched: true,
        data: sortFields(result.data)
      });
    } catch (error) {
      alertHttpError(error);
    }
  };

  useEffect(() => {
    fetchFields();
  }, []);

  const addField = field => {
    setFields({
      fetched: fields.fetched,
      data: sortFields(fields.data.concat(field))
    });
  };

  const deleteField = fieldId => {
    setFields({
      fetched: fields.fetched,
      data: sortFields(fields.data.filter(f => f.id !== fieldId))
    });
  };

  const updateField = field => {
    setFields({
      fetched: fields.fetched,
      data: fields.data.map(f => (f.id === field.id ? field : f))
    });
  };

  const removeField = fieldId => {
    const postData = { field_id: fieldId };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/details/registration", `/${fieldId}`),
      method: "DELETE",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        deleteField(response.data.field_id);
        alertSuccess("Successfully deleted registration field");
      } else {
        alertError("Failed deleting registration field");
      }
    });
  };

  const modalAddOpen = () => {
    setModalAddVisible(true);
  };

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const renderBody = () => {
    if (fields.fetched) {
      return (
        <EventDetailsRegistrationPreview
          fields={fields.data}
          addField={addField}
          removeField={removeField}
          updateField={updateField}
        />
      );
    }
    return <Loading />;
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <h1>Registration Form Overview</h1>
      {renderBody()}
      <EventDetailsRegistrationFieldModal
        field={EventDetailsBlankField}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Registration Field"
        updateFunc={addField}
      />
    </div>
  );
};

export default EventDetailsRegistration;
