import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import sortBy from "lodash/sortBy";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import EventMeetings1On1HostsAdd from "./EventMeetings1On1HostsAdd";

const EventMeetings1On1Hosts = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [fetchedHosts, setFetchedHosts] = useState(false);
  const [hosts, setHosts] = useState([]);
  const confirm = useConfirm();

  useEffect(() => {
    const fetchHosts = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "meeting_hosts"));
        setHosts(result.data.hosts);
        setFetchedHosts(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHosts();
  }, []);

  const addHost = host => {
    setHosts([host].concat(hosts));
  };

  const updateHost = host => {
    setHosts(hosts.map(h => (h.id === host.id ? host : h)));
  };

  const removeHost = id => {
    setHosts(hosts.filter(h => h.id !== id));
  };

  const setVirtual = (hostId, checked) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/meeting_hosts/", `${hostId}`, "/set_virtual"),
      method: "PATCH",
      data: { virtual: checked }
    })
      .then(response => {
        if (response.data.error === null) {
          updateHost(response.data.host);
        } else {
          alertError("failed to update host");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const deleteHost = hostId => {
    const postData = {
      meeting_host_id: hostId
    };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/meeting_hosts/", `${hostId}`),
      method: "DELETE",
      data: postData
    })
      .then(response => {
        if (response.data.error === null) {
          removeHost(response.data.host_id);
        } else {
          alertError("failed to remove host");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const confirmDelete = hostId => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to remove this host? This will also remove any meetings and meeting requests that include this host!"
    })
      .then(() => {
        deleteHost(hostId);
      })
      .catch(err => {
        alertError(err);
      });
  };

  const renderVirtualCheckbox = host => {
    const checked = host.data["virtual"] || false;
    return (
      <label className="sg-mgmt-checkbox-container">
        <input
          checked={checked}
          onChange={e => setVirtual(host.id, e.target.checked)}
          type="checkbox"
          value="true"
        />
        <span className="sg-mgmt-checkbox" />
      </label>
    );
  };

  const renderHostTable = () => {
    if (fetchedHosts) {
      return (
        <table className="sg-mgmt-1on1-table-hosts table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Meeting Count</th>
              <th>Request Count</th>
              <th>Virtual Only</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortBy(hosts, ["name_last", "name_first"]).map(host => (
              <tr key={host.id}>
                <td>{`${host.name_first} ${host.name_last}`}</td>
                <td>{host.company}</td>
                <td>{host.meeting_host_attendances.length}</td>
                <td>{host.meeting_request_host_attendances.length}</td>
                <td>{renderVirtualCheckbox(host)}</td>
                <td>
                  <span
                    className="sg-mgmt-link"
                    onClick={() => {
                      confirmDelete(host.id);
                    }}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return <Loading />;
  };

  const renderBody = () => {
    if (fetchedHosts) {
      return (
        <div>
          <EventMeetings1On1HostsAdd update={addHost} hosts={hosts} />
          <div>{renderHostTable()}</div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <PageHeader text="Manage Hosts" />
      <div className="sg-mgmt-event-meetings-wrapper">{renderBody()}</div>
    </div>
  );
};

export default EventMeetings1On1Hosts;
