import React, { useState } from "react";

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import compact from "lodash/compact";
import sum from "lodash/sum";

import Bubble from "@shared/Bubble";

import HousingOverviewRoomType from "./HousingOverviewRoomType";

const HousingOverviewHotel = (props) => {
  const { config, hotel, index = 0 } = props;
  const [viewTypes, setViewTypes] = useState(index == 0);

  const renderVisibilityArrow = () => {
    if (viewTypes) {
      return (
        <span key={Math.random()}>
          <FontAwesomeIcon icon={faCaretDown} />
          &nbsp;
        </span>
      );
    }
    return (
      <span key={Math.random()}>
        <FontAwesomeIcon icon={faCaretRight} />
        &nbsp;
      </span>
    );
  };

  const toggleTypes = () => {
    setViewTypes(!viewTypes);
  };

  const totalInventory = () => {
    return sum(compact(hotel.room_types.map((rt) => sum(rt.room_type_dates.map((date) => date.unit_count)))));
  };

  const totalBookings = () => {
    return sum(
      compact(
        hotel.room_types.map((rt) =>
          sum(
            rt.room_block_room_types.map((rbrt) =>
              sum(rbrt.room_block_room_type_dates.map((date) => date.occupancy_count))
            )
          )
        )
      )
    );
  };

  const renderRoomTypes = () => {
    if (viewTypes) {
      if (hotel.room_types.length === 0) {
        return (
          <div>
            <em>No room types have been created for this hotel yet.</em>
          </div>
        );
      }
      return hotel.room_types.map((rt) => <HousingOverviewRoomType key={rt.gid} config={config} roomType={rt} />);
    }
    return <></>;
  };

  const renderRoomTypesToggle = () => (
    <div className="cursor-pointer" onClick={toggleTypes}>
      <div>
        {renderVisibilityArrow()}
        <span className="text-sg-orange">Room Inventory</span>
      </div>
    </div>
  );

  const renderHotelImage = () => {
    const hotelImage = hotel.image_url ? hotel.image_url : "/images/housing/hotel-placeholder-icon.png";
    return (
      <div className="mr-2">
        <div className="sg-mgmt-hotel-image">
          <img src={hotelImage} alt="hotel" />
        </div>
      </div>
    );
  };

  const renderHotelTitle = () => <div className="text-lg text-sg-orange">{hotel.name}</div>;

  const renderHotelAddress = () => (
    <div>
      <div>
        <div>{hotel.address_street_1}</div>
        <div>{hotel.address_street_2}</div>
        <div>
          {compact([hotel.address_city, hotel.address_state, hotel.address_country]).filter(Boolean).join(", ")}
        </div>
        <div className="pt-2">{hotel.phone}</div>
      </div>
    </div>
  );

  const renderHotelBubbles = () => (
    <div className="flex justify-end" style={{ height: "150px" }}>
      <Bubble
        colorBack=""
        label="Total Inventory"
        marginLeft={0}
        marginRight={8}
        size={135}
        twClasses="bg-ui-5"
        value={totalInventory()}
      />
      <Bubble
        colorBack=""
        label="Total Booked"
        marginLeft={0}
        marginRight={8}
        twClasses="bg-sg-yellow"
        size={135}
        value={totalBookings()}
      />
    </div>
  );

  const renderHotelDetails = () => (
    <div className="mb-2 flex w-full justify-start">
      <div className="flex-none">{renderHotelImage()}</div>
      <div className="flex-none">
        {renderHotelTitle()}
        {renderHotelAddress()}
      </div>
      <div className="mr-4 w-full grow">{renderHotelBubbles()}</div>
    </div>
  );

  const renderHotel = () => {
    return (
      <div className="sg-mgmt-content min-h-0 bg-white py-2">
        {renderHotelDetails()}
        {renderRoomTypesToggle()}
        <div>{renderRoomTypes()}</div>
      </div>
    );
  };

  return <div>{renderHotel()}</div>;
};

export default HousingOverviewHotel;
