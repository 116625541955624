import React, { useContext, useState } from "react";

import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import GrowlTableStatic from "@shared/GrowlTableStatic";
import { useAddHousingBlockRoomType } from "@shared/hooks/useHousingBlocks";
import { useHousingHotels } from "@shared/hooks/useHousingHotels";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import HousingBlankRoomBlockRoomType from "./HousingBlankRoomBlockRoomType";
import HousingRoomBlockRoomTypeInventoryModal from "./HousingRoomBlockRoomTypeInventoryModal";

const HousingRoomBlockInventory = (props) => {
  const { config, goIndex, block, blocks } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [manageRoomBlockRoomType, setManageRoomBlockRoomType] = useState(HousingBlankRoomBlockRoomType);
  const [modalInventoryVisible, setModalInventoryVisible] = useState(false);
  const hotelsQuery = useHousingHotels(apiRoot, event.id);
  const addHousingBlockRoomType = useAddHousingBlockRoomType(apiRoot, event.id);

  const modalInventoryReset = () => {
    setModalInventoryVisible(false);
  };

  const generateStaticRowData = (rbrt) => {
    return {
      key: rbrt.id,
      cells: [
        <><span className="font-bold">{rbrt.room_type.hotel_name}</span></>,
        <><span className="font-bold">{rbrt.room_type.name}</span></>,
        <>{rbrt.room_type.description}</>,
        <>
          <div className="text-right text-[13px] font-medium leading-none">
            <span
              className="inline-block cursor-pointer border-r border-r-ui-7 pr-2 uppercase text-ui-7"
              onClick={() => {
                setManageRoomBlockRoomType(rbrt);
                setModalInventoryVisible(true);
              }}
            >
              Manage Inventory
            </span>
            <span
              className="inline-block cursor-pointer pl-2 uppercase text-ui-7"
              onClick={() => {
                alert("Implement me!")
              }}
            >
              Delete
            </span>
          </div>
        </>
      ]
    }
  }

  const renderHotelTable = (roomBlockRoomTypes) => {
    const columns = ["Hotel", "Room Type", "Description", "Actions"];
    const items = roomBlockRoomTypes.map(rbrt => generateStaticRowData(rbrt));

    return (
      <div className="mb-2" key={`hotel-table-${roomBlockRoomTypes[0].room_type.hotel_id}`}>
        <div className="mb-2">
          <h2>{roomBlockRoomTypes[0].room_type.hotel_name}</h2>
        </div>
        <GrowlTableStatic
          columns={columns}
          items={items}
        />
      </div>
    );
  };

  const renderRoomTypes = () => {
    const sortedRoomBlockRoomTypes = sortBy(block.room_block_room_types, ["room_type.hotel_id", "room_type.name"]);
    const hotelIds = [...new Set(block.room_block_room_types.map((e) => e.room_type).map((rt) => rt.hotel_id))];

    return (
      <>
        {hotelIds.map((hid) =>
          renderHotelTable(sortedRoomBlockRoomTypes.filter((rbrt) => rbrt.room_type.hotel_id === hid))
        )}
      </>
    );
  };

  const renderAddButton = () => {
    return renderSubmitButton("Add Room Type", false);
  };

  const renderBackButton = () => {
    return renderCancelButton("Back", goIndex);
  };

  const renderRoomTypeSelect = () => {
    const selectedRoomTypeGids = block.room_block_room_types.map((e) => e.room_type).map((rt) => rt.gid);
    const options = hotelsQuery.data.hotels
      .map((h) =>
        h.room_types
          .filter((rt) => !selectedRoomTypeGids.includes(rt.gid))
          .map((rt) => ({
            label: `${h.name}: ${rt.name}`,
            value: rt.id,
            hotel: h.name
          }))
      )
      .flat();
    return (
      <Formik
        initialValues={{
          room_type_id: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          addHousingBlockRoomType.mutate(
            {
              data: values
            },
            {
              onSuccess: (data) => {
                if (data.error === null) {
                  setSubmitting(false);
                } else {
                  alertError(data.error);
                  setSubmitting(false);
                }
              },
              onError: (error) => {
                alertHttpError(error);
              }
            }
          );
        }}
      >
        {({ values, isSubmitting }) => (
          <div className="my-4">
            <Form className="sg-mgmt-form" id="sg-mgmt-block-room-type-form">
              <div className="flex">
                <div className="mr-2 flex-auto">
                  <SelectField name="room_type_id" options={options} groupBy={(option) => option.hotel} />
                </div>
                <div className="flex-none">{renderAddButton(values.room_type_id, isSubmitting)}</div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    );
  };

  if (hotelsQuery.isLoading) {
    return <Loading />;
  }

  if (hotelsQuery.isError) {
    return <div>Error loading hotels: {hotelsQuery.error}</div>;
  }

  return (
    <div>
      <div className="my-2">
        <PageHeader text={block.name} subHeader />
      </div>
      {renderRoomTypes()}
      {renderRoomTypeSelect()}
      <HousingRoomBlockRoomTypeInventoryModal
        blocks={blocks}
        config={config}
        block={block}
        modalVisible={modalInventoryVisible}
        resetModal={modalInventoryReset}
        roomBlockRoomType={manageRoomBlockRoomType}
        title="Set Inventory"
      />
      {renderBackButton()}
    </div>
  );
};

export default HousingRoomBlockInventory;
