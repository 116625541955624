import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import Loading from "@shared/Loading";
import { renderButton } from "@shared/FormUtils";
import EventContext from "@event/EventContext";
import EventSessionSpeakersTable from "./EventSessionSpeakersTable";
import EventSessionTitle from "../EventSessionTitle";

const EventSessionSpeakers = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { update } = props;
  const [participants, setParticipants] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [mode, setMode] = useState("assigned");

  useEffect(() => {
    const fetchParticipants = async () => {
      const result = await axios(urljoin(apiRoot, "/participants/speakers"));
      setParticipants(result.data);
      setFetched(true);
    };

    fetchParticipants();
  }, [apiRoot]);

  const renderButtons = () => {
    return (
      <div className="sg-mgmt-session-attend-btn-container">
        {renderButton("Assigned Speakers", () => { setMode("assigned") })}
        {renderButton("Available Speakers", () => { setMode("available")})}
      </div>
    );
  };

  const renderSpeakers = () => {
    if (fetched) {
      return (
        <EventSessionSpeakersTable
          items={participants}
          mode={mode}
          update={update}
        />
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <EventSessionTitle />
      <h2>Speaker Assignment</h2>
      {renderButtons()}
      {renderSpeakers()}
    </div>
  );
};

EventSessionSpeakers.propTypes = {
  update: PropTypes.func.isRequired
};

export default EventSessionSpeakers;
