import React, { useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";

const MainMenu = () => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);

  const menuHide = () => {
    setVisible(false);
  };

  const menuShow = () => {
    setVisible(true);
  };

  const handleClick = evt => {
    if (wrapperRef.current && wrapperRef.current.contains(evt.target)) {
      if (visible) {
        menuHide();
      } else {
        menuShow();
      }
    } else if (wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
      menuHide();
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClick);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const menuItems = () => (
    <div className="sg-mgmt-dash-menu-items">
      <Link to="/events">
        <div className="sg-mgmt-dash-menu-item">Events</div>
      </Link>
      <Link to="/people">
        <div className="sg-mgmt-dash-menu-item">People</div>
      </Link>
    </div>
  );

  const renderItems = () => {
    if (visible) {
      return menuItems();
    }
    return <></>;
  };

  return (
    <div className="sg-mgmt-dash-menu-wrapper" ref={wrapperRef}>
      <i className="fas fa-bars sg-mgmt-dash-menu-icon" />
      {renderItems()}
    </div>
  );
};

export default MainMenu;
