import React from "react";
import PropTypes from "prop-types";
import { alertError } from "@shared/Alerts";
import EventSessionFormDetails from "./form/EventSessionFormDetails";
import EventSessionTitle from "./EventSessionTitle";

const EventSessionDetails = props => {
  const { setTab, update } = props;
  const GI_TAB_IDX = 0;

  const success = response => {
    update(response.data.session);
    setTab(GI_TAB_IDX);
  };

  const failure = response => {
    alertError("Failed to update session details");
  };

  const cancel = () => {
    setTab(GI_TAB_IDX);
  };

  return (
    <div>
      <EventSessionTitle />
      <EventSessionFormDetails
        callbackFailure={failure}
        callbackSuccess={success}
        cancel={cancel}
      />
    </div>
  );
};

EventSessionDetails.propTypes = {
  setTab: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default EventSessionDetails;
