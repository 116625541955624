import React, { useContext } from "react";
import EventSessionContext from "./EventSessionContext";

const EventSessionTitle = () => {
  const { session } = useContext(EventSessionContext);
  const renderSessionTitle = () => (
    <div>
      <div className="sg-mgmt-info-label">Session Title:</div>
      <span className="sg-mgmt-session-title">{session.title}</span>
    </div>
  );

  return <div>{renderSessionTitle()}</div>;
};

export default EventSessionTitle;
