import React from "react";

import TicketRequestWidget from "@dashboard/widgets/ticket_request/TicketRequestWidget";
import PageHeader from "@shared/PageHeader";

const TicketRequests = () => {
  return (
    <>
      <div>
        <PageHeader text="Ticket Requests" />
        <TicketRequestWidget fullPage={true} />
        <br />
      </div>
    </>
  );
};

export default TicketRequests;
