import React, { useContext, useEffect } from "react";

import { Stack } from "@mui/material";
import { Formik, Form } from "formik";

import { useDropzoneField } from "@/shared/hooks/useDropzoneField";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderTextField, renderCancelButton, renderCheckField, renderSubmitButton } from "@shared/FormUtils";
import { useAddHousingHotelRoomType, useUpdateHousingHotelRoomType } from "@shared/hooks/useHousingHotels";

import HousingBlankRoomType from "./HousingBlankRoomType";

const HousingHotelRoomTypeForm = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    callbackFailure = () => { },
    callbackSuccess = () => { },
    cancelButton = () => { },
    hotel,
    roomType
  } = props;
  const addHousingHotelRoomType = useAddHousingHotelRoomType(apiRoot, event.id);
  const updateHousingHotelRoomType = useUpdateHousingHotelRoomType(apiRoot, event.id);
  const [image, setPreviewImageURL, renderImagePreview, renderImageUpload] = useDropzoneField();

  useEffect(() => {
    setPreviewImageURL(roomType.image_url);
  }, [setPreviewImageURL, roomType]);

  const isEdit = () => {
    return roomType && roomType.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        formId: "sg-mgmt-form-room-type-edit",
        saveButton: "Update",
      };
    }
    return {
      formId: "sg-mgmt-form-room-type-add",
      saveButton: "Create",
    };
  })();

  const initialAmenitiesValues = () => {
    const values = {};
    roomType.amenities.forEach((amenity) => {
      values[amenity.id] = true;
    });
    return values;
  };

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: roomType.name || "",
        code: roomType.code || "",
        description: roomType.description || "",
        amenities: initialAmenitiesValues()
      };
    }
    return HousingBlankRoomType;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          room_type: formInitialValues()
        }}
        onSubmit={(_values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);

          if (image) {
            formData.set("room_type[image]", image);
          }

          if (isEdit()) {
            updateHousingHotelRoomType.mutate(
              {
                hotelId: hotel.id,
                roomTypeId: roomType.id,
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                  } else {
                    callbackFailure(data.error);
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertHttpError(error);
                }
              }
            );
          } else {
            addHousingHotelRoomType.mutate(
              {
                hotelId: hotel.id,
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                  } else {
                    callbackFailure(data.error);
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertHttpError(error);
                }
              }
            );
          }


        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Room Type Name", "room_type[name]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Code", "room_type[code]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Description", "room_type[description]")}
              </div>
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-input-container">
                  <label>Choose room type image</label>
                  {renderImageUpload()}
                  {renderImagePreview()}
                </div>
              </div>
              <div className="sg-mgmt-form-row">
                <h2>Amenities</h2>
              </div>
              <div className="mb-4">
                {hotel.amenities.map((amenity) => (
                  <div key={amenity.id}>
                    {renderCheckField(
                      amenity.name,
                      `room_type[amenities][${amenity.id}]`,
                      "mb-0"
                    )}
                  </div>
                ))}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default HousingHotelRoomTypeForm;
