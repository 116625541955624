import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useHousingBlocks, useHousingHotels } from "@shared/hooks/useHousing";
import { useHousingBookings } from "@shared/hooks/useHousingBookings";
import Loading from "@shared/Loading";

import HousingOverviewHotel from "./HousingOverviewHotel";

const HousingOverview = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const bookingsQuery = useHousingBookings(apiRoot, event.id);
  const blocksQuery = useHousingBlocks(apiRoot, event.id);
  const hotelsQuery = useHousingHotels(apiRoot, event.id);

  const renderHotels = () => {
    if (hotelsQuery.data.hotels.length > 0) {
      return hotelsQuery.data.hotels.map((hotel, idx) => (
        <HousingOverviewHotel
          key={hotel.gid}
          config={hotelsQuery.data.configuration}
          hotel={hotel}
          index={idx}
        />
      ));
    }
    return (
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <h1>Housing</h1>
        <p>To begin using the Housing module:</p>
        <ol className="ml-2 mt-1">
          <li>1. Configure dates and other settings in the SETTINGS tab</li>
          <li>2. Create and manage hotels in the HOTELS tab</li>
        </ol>
      </div>
    );
  };

  const queryError = (label, error) => (
    <div>
      {`An error occurred loading ${label}: ${error}`}
    </div>
  );

  if (hotelsQuery.isLoading) {
    return <Loading />;
  }

  if (bookingsQuery.isError) { return queryError("bookings", bookingsQuery.error); }
  if (blocksQuery.isError) { return queryError("blocks", blocksQuery.error); }
  if (hotelsQuery.isError) { return queryError("hotels", hotelsQuery.error); }

  return (
    <div>
      {renderHotels()}
    </div>
  );
};

export default HousingOverview;
