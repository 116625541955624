import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchParticipantsTable = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/participants/table")
  });
};

export const useParticipantsTable = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["participantsTable", eventId],
    queryFn: () => fetchParticipantsTable(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};
