import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { alertError } from "@shared/Alerts";
import { useHousingHotel } from "@shared/hooks/useHousingHotels";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import HousingHotelAmenities from "./HousingHotelAmenities";
import HousingHotelForm from "./HousingHotelForm";

const HousingHotel = (props) => {
  const { editHotelId, goIndex, setEditHotelId } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const hotelQuery = useHousingHotel(apiRoot, event.id, editHotelId);

  const success = () => {
    goIndex();
    setEditHotelId(null);
  };

  const failure = () => {
    alertError("Failed to update hotel");
  };

  if (hotelQuery.isLoading) {
    return <Loading />;
  }

  if (hotelQuery.isError) {
    return <div>Error loading hotel: {hotelQuery.error}</div>;
  }

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <div className="my-4">
        <div className="mb-2">
          <PageHeader text="Hotel Details" subHeader />
        </div>
        <HousingHotelForm
          callbackFailure={failure}
          callbackSuccess={success}
          cancelButton={goIndex}
          hotel={hotelQuery.data.hotel}
        />
      </div>
      <div className="my-4">
        <div className="mb-2">
          <PageHeader text="Amenities" subHeader />
        </div>
        <HousingHotelAmenities hotel={hotelQuery.data.hotel} />
      </div>
    </div>
  );
};

export default HousingHotel;
