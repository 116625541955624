import React, { useState } from "react";

import HousingHotelAmenitiesForm from "./HousingHotelAmenitiesForm";

const HousingHotelAmenity = (props) => {
  const { amenity, hotel } = props;
  const [edit, setEdit] = useState(false);

  const deleteAmenity = () => {
    alert("delete");
  };

  const stopEdit = () => {
    setEdit(false);
  };

  if (edit) {
    return (
      <div className="mb-4">
        <HousingHotelAmenitiesForm amenity={amenity} callbackSuccess={stopEdit} cancelButton={stopEdit} hotel={hotel} />
      </div>
    );
  }

  return (
    <div>
      - {amenity.name} (
      <span className="sg-mgmt-link cursor-pointer" onClick={() => setEdit(true)}>
        edit
      </span>{" "}
      |{" "}
      <span className="sg-mgmt-link cursor-pointer" onClick={deleteAmenity}>
        delete
      </span>
      )
    </div>
  );
};

export default HousingHotelAmenity;
