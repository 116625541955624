import React, { useContext, useEffect, useState } from "react";
import ContentFrame from "@shared/ContentFrame";
import TemplateForm from "./TemplateForm";
import { useHistory, useParams } from "react-router-dom";

const TemplateEdit = (props) => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <ContentFrame>
      <TemplateForm 
        webPageTemplateId={id != "new" ? id : null}
        cancel={() => history.push(`/content/web_pages`)}
      />
    </ContentFrame>
  );
}

export default TemplateEdit