import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { useHistory } from "react-router-dom";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTimeUtcToZone } from "@shared/TimeUtils";

import TicketingTicketTypeFormModal from "./TicketingTicketTypeFormModal";
import { ticketTotalCount, ticketTotalAvailable } from "./TicketingUtils";

const TicketingTickets = () => {
  const { user } = useContext(EventUserContext);
  const confirm = useConfirm();

  const [ticketTypes, setTicketTypes] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [ticketTypeModalOpen, setTicketTypeModalOpen] = useState(false);
  const [ticketTypeModalTarget, setTicketTypeModalTarget] = useState(null);
  const { apiRoot, event } = useContext(EventContext).values;
  const history = useHistory();

  const columns = [
    {
      headerName: "Ticket Name",
      field: "name",
      flex: 1
    },
    {
      headerName: "Description",
      field: "notes",
      flex: 1
    },
    {
      headerName: "Total Count",
      field: "total_count",
      renderCell: (params) => ticketTotalCount(params.row),
      flex: 1
    },
    {
      headerName: "Assigned",
      field: "total_assigned",
      flex: 1
    },
    {
      headerName: "Available",
      field: "total_available",
      renderCell: (params) => ticketTotalAvailable(params.row, params.row.total_assigned),
      flex: 1
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      renderCell: (params) => formatTimeUtcToZone(params.value, event.time_zone),
      flex: 1,
      minWidth: 200
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      align: "right",
      minWidth: 320,
      getActions: (params) => [
        renderViewAction(params.row),
        renderEditAction(params.row),
        renderDeleteAction(params.row)
      ]
    }
  ];

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "ticketing/ticket_types"));
        setTicketTypes(result.data["ticket_types"]);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchForms();
  }, [apiRoot]);

  const deleteTicketType = (ticketType) => {
    setTicketTypes(ticketTypes.filter((f) => f.id != ticketType.id));
  };

  const renderViewAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            viewTicket(id);
          }}
        >
          View Assignments
        </span>
      </>
    );
  };

  const renderEditAction = (id) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            openEditTicketTypeModal(id);
          }}
        >
          Edit Ticket
        </span>
      </>
    );
  };

  const renderDeleteAction = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm delete",
              description: "Are you sure you want to delete this ticket?"
            })
              .then(() => {
                performDelete(item);
              })
              .catch((err) => {
                if (err) alertError(err);
              });
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const performDelete = (ticket) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/ticketing/ticket_types/", `${ticket.id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteTicketType(ticket);
          alertSuccess("Ticket type deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderTicketsTable = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <GrowlTable
        columns={columns}
        items={ticketTypes}
        sortField="name"
        sortDirection="asc"
        tableName={`${event.slug}-tickets`}
      />
    );
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.ticketing_edit) {
      return false;
    }
    return true;
  };

  const renderNewTicketButton = () => {
    if (!editEnabled()) {
      return <></>;
    }

    return renderCreateButton("Create New Ticket Type", openNewTicketTypeModal);
  };

  const renderTicketTypeModal = () => {
    return (
      <TicketingTicketTypeFormModal
        ticketTypeId={ticketTypeModalTarget}
        modalVisible={ticketTypeModalOpen}
        resetModal={closeTicketTypeModal}
        callbackFailure={() => {}}
        callbackSuccess={(response) => {
          updateTicketTypes(response.data.ticket_type);
          closeTicketTypeModal();
        }}
      />
    );
  };

  const openNewTicketTypeModal = () => {
    setTicketTypeModalTarget(null);
    setTicketTypeModalOpen(true);
  };

  const openEditTicketTypeModal = (item) => {
    setTicketTypeModalTarget(item.id);
    setTicketTypeModalOpen(true);
  };

  const updateTicketTypes = (ticketType) => {
    setTicketTypes(ticketTypes.filter((x) => x.id != ticketType.id).concat([ticketType]));
  };

  const closeTicketTypeModal = () => {
    setTicketTypeModalOpen(false);
    setTicketTypeModalTarget(null);
  };

  const viewTicket = (ticketType) => {
    //TODO go to edit page
    history.push(`/ticketing/tickets/${ticketType.id}`);
  };

  return (
    <>
      <div>
        <PageHeader text="Tickets Overview" />
        <p className="mb-4 w-1/2">
          In the ticketing module you can create tickets or packages, assign them to users, and manage and track those
          assignments inside of a singular event.
        </p>
        {renderNewTicketButton()}
        <br />
        <br />
        {renderTicketsTable()}
        {renderTicketTypeModal()}
      </div>
    </>
  );
};

export default TicketingTickets;
