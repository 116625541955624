import React, { useContext } from "react";
import PropTypes from "prop-types";

import { format, parseISO } from "date-fns";

import EventContext from "@event/EventContext";
import GrowlTable from "@shared/GrowlTable";

const EventDetailsTokensTable = props => {
  const { setEdit, setView, tokens } = props;
  const { event } = useContext(EventContext).values;

  const columns = [
    {
      headerName: "Token Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Token Value",
      field: "value",
      flex: 1
    },
    {
      headerName: "Expiration Date",
      field: "expiry",
      flex: 1,
      renderCell: params => (
        format(parseISO(params.value), "y-MM-dd")
      )
    },
    {
      headerName: "Permission Count",
      field: "permission_count",
      flex: 1,
      valueGetter: (value) => (
        Object.keys(value.row).filter(key => value.row[key] === true).length
      )
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 260,
      getActions: params => [
        renderViewAction(params.row),
        renderEditAction(params.row),
      ]
    }
  ]

  const renderViewAction = token => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          setView(token.id);
        }}
      >
        View
      </div>
    )
  }

  const renderEditAction = token => {
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          setEdit(token.id);
        }}
      >
        Edit
      </div>
    )
  }

  const renderTable = () => (
    <GrowlTable
      columns={columns}
      items={tokens}
      tableName={`${event.slug}-api-tokens`}
    />
  );

  if (tokens.length < 1) {
    return (
      <div>
        <p>No tokens have been generated.</p>
      </div>
    );
  }
  return renderTable();
};

EventDetailsTokensTable.propTypes = {
  setEdit: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  tokens: PropTypes.array.isRequired
};

export default EventDetailsTokensTable;
