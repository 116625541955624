import React, {
  useContext,
  useState,
  useEffect
} from "react";
import EventContext from "@event/EventContext";
import axios from "axios";
import urljoin from "url-join";
import { Formik, Form } from "formik";
import Loading from "@shared/Loading";
import { renderCancelButton, renderSubmitButton, renderTextField, renderRegexField } from "@shared/FormUtils";
import FormikObserver from "@shared/FormikObserver";
import { contentTypes, renderContentByType } from "@/contents/ContentTypes";
import InputSlugGenerator from "@shared/forms/InputSlugGenerator";
import { Stack } from "@mui/material";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import TemplateQuestionsForm from "./TemplateQuestionsForm";

const TemplateForm = (props) => {
  const { 
    webPageTemplateId,
    cancel,
    callbackFailure,
    callbackSuccess
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [template, setTemplate] = useState(null);
  const [webPageQuestions, setWebPageQuestions] = useState([]);
  const [ dirty, setDirty ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  const fetchTemplate = async () => {
    const result = await axios(
      urljoin(apiRoot, `/contents/web_page_templates/${webPageTemplateId ? webPageTemplateId : "new"}`)
    );
    setTemplate(result.data.web_page_template);
    setWebPageQuestions(result.data.web_page_template.questions);
  }

  useEffect(() => {
    fetchTemplate();
  }, [apiRoot, webPageTemplateId ]);

  const getInitialTemplateValues = () => {
    return {
      web_page_template: {
        name: template ? template.name : "",
        slug: template ? template.slug : "",
      }
    }
  }

  const renderButtons = (formikProps) => {
    return (
      <Stack spacing={2} direction="row">
        {renderCancelButton("Cancel", cancel)}
        {renderSubmitButton("Save", submitting || !dirty, {})}
      </Stack>
    )
  }

  const renderFormFields = (formikProps) => {
    const { values } = formikProps;
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="w-1/2 mr-4">
              {renderTextField(
                "Name",
                "web_page_template[name]",
                [],
                false,
                true
              )}
            </div>
            <div className="w-1/2 mr-4">
              {renderRegexField(
                "Identifier (lowercase letters, numbers, hyphens only)",
                "web_page_template[slug]",
                RegExp("^[0-9A-Za-z_-]{0,32}$"),
                [],
                {
                  required: true, fieldProperties: {
                    InputProps: {
                      endAdornment: values.web_page_template.slug ? "" : <InputSlugGenerator formikProps={formikProps} name={`web_page_template[slug]`} associatedValue={values.web_page_template.name} maxSlugLength={30} />
                    }
                  }
                }
              )}     
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderQuestions = (formikProps) => {
    return (
      <TemplateQuestionsForm
        questions={template.web_page_template_questions}
        template={template}
        formikProps={formikProps}
        onChange={() => setDirty(true)}
      />
    )
  }


  const formConfig = {
    formUrl: urljoin(apiRoot, `/contents/web_page_templates/${(template||{}).id}`),
    method: "PATCH"
  }

  const renderForm = () => {

    return (
      <Formik
        enableReinitialize
        initialValues={getInitialTemplateValues()}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          let submittingValues = {
            web_page_template: values.web_page_template,
          }
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: submittingValues
          })
          .then(response => {
            if (!response.data.error) {
              callbackSuccess ? callbackSuccess(response) : ()=>{};
              alertSuccess("Web Page Template Saved");
              setSubmitting(false);
              setDirty(false);
            } else {
              callbackFailure ? callbackFailure(response) : ()=>{};
              alertError(
                `Unable to save web page: ${response.data.error}`
              );
              setSubmitting(false);
            }
          })
          .catch(error => {
            console.log({
              error: error
            })
            alertHttpError(error);
          });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <FormikObserver formikProps={formikProps} onChange={formOnChange}/>
            {renderFormFields(formikProps)}
            {renderQuestions(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    )
  }

  formOnChange = (formikProps) => {
    if(formikProps.dirty){
      setDirty(true)
    }
  }


  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <h1>
        {webPageTemplateId ? "Edit " : "Add "}
        {template && template.name ? template.name : "Web Page Template"}
      </h1>
      {template && template.id != null ? renderForm() : <Loading />}
    </div>
  );
}

export default TemplateForm