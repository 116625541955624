import React from "react";

import clsx from "clsx";

const WidgetContentFrame = props => {
  const { children, fluidHeight = false } = props;

  return <div className={clsx("group relative p-7", fluidHeight ? "" : "h-widget")}>{children}</div>;
};

export default WidgetContentFrame;
