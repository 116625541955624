import React, { useEffect } from "react";
import axios from "axios";
import urljoin from "url-join";
import PropTypes from "prop-types";

const EventHeartbeat = props => {
  const { apiRoot } = props;
  const heartbeatTimer = 5 * 60 * 1000; // 5 minutes

  useEffect(() => {
    const fetchHeartbeat = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "heartbeat"));
        if (result.data.logged_in === false) {
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    };

    setInterval(() => {
      fetchHeartbeat();
    }, heartbeatTimer);
  }, [apiRoot, heartbeatTimer]);

  return <></>;
};

EventHeartbeat.propTypes = {
  apiRoot: PropTypes.string.isRequired
};

export default EventHeartbeat;
