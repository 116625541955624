import React from "react";
import clsx from "clsx";

const SubpageTab = (props) => {
  const { active, color, label, onClick, height, width } = props;

  const tabClasses = () => {
    const backgroundColor = active ? "bg-ui-3" : "bg-bg-3";
    return clsx(
      "cursor-pointer",
      "inline-block",
      "text-center",
      "mr-1",
      backgroundColor);
  };

  return (
    <div
      className={tabClasses()}
      onClick={onClick}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        lineHeight: `${height}px`,
        color: color
      }}
    >
      {label}
    </div>
  );
};

SubpageTab.defaultProps = {
  color: "white",
  height: 55,
  width: 170
}

export default SubpageTab;
