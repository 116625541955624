import React, { useState } from "react";
import PropTypes from "prop-types";
import { alertError } from "@shared/Alerts";
import EventSessionBlankSession from "../EventSessionBlankSession";
import EventSessionFormGeneralInfo from "./EventSessionFormGeneralInfo";
import EventSessionFormDetails from "./EventSessionFormDetails";

const EventSessionFormAdd = props => {
  const { resetModal, setFormSession, updateFunc } = props;
  const [phase, setPhase] = useState(1);

  switch (phase) {
    case 1:
      return (
        <EventSessionFormGeneralInfo
          callbackSuccess={response => {
            setFormSession(response.data.session);
            setPhase(2);
          }}
          callbackFailure={response => {
            alertError(response.data.error);
          }}
          cancelButton={resetModal}
        />
      );
    case 2:
      return (
        <EventSessionFormDetails
          callbackSuccess={response => {
            updateFunc.mutate(response.data.session);
            resetModal();
            setFormSession(EventSessionBlankSession);
          }}
          callbackFailure={response => {
            alertError(response.data.error);
          }}
          cancel={resetModal}
        />
      );
    default:
      throw new Error("Invalid phase");
  }
};

EventSessionFormAdd.propTypes = {
  resetModal: PropTypes.func.isRequired,
  setFormSession: PropTypes.func.isRequired,
  updateFunc: PropTypes.object.isRequired
};

export default EventSessionFormAdd;
