import React from "react";

import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";

import HousingHotelRoomTypeForm from "./HousingHotelRoomTypeForm";

const HousingHotelRoomTypeModal = props => {
  const {
    hotel,
    modalVisible,
    resetModal,
    roomType,
    title
  } = props;

  Modal.setAppElement("#root");

  const success = () => {
    alertSuccess("Room type saved successfully");
    resetModal();
  };

  const failure = error => {
    alertError(`Failed to save room type: ${error}`);
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingHotelRoomTypeForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            hotel={hotel}
            roomType={roomType}
          />
        </div>
      </div>
    </Modal>
  );
};

export default HousingHotelRoomTypeModal;
