import React, {
  useContext,
  useState
} from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import { renderCancelButton, renderCreateButton, renderButton, renderDeleteButton } from "@/shared/FormUtils";
import { alertHttpError, alertError} from "@/shared/Alerts";
import Stack from "@mui/material/Stack";
import EventContext from "@event/EventContext";
import axios from "axios";
import urljoin from "url-join";
const ReplaceStaticFileModal = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    modalVisible,
    resetModal,
    callbackFailure,
    callbackSuccess,
    staticFile
  } = props;

  Modal.setAppElement("#root");
  const [currentFile, setCurrentFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const renderForm = () => {
    return (
      <div>
        {renderDropzone()}
        {renderFiles()}
        {renderButtons()}
      </div>
    )
  };

  const renderFiles = () => {
    if(currentFile)
    return (
      <div>
        {currentFile ? renderFilePreview(currentFile) : ""}
      </div>
    );
  }

  const removeFile = (i) => {
    setCurrentFile(null);
  }

  const renderFilePreview = (file) => {
    return (
      <div className="flex">
        <div className="grow">{file.name}</div>
      </div>
    );
  }

  const renderButtons = () => {
    return (
      <Stack direction="row" spacing={2}>
        {renderButton("Upload", uploadFiles, { color: "secondary" }) }
        {renderCancelButton("Cancel", resetModal)}
      </Stack>
    );
  };

  const uploadFiles = () => {
    setSubmitting(true);
    const formData = new FormData();

    formData.append("static_file", currentFile);

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, `contents/static_files/${staticFile.id}/replace`),
      method: "POST",
      data: formData
    }).then(response => {
      if (!response.data.error) {
        callbackSuccess(response.data.static_file);
        setCurrentFile(null);
        setSubmitting(false);
        resetModal();
      } else {
        alertHttpError(response.data.error);
        setCurrentFile(null);
        setSubmitting(false);
      }
    }).catch(error => {
      alertError(error);
      console.log({
        error
      })
      setSubmitting(false);
    });
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if(acceptedFiles.length > 0) {
      setCurrentFile(acceptedFiles[0])
    }
  }

  const renderDropzone = () => {
    return (
      <Dropzone onDrop={onDrop} name="import[]">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <>
              <div
                {...getRootProps()}
                className={clsx(
                  "dropzone",
                  "sg-mgmt-form-input-static-file-drop",
                  {
                    "dropzone--isActive": isDragActive
                  }
                )}
              >
                <input {...getInputProps()} />
                Drop files here
              </div>
            </>
          );
        }}
      </Dropzone>
    );
  };

  

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Replace Static File"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Replace File</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            {modalVisible ? renderForm() : ""}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReplaceStaticFileModal;
