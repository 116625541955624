import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import urljoin from "url-join";
import { Formik, Field, Form } from "formik";
import { alertError, alertSuccess } from "@shared/Alerts";
import EventSessionTag from "./EventSessionTag";
import EventSessionTagEdit from "./EventSessionTagEdit";
import { renderSubmitButton } from "@shared/FormUtils";

const EventSessionTags = ({ apiRoot, tags, setTags }) => {
  const [editTag, setEditTag] = useState(null);

  const deleteTag = id => {
    axios({
      url: urljoin(apiRoot, "/sessions/settings/delete_tag"),
      method: "DELETE",
      data: { tag_id: id }
    }).then(response => {
      if (response.data.error === null) {
        setTags(response.data.tags);
        alertSuccess("Tag deleted");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const updateTag = (id, name) => {
    axios({
      url: urljoin(apiRoot, "/sessions/settings/update_tag"),
      method: "PATCH",
      data: {
        session_tag_id: id,
        session_tag: {
          name
        }
      }
    }).then(response => {
      if (response.data.error === null) {
        setTags(response.data.tags);
        alertSuccess("Tag updated");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const renderTagNameField = (formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <Field
        className="sg-mgmt-form-input"
        type="text"
        name="session_tag[name]"
        autoComplete="off"
      />
    </div>
  );

  const renderNewTagForm = () => (
    <Formik
      initialValues={{ session_tag: { name: "" } }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        const formData = values;
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        axios({
          url: urljoin(apiRoot, "/sessions/settings/create_tag"),
          method: "POST",
          data: formData
        }).then(response => {
          if (response.data.error === null) {
            alertSuccess("Created tag");
            setTags(response.data.tags);
            setSubmitting(false);
            resetForm({
              session_tag: { name: "" }
            });
          } else {
            alertError(response.data.error);
            setSubmitting(false);
          }
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="sg-mgmt-form">
          <div className="flex">
            <div className="mr-2">{renderTagNameField()}</div>
            <div>
              {renderSubmitButton(
                "Create Tag",
                isSubmitting
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <div className="mr-2">Tags:</div>
      <div className="flex flex-wrap mt-2 mb-4">
        {tags.map(tag => {
          if (tag.id === editTag) {
            return (
              <EventSessionTagEdit
                key={tag.gid}
                setEditTag={setEditTag}
                updateTag={updateTag}
                tag={tag}
              />
            );
          }
          return (
            <EventSessionTag
              key={tag.gid}
              setEditTag={setEditTag}
              deleteTag={deleteTag}
              tag={tag}
            />
          );
        })}
      </div>
      <div className="w-1/2">{renderNewTagForm()}</div>
    </div>
  );
};

EventSessionTags.propTypes = {
  apiRoot: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  setTags: PropTypes.func.isRequired
};

export default EventSessionTags;
