import React, { useContext, useState, useEffect } from "react";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { formatTime } from "@shared/TimeUtils";
import PageHeader from "@shared/PageHeader";
import { Link } from "react-router-dom";
import GrowlTable from "@shared/GrowlTable";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import axios from "axios";
import urljoin from "url-join";
import { renderCreateButton } from "@/shared/FormUtils";
import { useConfirm } from "material-ui-confirm";
import WebPageTable from "./WebPageTable";
const WebPageIndex = () => {
  const { user } = useContext(EventUserContext);
  const renderAddWebPagesButton = () => {
    if(editEnabled()) {
      return (
        <Link to="/content/web_pages/new">
          {renderCreateButton("Add Web Page", ()=>{})}
        </Link>
      );
    }
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.static_files_edit) {
      return false;
    }
    return true;
  };


  const renderWebPagesTable = () => {
    return (<WebPageTable />)
  };

  return (
    <div>
      <PageHeader text="Web Pages" />
      {renderAddWebPagesButton()}
      <br />
      <br />
      {renderWebPagesTable()}
    </div>
  );
};

export default WebPageIndex;