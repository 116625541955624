import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import ContentFrame from "@shared/ContentFrame";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import EventUsersFormBlankUser from "./EventUsersFormBlankUser";
import EventUsersModal from "./EventUsersModal";

const EventUsers = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [fetched, setFetched] = useState(false);
  const [editUser, setEditUser] = useState(EventUsersFormBlankUser);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [eventUsers, setEventUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const confirm = useConfirm();

  useEffect(() => {
    const fetchEventUsers = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/users"));
        setEventUsers(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchAllUsers = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/users/all"));
        setAllUsers(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchEventUsers();
    fetchAllUsers();
  }, [apiRoot]);

  const performDelete = (user) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/users/", `${user.id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteUser(user);
          alertSuccess("User deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const columns = [
    {
      headerName: "First Name",
      field: "name_first",
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "name_last",
      flex: 1
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1
    },
    {
      headerName: "Company",
      field: "company",
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      align: "right",
      minWidth: 220,
      flex: 1,
      getActions: (params) => [
        renderBecomeUser(params.row, user),
        renderViewAction(params.row),
        renderEditAction(params.row),
        renderDeleteAction(params.row)
      ]
    }
  ];

  const renderBecomeUser = (editing_user, user) => {
    if (user.role === "basic") {
      return <></>;
    }

    return (
      <>
        <span className="cursor-pointer">
          <a href={urljoin(apiRoot, `/users/${editing_user.id}/become_user`)} className="cursor-pointer">
            Become User
          </a>
        </span>
      </>
    );
  };

  const renderViewAction = (user) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setEditUser(user);
            modalEditOpen();
          }}
        >
          View
        </span>
      </>
    );
  };

  const renderEditAction = (user) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setEditUser(user);
            modalEditOpen();
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderDeleteAction = (item) => {
    if (!deleteEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm removal",
              description: "Are you sure you want to delete this user? This cannot be undone."
            })
              .then(() => {
                performDelete(item);
              })
              .catch((err) => {
                alertError(err);
              });
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const modalAddClose = () => {
    setModalAddVisible(false);
  };

  const modalAddOpen = () => {
    setModalAddVisible(true);
  };

  const modalEditClose = () => {
    setEditUser(EventUsersFormBlankUser);
    setModalEditVisible(false);
  };

  const modalEditOpen = () => {
    setModalEditVisible(true);
  };

  const addUser = (newUser) => {
    setEventUsers([newUser].concat(eventUsers));
  };

  const updateUser = (updatedUser) => {
    setEventUsers(eventUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u)));
  };

  const deleteUser = (deletedUser) => {
    setEventUsers(eventUsers.filter((u) => u.id !== deletedUser.id));
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.users_edit) {
      return false;
    }
    return true;
  };

  const deleteEnabled = () => {
    if (user.role === "basic" && !user.permission.users_delete) {
      return false;
    }
    return true;
  };

  const renderUsersTable = () => {
    return (
      <GrowlTable
        columns={columns}
        items={eventUsers}
        sortColumn="last_name"
        sortDirection="asc"
        tableName={`${event.slug}-users`}
      />
    );
  };

  const renderAddUserButton = () => {
    if (!editEnabled()) {
      return <></>;
    }

    return renderCreateButton("Add User", modalAddOpen);
  };

  const renderUsers = () => {
    if (!fetched) {
      return <Loading />;
    }

    return renderUsersTable();
  };

  return (
    <ContentFrame>
      <PageHeader text="Users" />
      {renderAddUserButton()}
      <br />
      <div className="sg-mgmt-event-users-wrapper">{renderUsers()}</div>

      <EventUsersModal
        eventUsers={eventUsers}
        modalVisible={modalAddVisible}
        resetModal={modalAddClose}
        title="Add User"
        updateFunc={addUser}
        user={EventUsersFormBlankUser}
        users={allUsers}
      />
      <EventUsersModal
        modalVisible={modalEditVisible}
        resetModal={modalEditClose}
        title={`${editUser.name_first} ${editUser.name_last}`}
        updateFunc={updateUser}
        user={editUser}
      />
    </ContentFrame>
  );
};

export default EventUsers;
