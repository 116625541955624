import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from 'clsx';
import { parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { Field, Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import CheckFieldSmall from "@shared/CheckFieldSmall";
import DateTimePickerField from "@shared/DateTimePickerField";
import SelectField from "@shared/SelectField";
import EventContext from "@event/EventContext";
import EventMeetings1On1BlankRequest from "./EventMeetings1On1BlankRequest";
import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const EventMeetings1On1RequestForm = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    addRequest,
    attendees,
    callbackFailure,
    callbackSuccess,
    cancel,
    hosts
  } = props;

  const formConfig = (() => {
    return {
      alert: "added",
      formId: "sg-mgmt-form-meeting-add",
      formUrl: urljoin(apiRoot, "/meeting1on1s/requests"),
      method: "POST",
      saveButton: "Continue",
      title: "Add Request"
    };
  })();

  const renderDataField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <Field
        className="sg-mgmt-form-input"
        type="text"
        name={`request[data][${field}]`}
        autoComplete="off"
      />
    </div>
  );

  // TODO: Make dates reflect event date
  const renderDateTimeField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <DateTimePickerField
        className={clsx("sg-mgmt-form-input", "sg-mgmt-form-input-time")}
        selected={new Date("2021-11-30T09:00:00")}
        minDate={new Date("2021-11-30T00:00:00")}
        maxDate={new Date("2021-11-30T00:00:00")}
        minTime={new Date(2000, 1, 1, 8, 0)}
        maxTime={new Date(2000, 1, 1, 19, 30)}
        timeIntervals={60}
        name={field}
        autoComplete="off"
      />
    </div>
  );

  const renderMeetingType = () => {
    const options = ["General (AR/PR)", "Prospect", "Customer", "Partner"];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Meeting Type</label>
        <SelectField fieldName="request[meeting_type]" options={options} />
      </div>
    );
  };

  const renderUseCase = () => {
    const options = [
      "DevSecOps for Kubernetes",
      "Tired Analytics/Credit Based Licensing",
      "Security Intelligence",
      "Solution (Cloud SIEM, SOC Analytics & Automation)",
      "Observability",
      "Other"
    ];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Meeting Type</label>
        <SelectField fieldName="request[data][use_case]" options={options} />
      </div>
    );
  };

  const renderCombo = () => (
    <div className="sg-mgmt-user-access-section-field">
      <CheckFieldSmall fieldName="request[combo]" label="Combo" />
    </div>
  );

  const renderHost = (choice, idx, combo) => {
    const sorted = sortBy(hosts, ["name_last", "name_first"]);
    const options = sorted.map(h => ({
      label: `${h.name_first} ${h.name_last}`,
      value: h.id
    }));
    const choiceText = choice === 1 ? "First" : "Second";
    const choiceCombo = combo ? "Selection" : "Choice";
    return (
      <div
        className="sg-mgmt-form-input-container"
        key={`host-field-${idx}-choice-${choice}`}
      >
        <label>{`Executive (${choiceText} ${choiceCombo}):`}</label>
        <SelectField
          fieldName={`request[choice_${choice}][meeting_hosts][${idx}][id]`}
          options={options}
          includeBlank
        />
      </div>
    );
  };

  const renderHostDateTime = (choice, combo = false) => {
    const choiceText = choice === 1 ? "First" : "Second";
    const fieldLabel = combo
      ? "Date and Time"
      : `Date and Time (${choiceText} Choice)`;
    if (combo && choice !== 1) {
      return <></>;
    }
    return (
      <div className="sg-mgmt-form-row">
        {renderDateTimeField(
          fieldLabel,
          `request[choice_${choice}][meeting_hosts][1][date_and_time]`,
          "sg-mgmt-form-input-date-time"
        )}
      </div>
    );
  };

  const renderHosts = (choice, combo = false) => {
    return renderHost(choice, 1, combo);
  };

  const renderAttendee = () => {
    const sorted = sortBy(attendees, ["name_last", "name_first"]);
    const options = sorted.map(h => ({
      label: `${h.name_first} ${h.name_last}`,
      value: h.id
    }));
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Attendee:</label>
        <SelectField
          fieldName="request[attendee_id]"
          options={options}
          includeBlank
        />
      </div>
    );
  };

  const formInitialValues = () => {
    const initialVals = EventMeetings1On1BlankRequest;
    initialVals.choice_1.meeting_hosts[1].date_and_time = parseISO(
      "2021-11-30T09:00:00"
    );
    initialVals.choice_2.meeting_hosts[1].date_and_time = parseISO(
      "2020-11-30T09:00:00"
    );
    return initialVals;
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        request: formInitialValues()
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);

        // Check that hosts/attendees were selected
        if (
          !values.request.choice_1.meeting_hosts[1].id ||
          !values.request.choice_2.meeting_hosts[1].id ||
          !values.request.attendee_id
        ) {
          alertError("Must select meeting hosts and attendee");
          setSubmitting(false);
          return;
        }

        if (
          values.request.choice_1.meeting_hosts[1].id ===
          values.request.choice_2.meeting_hosts[1].id
        ) {
          alertError(
            "Must select different hosts for first and second choices"
          );
          setSubmitting(false);
          return;
        }

        // if combo meeting, set choice_2 date_and_time to match choice_1
        values.request.choice_2.meeting_hosts[1].date_and_time =
          values.request.choice_1.meeting_hosts[1].date_and_time;

        // convert times to UTC for submission to back-end
        formData.set(
          "request[choice_1][meeting_hosts][1][date_and_time]",
          zonedTimeToUtc(
            values.request.choice_1.meeting_hosts[1].date_and_time,
            event.time_zone
          )
        );
        formData.set(
          "request[choice_2][meeting_hosts][1][date_and_time]",
          zonedTimeToUtc(
            values.request.choice_2.meeting_hosts[1].date_and_time,
            event.time_zone
          )
        );

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              addRequest(response.data.request);
              callbackSuccess();
              alertSuccess("Request created");
            } else {
              callbackFailure(response);
              alertError(response.data.error);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              <h2>Meeting Details</h2>
              {renderMeetingType()}
              {renderHosts(1)}
              {renderHostDateTime(1)}
              {renderHosts(2)}
              {renderHostDateTime(2)}
              {renderCombo()}
              {renderAttendee()}
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-row-column">
                  {renderDataField("Objective 1", "objective_1")}
                  {renderDataField("Objective 2", "objective_2")}
                  {renderDataField("Objective 3", "objective_3")}
                  {renderDataField("Desired Outcome", "outcome")}
                  {renderUseCase()}
                </div>
                <div className="sg-mgmt-form-row-column">
                  {renderDataField("Company Overview", "company_overview")}
                  {renderDataField("Stage of Account", "stage_account")}
                  {renderDataField("Potential Revenue", "potential_revenue")}
                  {renderDataField("Close Date", "close_date")}
                  {renderDataField("Notes/Comments", "notes")}
                </div>
              </div>
            </div>
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton(formConfig.saveButton, isSubmitting)}
            {renderCancelButton("Cancel", cancel)}
          </Stack>
        </Form>
      )}
    </Formik>
  );

  return <>{renderForm()}</>;
};

EventMeetings1On1RequestForm.defaultProps = {
  callbackFailure: () => {}
};

EventMeetings1On1RequestForm.propTypes = {
  addRequest: PropTypes.func.isRequired,
  attendees: PropTypes.array.isRequired,
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  hosts: PropTypes.array.isRequired
};

export default EventMeetings1On1RequestForm;
