import React, { useContext } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Button from "@shared/Button";
import ContentFrame from "@shared/ContentFrame";
import PageHeader from "@shared/PageHeader";

const Caches = () => {
  const { apiRoot } = useContext(EventContext).values;

  const clearIndividuals = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, "/caches/individuals"),
      method: "GET"
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess("Individuals cache invalidation triggered");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const clearParticipants = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, "/caches/participants"),
      method: "GET"
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess("Participants cache invalidation triggered");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const clearMetadata = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, "/caches/metadata"),
      method: "GET"
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess("Custom Field cache invalidation triggered");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  return (
    <ContentFrame>
      <div className="sg-mgmt-content-frame">
        <PageHeader text="Cache Management" />
        <h2>Manually Clear Caches</h2>
        <div className="mt-4 space-x-4">
          <Button onClick={clearIndividuals}>Individuals Cache</Button>
          <Button onClick={clearParticipants}>Participants Cache</Button>
          <Button onClick={clearMetadata}>Custom Field Cache</Button>
        </div>
      </div>
    </ContentFrame>
  );
};

export default Caches;
