import React from "react";

import { useAppsignal } from "@shared/useAppsignal";

const GrowlTableErrorFallback = ({ error, tableName }) => {
  if (ESB_NODE_ENV === "development") {
    console.log(`Error fallback - clearing saved state for ${tableName}`);
    console.log(`Error: ${error}`);
  }

  // clear saved state for table on error, to ensure a bad
  // saved state can't create repeating errors
  localStorage?.setItem(tableName, JSON.stringify({}));

  return (
    <div>
      <p>Error rendering table. Please try again.</p>
    </div>
  );
};

const GrowlTableBoundary = (props) => {
  const { children, tableName } = props;
  const { appsignal, ErrorBoundary } = useAppsignal();

  return (
    <ErrorBoundary
      instance={appsignal}
      tags={{ tag: `growl-table-${tableName}` }}
      fallback={(error) => <GrowlTableErrorFallback error={error} tableName={tableName} />}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GrowlTableBoundary;
