import React, { useContext } from "react";

import Modal from "react-modal";

import EventContext from "@event/EventContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import { useHousingHotelRoomTypeInventory } from "@shared/hooks/useHousingHotels";
import Loading from "@shared/Loading";

import HousingRoomTypeInventoryForm from "./HousingHotelRoomTypeInventoryForm";

const HousingHotelRoomTypeInventoryModal = props => {
  const {
    config,
    hotel,
    modalVisible,
    resetModal,
    roomType,
    title,
    updateFunc
  } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const inventoryQuery = useHousingHotelRoomTypeInventory(apiRoot, event.id, hotel.id, roomType.id);

  Modal.setAppElement("#root");

  const success = response => {
    alertSuccess("Inventory updated successfully");
    updateFunc(response.data.dates);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to update inventory");
  };

  if (inventoryQuery.isLoading) {
    return <Loading />;
  }

  if (inventoryQuery.isError) {
    return <div>Error loading inventory: {inventoryQuery.error}</div>;
  }

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div>
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingRoomTypeInventoryForm
            config={config}
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            dates={inventoryQuery.data}
            hotel={hotel}
            roomType={roomType}
          />
        </div>
      </div>
    </Modal>
  );
};

export default HousingHotelRoomTypeInventoryModal;
