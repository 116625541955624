import React, { useContext } from "react";
import ContentFrame from "@shared/ContentFrame";
import TabbedPage from "@shared/TabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";
import EventUserContext from "@/event/EventUserContext";
import WebPageTags from "./WebPageTags";
import WebPageIndex from "./WebPageIndex";
import TemplateIndex from "./templates/TemplateIndex";
const WebPages = () => {
  const { user } = useContext(EventUserContext);
  const getTabs = () => {
    let tabs = [
      {
        label: "Pages",
        component: WebPageIndex,
        componentProps: {}
      },
      {
        label: "Templates",
        component: TemplateIndex,
        componentProps: {}
      }
    ];

    if (user && (user.role != "basic" || user.permission.web_pages_edit)) {
      tabs.push(
        { label: "Settings", component: WebPageTags, componentProps: {}, icon: TabSettingsIcon},
      )
    }

    return tabs;
  };

  return (
    <TabbedPage tabs={getTabs()} />
  );
};

export default WebPages;
