// Formik wrapper for react-ace

import React from "react";
import { useField, useFormikContext } from "formik";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const HtmlEditorField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(props);

  const onChange = val => {
    setFieldValue(field.name, val);
    setTimeout(() => setFieldTouched(field.name, true));  // trigger form onChange
  };

  return (
    <AceEditor
      {...field}
      {...props}
      onChange={onChange}
      value={field.value}
      placeholder={props.placeholder ? props.placeholder : "Enter HTML here"}
      mode={props.mode ? props.mode : "html"}
      theme="tomorrow"
      name={field.name}
      id={field.name}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2
      }}
    />
  );
};

export default HtmlEditorField;
