import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import clsx from "clsx";
import { Formik, Form } from "formik";
import Dropzone from "react-dropzone";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderCancelButton, renderSubmitButton, renderTextField } from "@shared/FormUtils";
import { useAddHousingHotel, useUpdateHousingHotel } from "@shared/hooks/useHousingHotels";

import HousingBlankHotel from "./HousingBlankHotel";

const HousingHotelForm = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { callbackFailure = () => { }, callbackSuccess = () => { }, cancelButton = () => { }, hotel } = props;
  const [image, setImage] = useState(null);
  const [imageDropped, setImageDropped] = useState(false);
  const [previewImageURL, setPreviewImageURL] = useState(null);
  const addHousingHotel = useAddHousingHotel(apiRoot, event.id);
  const updateHousingHotel = useUpdateHousingHotel(apiRoot, event.id);
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";

  useEffect(() => {
    setPreviewImageURL(hotel.image_url);
  }, [hotel]);

  const isEdit = () => {
    return hotel && hotel.id;
  };

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (e) => {
        setImage(acceptedFiles[0]);
        setImageDropped(true);
        setPreviewImageURL(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setImage(acceptedFiles[0]);
      setImageDropped(true);
      setPreviewImageURL(null);
    }
  };

  const dropzoneFilename = () => {
    if (imageDropped) {
      return (
        <>
          File attached:
          <br />
          {image.name}
        </>
      );
    }
    return <></>;
  };

  const renderImagePreview = (header = false) => {
    const previewClass = header ? "sg-mgmt-form-tile-preview-header" : "";
    if (previewImageURL) {
      return (
        <div className="sg-mgmt-form-tile-preview-container">
          <img
            className={clsx("sg-mgmt-hotel-image-preview", previewClass)}
            src={previewImageURL}
            alt="Hotel Preview"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderImageUpload = () => {
    return (
      <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="event[tile]">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={clsx("dropzone", "sg-mgmt-form-input-dropzone-tile", {
                "dropzone--isActive": isDragActive
              })}
            >
              <input {...getInputProps()} />
              <div className="sg-mgmt-form-input-dropzone-tile-prompt">
                Drag and Drop
                <br />
                or Click to Choose File
              </div>
              <div className="sg-mgmt-form-input-dropzone-tile-filename">{dropzoneFilename()}</div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        formId: "sg-mgmt-form-token-edit",
        saveButton: "Update"
      };
    }
    return {
      formId: "sg-mgmt-form-token-add",
      saveButton: "Create"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: hotel.name || "",
        address_street_1: hotel.address_street_1 || "",
        address_street_2: hotel.address_street_2 || "",
        address_city: hotel.address_city || "",
        address_state: hotel.address_state || "",
        address_postcode: hotel.address_postcode || "",
        address_country: hotel.address_country || "",
        phone: hotel.phone || ""
      };
    }
    return HousingBlankHotel;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          hotel: formInitialValues()
        }}
        onSubmit={({ setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);

          if (image) {
            formData.set("hotel[image]", image);
          }

          if (isEdit()) {
            updateHousingHotel.mutate(
              {
                id: hotel.id,
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                  } else {
                    callbackFailure();
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertHttpError(error);
                }
              }
            );
          } else {
            addHousingHotel.mutate(
              {
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                  } else {
                    callbackFailure();
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertHttpError(error);
                }
              }
            );
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">{renderTextField("Hotel Name", "hotel[name]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Address (line 1)", "hotel[address_street_1]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Address (line 2)", "hotel[address_street_2]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("City", "hotel[address_city]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("State/Province", "hotel[address_state]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("ZIP/Postal Code", "hotel[address_postcode]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Country", "hotel[address_country]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Phone Number", "hotel[phone]")}</div>
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-input-container">
                  <label>Choose hotel image</label>
                  {renderImageUpload()}
                  {renderImagePreview()}
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default HousingHotelForm;
