import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";

import HousingRoomBlockForm from "./HousingRoomBlockForm";

const HousingRoomBlock = props => {
  const { editBlockId, goIndex, setEditBlockId, updateBlocks } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [block, setBlock] = useState(false);
  const { user } = useContext(EventUserContext);

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchBlock = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/housing/blocks", `/${editBlockId}`)
        );
        setBlock(result.data.block);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchBlock();
  }, [apiRoot, editBlockId]);

  const success = response => {
    updateBlocks(response.data.block);
    goIndex();
    setEditBlockId(null);
  };

  const failure = () => {
    alertError("Failed to update block");
  };

  const renderBlockForm = () => {
    if (fetched) {
      return (
        <HousingRoomBlockForm
          callbackFailure={failure}
          callbackSuccess={success}
          cancelButton={goIndex}
          block={block}
        />
      );
    }
    return <Loading />;
  };

  return <div>{renderBlockForm()}</div>;
};

HousingRoomBlock.propTypes = {
  config: PropTypes.object.isRequired,
  editBlockId: PropTypes.number.isRequired,
  goIndex: PropTypes.func.isRequired,
  setEditBlockId: PropTypes.func.isRequired,
  updateBlocks: PropTypes.func.isRequired
};

export default HousingRoomBlock;
