export default {
  name: "",
  attendees_view: false,
  attendees_edit: false,
  feature_flag_view: false,
  feature_flag_edit: false,
  forms_view: false,
  forms_edit: false,
  meetings_view: false,
  meetings_edit: false,
  sessions_view: false,
  sessions_edit: false,
  speakers_view: false,
  speakers_edit: false,
  surveys_view: false,
  surveys_edit: false,
  users_view: false,
  users_edit: false,
  expiry: new Date(),
  whitelist: [],
  whitelist_values: ""
};
