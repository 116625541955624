import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import GrowlTable from "@shared/GrowlTable";
import { alertSuccess, alertError } from "@shared/Alerts";
import EventContext from "../../EventContext";
import EventSessionContext from "../EventSessionContext";

const EventSessionSpeakersTable = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { session } = useContext(EventSessionContext);
  const { items, mode, update } = props;

  const callbackSuccessAdd = att => {
    const assignments = session.session_participants.concat(att);
    update({ ...session, session_participants: assignments });
  };

  const callbackSuccessRemove = gid => {
    const assignments = session.session_participants.filter(
      a => a.event_participant.gid !== gid
    );
    update({ ...session, session_participants: assignments });
  };

  const addAssignment = gid => {
    const postData = {
      event_participant_gid: gid,
      session_participant: { role: "speaker" }
    };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/participants"),
      method: "POST",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        callbackSuccessAdd(response.data.session_participant);
        alertSuccess("Added speaker to session");
      } else {
        alertError("Failed assigning speaker to session");
      }
    });
  };

  const removeAssignment = gid => {
    const postData = { event_participant_gid: gid };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/participants"),
      method: "DELETE",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        callbackSuccessRemove(response.data.event_participant_gid);
        alertSuccess("Removed speaker from session");
      } else {
        alertError("Failed to remove speaker from session");
      }
    });
  };

  const filteredItems = () => {
    const assignedIds = session.session_participants
      .filter(sp => sp.role === "speaker")
      .map(sp => sp.event_participant.gid);
    if (mode === "available") {
      return items.filter(att => !assignedIds.includes(att.gid));
    }
    return items.filter(att => assignedIds.includes(att.gid));
  };

  const renderAddAssign = (item) => {
    if (mode !== "available") { return <></>; }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => addAssignment(item.gid)}
        >
          Assign
        </span>
      </>
    )
  };

  const renderRemoveAssign = (item) => {
    if (mode === "available") { return <></>; }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => removeAssignment(item.gid)}
        >
          Remove
        </span>
      </>
    )
  };

  const tableColumns = [
    {
      headerName: "GID",
      field: "gid",
      flex: 1
    },
    {
      headerName: "First Name",
      field: "name_first",
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "name_last",
      flex: 1
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1
    },
    {
      headerName: "Company",
      field: "company",
      flex: 1
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      getActions: params => [
        renderAddAssign(params.row),
        renderRemoveAssign(params.row)
      ]
    }
  ];

  return (
    <GrowlTable
      columns={tableColumns}
      items={filteredItems()}
      tableName={`${event.slug}-speakers`}
    />
  )
};

EventSessionSpeakersTable.propTypes = {
  items: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired
};

export default EventSessionSpeakersTable;
