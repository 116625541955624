import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";


import Stack from "@mui/material/Stack";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import Dropzone from "react-dropzone";
import urljoin from "url-join";

import { alertError, alertSuccess } from "@shared/Alerts";
import CsrfToken from "@shared/CsrfToken";
import {
  renderCancelButton,
  renderCheckField,
  renderSubmitButton,
  renderRichTextAreaField,
  renderTextField
} from "@shared/FormUtils";

import PeopleBlankIndividual from "./PeopleBlankIndividual";

const PeopleIndividualForm = props => {
  const { cancelButton, fetched, individual, rootUrl, updateIndividual } =
    props;
  const [photo, setPhoto] = useState(null);
  const [photoDropped, setPhotoDropped] = useState(false);
  const [previewPhotoUrl, setPreviewPhotoUrl] = useState(null);
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";
  useEffect(() => {
    setPreviewPhotoUrl(individual.photo_url);
  }, [individual]);

  const config = {
    alert: "updated",
    formId: "sg-mgmt-form-attendee-edit",
    formUrl: urljoin(rootUrl, "/-/individuals/", `/${individual.id}`),
    method: "PATCH",
    saveButton: "Save",
    title: "Edit Person"
  };

  const onDrop = acceptedFiles => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = e => {
        setPhoto(acceptedFiles[0]);
        setPhotoDropped(true);
        setPreviewPhotoUrl(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setPhoto(acceptedFiles[0]);
      setPhotoDropped(true);
      setPreviewPhotoUrl(null);
    }
  };

  const dropzoneFilename = () => {
    if (photoDropped) {
      return (
        <>
          File attached:
          <br />
          {photo.name}
        </>
      );
    }
    return <>Upload Photo</>;
  };

  const renderPhoto = () => {
    if (previewPhotoUrl) {
      return (
        <img
          className="sg-mgmt-form-speaker-photo-container cursor-pointer"
          src={previewPhotoUrl}
          alt="Individual"
        />
      );
    }
    return (
      <div className="sg-mgmt-form-input-dropzone-tile-prompt">
        Drag and Drop
        <br />
        or Click to Choose File
      </div>
    );
  };

  const renderPhotoField = () => {
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Photo</label>
        <Dropzone
          accept={dropzoneAccept}
          onDrop={onDrop}
          name="individual[photo]"
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className={clsx(
                    "dropzone",
                    "sg-mgmt-form-input-dropzone-tile",
                    {
                      "dropzone--isActive": isDragActive
                    }
                  )}
                >
                  <input {...getInputProps()} />
                  {renderPhoto()}
                </div>
                <div className="sg-mgmt-participant-photo-filename cursor-pointer">
                  {dropzoneFilename()}
                </div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const formInitialValues = () => {
    if (fetched) {
      return {
        name_last: individual.name_last || "",
        name_first: individual.name_first || "",
        job_title: individual.job_title || "",
        company: individual.company || "",
        email: individual.email || "",
        work_phone: individual.work_phone || "",
        work_address_street_1: individual.work_address_street_1 || "",
        work_address_street_2: individual.work_address_street_2 || "",
        work_address_city: individual.work_address_city || "",
        work_address_state: individual.work_address_state || "",
        work_address_postcode: individual.work_address_postcode || "",
        work_address_country: individual.work_address_country || "",
        bio: individual.bio || "",
        opt_out: individual.opt_out || "",
        photo_url: ""
      };
    }
    return PeopleBlankIndividual;
  };

  const renderIndividualForm = () => {
    return (
      <Formik
        initialValues={{
          individual: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          CsrfToken();
          const form = document.getElementById(config.formId);
          const formData = new FormData(form);

          if (photo) {
            formData.set("individual[photo]", photo);
          }

          if (values.individual.opt_out === false) {
            formData.set("individual[opt_out]", false);
          }

          axios({
            url: config.formUrl,
            method: config.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              updateIndividual(response.data.individual);
              alertSuccess("Saved changes to individual");
              setSubmitting(false);
              cancelButton();
            } else {
              alertError(response.error);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={config.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("First Name", "individual[name_first]")}
                {renderTextField("Last Name", "individual[name_last]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Company", "individual[company]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Job Title", "individual[job_title]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Email Address", "individual[email]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Work Phone Number", "individual[work_phone]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField(
                  "Address (line 1)",
                  "individual[work_address_street_1]"
                )}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField(
                  "Address (line 2)",
                  "individual[work_address_street_2]"
                )}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("City", "individual[work_address_city]")}
                {renderTextField(
                  "State/Province",
                  "individual[work_address_state]",
                  ["sg-mgmt-form-input-container-state"]
                )}
                {renderTextField(
                  "ZIP/Postal Code",
                  "individual[work_address_postcode]",
                  ["sg-mgmt-form-input-container-postcode"]
                )}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Country", "individual[work_address_country]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderRichTextAreaField("Bio", "individual[bio]")}
              </div>
              <div className="sg-mgmt-form-speaker-container-photo">
                {renderPhotoField()}
              </div>
              <div className="sg-mgmt-form-row">
                {renderCheckField(
                  "Opt Out of All Direct Emails",
                  "individual[opt_out]"
                )}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(config.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderIndividualForm()}</>;
};

PeopleIndividualForm.propTypes = {
  cancelButton: PropTypes.func.isRequired,
  fetched: PropTypes.bool.isRequired,
  individual: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
  updateIndividual: PropTypes.func.isRequired
};

export default PeopleIndividualForm;
