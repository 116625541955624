import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { Box } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import Loading from "@shared/Loading";
import EventSurveysView from "./EventSurveysView";

const EventSurveys = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [surveys, setSurveys] = useState([]);
  const [viewSurvey, setViewSurvey] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  useEffect(() => {
    const fetchSurveys = async () => {
      const result = await axios(urljoin(apiRoot, "/surveys"));
      setSurveys(result.data.surveys);
      setFetched(true);
    };

    fetchSurveys();
  }, [apiRoot]);

  const columns = [
    {
      field: "session_title",
      headerName: "Session",
      valueGetter: params => params.row.data.session_title,
      flex: 1
    },
    {
      field: "attendee_name",
      headerName: "Attendee",
      valueGetter: params => params.row.data.attendee_name,
      flex: 1
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      getActions: params => [
        <>
          <span
            className="sg-mgmt-link"
            onClick={() => {
              setViewSurvey(params.id);
              openViewModal();
            }}
          >
            View
          </span>
        </>
      ]
    }
  ];

  const openViewModal = () => {
    setViewModalVisible(true);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const resetViewModal = () => {
    closeViewModal();
    setViewSurvey(null);
  };

  const renderSurveysTable = () => {
    if (fetched) {
      return (
        <Box display="flex" width="100%">
          <DataGridPro
            autoHeight
            unstable_headerFilters
            columns={columns}
            rows={surveys}
            initialState={{
              sorting: {
                sortModel: [{ field: 'session_title', sort: 'asc' }],
              },
            }}
            sx={{
              width: "100%",
              overflow: "hidden"
            }}
          />
        </Box>
      );
    }
    return <Loading />;
  };

  return (
    <div className="sg-mgmt-content-frame">
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <h1>Surveys</h1>
        {renderSurveysTable()}
      </div>
      <EventSurveysView
        closeModal={closeViewModal}
        modalVisible={viewModalVisible}
        resetModal={resetViewModal}
        survey={viewSurvey}
      />
    </div>
  );
};

export default EventSurveys;
