import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";

const ReportWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "customReports",
      widget_header: widget?.widget_header || "Reports",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 2,
      widget_config: widget?.widget_config || {
        email_id: null,
        email_template_id: null,
        email_template_tag_id: null
      }
    }
  });
  const [dataSetOptions, setDataSetOptions] = useState({
    customReports: [],
    standardReports: []
  });

  const dataSetWatch = watch("widget_data_set");

  const dataSets = [
    { value: "customReports", label: "Custom Reports" },
    { value: "standardReports", label: "Standard Reports" }
  ];

  useQuery({
    queryKey: ["dataSetOptions", dataSetWatch],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/dashboard/", `${widgetBoard.id}`, "/dataset_options", `/${dataSetWatch}`), {
          signal
        })
        .then((res) => {
          setDataSetOptions({
            ...dataSetOptions,
            [dataSetWatch]: res.data.options
          });
          return res.data;
        })
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    Object.entries(submitData.widget_config.reports[dataSetWatch]).forEach(([key, report]) => {
      if (report.selected) {
        const option = dataSetOptions[dataSetWatch].find((data) => data.value.toString() === key);

        // if it's not in the data set, purge it
        if (!option) {
          delete submitData.widget_config.reports[dataSetWatch][key];
          return;
        }
        // otherwise, set the label
        report.label = option.label;

        // if dataset has order values, set the order
        if (option.order !== undefined) {
          report.order = option.order;
        }

        // if dataset has path values, set the path
        if (option.path !== undefined) {
          report.path = option.path;
        }
      } else {
        delete submitData.widget_config.reports[dataSetWatch][key];
      }
    });

    // purge bubble configs from previously selected dataSets
    Object.entries(submitData.widget_config.reports).forEach(([key, _values]) => {
      if (key !== dataSetWatch) {
        delete submitData.widget_config.reports[key];
      }
    });

    submitData.widget_type = "report";

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((result) => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderDataSetConfig = () => {
    switch (dataSetWatch) {
      default:
        return <></>;
    }
  };

  const renderReportSelections = () => {
    return (
      <div className="pb-12">
        <h3>Select from the options below</h3>
        {dataSetOptions[dataSetWatch].sort((a, b) => a.label.localeCompare(b.label)).map((data) => {
          return (
            <div className="flex flex-row" key={`${dataSetWatch}-dataset-${data.value}`}>
              <div className="w-full">
                <FormControl>
                  <Controller
                    name={`widget_config[reports][${dataSetWatch}][${data.value}][selected]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={data.label}
                        control={
                          <Checkbox checked={value} onChange={onChange} value={value} variant="outlined" size="small" />
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} placeholder={"i.e. Email Stats"} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderDataSetConfig()}
          <div>{renderReportSelections()}</div>
        </div>
        <WidgetSizeOptions control={control} />
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Reports widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

ReportWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default ReportWidgetSettings;
