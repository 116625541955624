import React from "react";

import Modal from "react-modal";

import { renderButton, renderCancelButton } from "@shared/FormUtils";

const TicketingPackageErrorModal = (props) => {
  const { assignPackage, assignmentMessage, modalVisible, resetModal, unassignTicket } = props;

  Modal.setAppElement("#root");

  const cancelButton = () => {
    resetModal();
  };

  const removeTicketAddPackage = () => {
    unassignTicket(assignmentMessage.participant_ticket_id);
    addPackage();
  };

  const addPackage = () => {
    assignPackage(assignmentMessage.participant_id);
    resetModal();
  };

  const renderOptions = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className={`sg-mgmt-modal-title`}>This person is already assigned to {assignmentMessage.ticket_name}</div>
        <div className="sg-mgmt-modal-content">
          <h2>What would you like to do?</h2>

          <div className="flex items-center justify-between">
            <div>Remove single ticket assignment and assign package</div>
            {renderButton(
              "Confirm",
              () => {
                removeTicketAddPackage();
              },
              {
                color: "secondary"
              }
            )}
          </div>

          <div className="mt-2 flex items-center justify-between">
            <div>
              Keep single ticket assignment and assign package
              <br />
              (Note: that person will have two of the same ticket)
            </div>
            {renderButton(
              "Confirm",
              () => {
                addPackage();
              },
              {
                color: "secondary"
              }
            )}
          </div>
          <div className="mt-8">
            {renderCancelButton(
              "Cancel",
              () => {
                cancelButton();
              },
              {
                color: "error"
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Edit Ticket Type"
    >
      {modalVisible ? renderOptions() : ""}
    </Modal>
  );
};

export default TicketingPackageErrorModal;
