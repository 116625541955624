import React, { useContext, useState } from "react";

import EventContext from "@event/EventContext";
import { alertError } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import GrowlTableStatic from "@shared/GrowlTableStatic";
import { useDeleteHousingBlock } from "@shared/hooks/useHousingBlocks";
import { requiredProp } from "@shared/PropUtils";

import HousingBlankRoomBlock from "./HousingBlankRoomBlock";
import HousingRoomBlockModal from "./HousingRoomBlockModal";

const HousingRoomBlocksIndex = props => {
  const {
    blocks,
    goEdit,
    goRooms,
    setEditBlockId
  } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const deleteHousingBlock = useDeleteHousingBlock(apiRoot, event.id);
  requiredProp("blocks", blocks);
  requiredProp("goEdit", goEdit);
  requiredProp("goRooms", goRooms);
  requiredProp("setEditBlockId", setEditBlockId);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const performDelete = id => {
    deleteHousingBlock.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            window.location.reload();
          } else {
            alertError(data.error);
          }
        },
        onError: (error) => {
          alertError(error);
        }
      }
    );
  };

  const deleteConfirmMsg = () => (
    <>
      <p>Are you sure you want to delete this room block?</p>
    </>
  );

  const renderDelete = (block) => {
    if (block.room_block_room_types.length !== 0) { return <></>; }

    return (
      <>
        <GrowlModal
          content={deleteConfirmMsg()}
          title={`Delete Room Block`}
          trigger={
            <span className="text-ui-7 uppercase cursor-pointer border-r-ui-7 border-r pr-2 inline-block">Delete</span>
          }
          actions={[
            {
              label: "Cancel",
              close: true
            },
            {
              label: "Delete",
              close: true,
              func: () => {
                performDelete(block.id);
              }
            }
          ]}
        />
      </>
    );
  };

  const renderManageAction = (block) => {
    if (block.main) { return <></>; }

    return (
      <>
        <span
          className="text-ui-7 uppercase cursor-pointer border-r-ui-7 border-r px-2 inline-block"
          onClick={() => {
            setEditBlockId(block.id);
            goRooms();
          }}
        >
          Manage
        </span>
      </>
    );
  };

  const renderEditAction = (block) => (
    <>
      <span
        className="text-ui-7 uppercase cursor-pointer pl-2"
        onClick={() => {
          setEditBlockId(block.id);
          goEdit();
        }}
      >
        Edit Info
      </span>
    </>
  );

  const generateStaticRowData = (block) => {
    return {
      key: block.id,
      cells: [
        <>{block.name}</>,
        <>{block.code}</>,
        <>
          {block.owner_name_first}
          &nbsp;
          {block.owner_name_last}
        </>,
        <>{block.notes}</>,
        <>
          <div className="text-right text-[13px] font-medium leading-none">
            {renderDelete(block)}
            {renderManageAction(block)}
            {renderEditAction(block)}
          </div>
        </>
      ]
    }
  }

  const renderBlockTableStatic = () => {
    const mainBlock = blocks.filter(blk => blk.main)[0];
    const nonMainBlocks = blocks.filter(blk => !blk.main);

    const columns = ["Room Block Name", "Code", "Owner", "Notes", "Actions"];
    const items = [generateStaticRowData(mainBlock), ...nonMainBlocks.map(b => generateStaticRowData(b))];
    return (
      <div className="my-2">
        <GrowlTableStatic
          columns={columns}
          items={items}
        />
      </div>
    );
  };

  const renderNewBlockButton = () => {
    return (
      renderCreateButton(
        "Add Room Block",
        () => {
          setModalAddVisible(true);
        }
      )
    )
  };

  return (
    <div>
      {renderNewBlockButton()}
      {renderBlockTableStatic()}
      <HousingRoomBlockModal
        block={HousingBlankRoomBlock}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Room Block"
      />
    </div>
  );
};

export default HousingRoomBlocksIndex;
