import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import EventContext from "@event/EventContext";
import { useHousingHotels } from "@shared/hooks/useHousing";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import HousingHotel from "./HousingHotel";
import HousingHotelRoomTypes from "./HousingHotelRoomTypes";
import HousingHotelsIndex from "./HousingHotelsIndex";

const HousingHotels = (props) => {
  const { forceUpdate } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [page, setPage] = useState("index");
  const [editHotelId, setEditHotelId] = useState(null);
  const hotelsQuery = useHousingHotels(apiRoot, event.id);

  const goToPage = (p) => {
    setPage(p);
  };

  useEffect(() => {
    goToPage("index");
  }, [forceUpdate]);

  const renderPage = () => {
    switch (page) {
      case "index":
        return (
          <HousingHotelsIndex
            config={hotelsQuery.data.configuration}
            setEditHotelId={setEditHotelId}
            hotels={hotelsQuery.data.hotels}
            goEdit={() => goToPage("edit")}
            goRooms={() => goToPage("room_types")}
          />
        );
      case "edit":
        return (
          <HousingHotel
            config={hotelsQuery.data.configuration}
            editHotelId={editHotelId}
            goIndex={() => goToPage("index")}
            setEditHotelId={setEditHotelId}
          />
        );
      case "room_types":
        return (
          <HousingHotelRoomTypes
            config={hotelsQuery.data.configuration}
            hotel={hotelsQuery.data.hotels.find((ele) => ele.id === editHotelId)}
            goIndex={() => goToPage("index")}
            setEditHotelId={setEditHotelId}
          />
        );
      default:
        throw new Error("invalid housing page");
    }
  };

  if (hotelsQuery.isLoading) {
    return <Loading />;
  }

  if (hotelsQuery.isError) {
    return <div>Error loading hotels: {hotelsQuery.error}</div>;
  }

  return (
    <div>
      <PageHeader text="Hotels" />
      {renderPage()}
    </div>
  );
};

HousingHotels.propTypes = {
  forceUpdate: PropTypes.any.isRequired
};

export default HousingHotels;
