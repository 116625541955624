import React, { useContext } from "react";

import urljoin from "url-join";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import CustomReportDownload from "@reporting/custom/CustomReportDownload";
import { useCustomReports } from "@shared/hooks/useCustomReports";

import ReportWidgetSettings from "./ReportWidgetSettings";
import StandardReportDownload from "./StandardReportDownload";

const ReportsWidget = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { editMode, widget } = useContext(WidgetContext);
  const reports = useCustomReports(apiRoot, event.id);
  const dataSet = widget?.widget_data_set;

  const renderCustomReport = (gid) => {
    const report = reports.data.reports.find((ele) => ele.gid === gid);

    if (!report) {
      return <></>;
    }

    return (
      <div className="mb-1 flex" key={gid}>
        <div style={{ width: "fit-content" }}>
          <CustomReportDownload url={urljoin(apiRoot, "/reports", `/${report.id}`, "/download")} useIcon={true} />
        </div>
        <div className="ml-1">
          <CustomReportDownload url={urljoin(apiRoot, "/reports", `/${report.id}`, "/download")} label={report.name} />
        </div>
      </div>
    );
  };

  const renderStandardReport = (key, report) => {
    return <StandardReportDownload url={report.path} label={report.label} key={key} />;
  };

  const renderReports = (reports) => {
    if (dataSet === "customReports") {
      return (
        <div className="flex h-widget-body flex-col flex-wrap justify-start gap-x-4 pt-2 align-middle">
          {reports.sort((a, b) => a[1].label.localeCompare(b[1].label)).map((val) => {
            return renderCustomReport(val[0], val[1]);
          })}
        </div>
      );
    }

    return (
      <div className="flex h-widget-body flex-col flex-wrap justify-start gap-x-4 pt-2 align-middle">
        {reports.sort((a, b) => a[1].label.localeCompare(b[1].label)).map((val) => {
          return renderStandardReport(val[0], val[1]);
        })}
      </div>
    );
  };

  const renderContent = () => {
    if (!dataSet) {
      return <div className="h-widget-body">No data set selected</div>;
    }

    const sortedReports = Object.entries(widget?.widget_config?.reports?.[dataSet])
      .filter((val) => val[1].selected)
      .sort((a, b) => a[1].order - b[1].order);

    return renderReports(sortedReports);
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Reports Settings" settingsComponent={ReportWidgetSettings} />;
  };

  if (reports.isLoading) {
    return <div>Loading...</div>;
  }

  if (reports.isError) {
    return <div>Error: {reports.error.message}</div>;
  }

  return (
    <WidgetContentFrame>
      <WidgetHeader />
      {renderContent()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default ReportsWidget;
