import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import { parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { Formik, Form } from "formik";
import range from "lodash/range";
import urljoin from "url-join";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import GrowlModal from "@shared/GrowlModal";
import DateTimePickerField from "@shared/DateTimePickerField";
import {
  renderCancelButton,
  renderCheckField,
  renderRadioField,
  renderSubmitButton
} from "@shared/FormUtils";
import Stack from "@mui/material/Stack";
import IntegerField from "@shared/IntegerField";
import SelectField from "@shared/forms/SelectField";
import EventContext from "@event/EventContext";
import EventSessionBlankSession from "../EventSessionBlankSession";
import EventSessionContext from "../EventSessionContext";

const EventSessionsFormDetails = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { session, venues } = useContext(EventSessionContext);
  const { callbackFailure, callbackSuccess, cancel } = props;
  const [resourceFields, setResourceFields] = useState(0);

  const isEdit = () => {
    return session && session.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-session-edit",
        formUrl: urljoin(apiRoot, "/sessions", `/${session.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit Session"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-session-add",
      formUrl: urljoin(apiRoot, "/sessions"),
      method: "POST",
      saveButton: "Continue",
      title: "Add Session"
    };
  })();

  const addResourceField = () => {
    setResourceFields(resourceFields + 1);
  };

  const renderResourceFileField = (label, idx = 0, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <input
        className="sg-mgmt-form-input"
        type="text"
        name={`resources[resource][${idx}][name]`}
        autoComplete="off"
        placeholder="Name"
      />
      <input
        className="sg-mgmt-form-input"
        type="file"
        name={`resources[resource][${idx}][file]`}
        autoComplete="off"
      />
    </div>
  );

  const deleteResource = gid => {
    const postData = { resource_gid: gid };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/resource"),
      method: "DELETE",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        callbackSuccess(response);
        alertSuccess("Deleted resource");
      } else {
        alertError("Failed deleting resource");
      }
    });
  };

  const removeVideo = gid => {
    const postData = { resource_gid: gid };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/video"),
      method: "DELETE",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        callbackSuccess(response);
        alertSuccess("Removed video");
      } else {
        alertError("Failed removing video");
      }
    });
  };

  const renderSessionVideoCurrent = () => {
    const { video } = session;
    if (video) {
      return (
        <div className="sg-mgmt-form-row">
          Current video:&nbsp;<a href={video.url}>{video.url}</a>
          <div className="pl-4">
            (
            <GrowlModal
              content="Are you sure you want to remove the video from this session?"
              title="Remove Video"
              trigger={<span className="sg-mgmt-link">remove video</span>}
              actions={[
                {
                  label: "Cancel",
                  close: true
                },
                {
                  label: "Delete",
                  func: () => {
                    removeVideo(session.id);
                  }
                }
              ]}
            />
            )
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderSessionVideo = () => (
    <div>
      <div className="sg-mgmt-form-row">
        Upload video or enter URL of video hosted on remote site:
      </div>
      {renderSessionVideoCurrent()}
      <div className="sg-mgmt-form-input-container">
        <label>Upload video file</label>
        <input
          className="sg-mgmt-form-input"
          type="file"
          name="session[session_video][video]"
          autoComplete="off"
        />
      </div>
      <div className="sg-mgmt-form-input-container">
        <label>Enter remote URL</label>
        <input
          className="sg-mgmt-form-input"
          type="text"
          name="session[session_video][external_url]"
          autoComplete="off"
          placeholder="URL of video on remote site"
        />
      </div>
    </div>
  );

  const renderDateTimeField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <DateTimePickerField
        className={clsx("sg-mgmt-form-input", "sg-mgmt-form-input-time")}
        minDate={new Date(`${event.date_begin}T00:00:00`)}
        maxDate={new Date(`${event.date_end}T00:00:00`)}
        name={`session[${field}]`}
        timeIntervals={15}
        autoComplete="off"
      />
    </div>
  );

  const renderIntegerField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <IntegerField
        className="sg-mgmt-form-input"
        name={`session[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderPresentationTypeField = () => {
    const options = [
      { label: "Physical (in-person)", value: 0 },
      { label: "Virtual (online)", value: 1 },
      { label: "Hybrid (in-person and online)", value: 2 }
    ];
    return renderRadioField("session[presentation_type]", options);
  };

  const renderLocationField = (presentation_type) => {
    // Virtual only, no location
    if (presentation_type == 1) {
      return <></>;
    }

    const options = venues.map(venue => 
      venue.rooms.map(room => ({
        label: `${venue.name}: ${room.name}`,
        value: room.id,
        venue: venue.name,
      }))
    ).flat();

    return (
      <div className="sg-mgmt-form-section">
        <h2>Session Location</h2>
        <div className="sg-mgmt-form-row">
          <div className="sg-mgmt-form-input-container">
            <label>Room</label>
            <SelectField
              name="session[session_room_id]"
              defaultValue={
                (session.session_room && session.session_room.id ?
                  { label: `${session.session_room.venue_name}: ${session.session_room.name}`, value: session.session_room_id}
                  :
                  { label: null, value: null }
                )
              }
              groupBy={(option) => option.venue}
              options={options}
              groupedValues
            />
          </div>
        </div>
      </div>
    );
  };

  const renderOnDemand = () => {
    const options = [
      { label: "Scheduled", value: false },
      { label: "On-Demand", value: true }
    ];
    return renderRadioField("session[on_demand]", options, false);
  };

  const renderEnrollmentField = () => {
    const options = [
      { label: "Closed", value: "closed_enroll" },
      // { label: "By Invitation (not yet supported)", value: "invite_enroll" },
      { label: "Open", value: "open_enroll" }
    ];
    return renderRadioField("session[enrollment]", options, false);
  };

  const renderAssignAllField = (formatClasses = []) => {
    return renderCheckField(
      "Assign to All Attendees?",
      "session[data][assign_all]",
      formatClasses
    );
  };

  const renderResourceFields = () => {
    if (resourceFields > 0) {
      return range(0, resourceFields).map(idx => (
        <div className="sg-mgmt-form-row" key={`resource-${idx}`}>
          {renderResourceFileField("Add New Resource", idx)}
        </div>
      ));
    }
    return <></>;
  };

  const renderResources = () => {
    if (session.resources && session.resources.length > 0) {
      return (
        <div>
          {session.resources.map(r => (
            <div key={r.gid}>
              <a className="sg-mgmt-link" href={r.file_url}>
                {r.name}
              </a>
              &nbsp; (
              <GrowlModal
                content="Are you sure you want to delete this resource?"
                title="Delete Resource"
                trigger={<span className="sg-mgmt-link">delete</span>}
                actions={[
                  {
                    label: "Cancel",
                    close: true
                  },
                  {
                    label: "Delete",
                    func: () => {
                      deleteResource(r.gid);
                    }
                  }
                ]}
              />
              )
            </div>
          ))}
        </div>
      );
    }
    return (
      <div>
        <p>
          <i>No resources uploaded for session.</i>
        </p>
      </div>
    );
  };

  // the datetime picker uses JS Date objects which makes timezones a royal pain.
  // Here we are making a Date object that uses the "local" time but transposed
  // into the user's timezone. This is OK because the form submit ignores the timezone
  // This is all to make the datetime picker cooperate.
  const dateTimeInitialValue = () => {
    if (session.date_and_time_local) {
      // HACK: hardcoded to Pacific time
      // const dateWithoutZone = session.date_and_time_local.split("-07:00")[0];
      const dateWithoutZone = session.date_and_time_local.split(".")[0];
      return new Date(dateWithoutZone);
    }
    if (event.date_begin) {
      return parseISO(event.date_begin);
    }
    return new Date();
  };

  /* eslint-disable dot-notation */
  const assignAllValue = () => {
    if (!session.data) {
      return false;
    }

    return session.data["assign_all"] ? session.data["assign_all"] : false;
  };
  /* eslint-enable dot-notation */

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        on_demand: session.on_demand,
        date_and_time: dateTimeInitialValue(),
        length_minutes: session.length_minutes,
        capacity: session.capacity,
        presentation_type: session.presentation_type,
        meeting_url: session.meeting_url,
        enrollment: session.enrollment,
        session_room_id: session.session_room_id,
        data: {
          assign_all: assignAllValue()
        }
      };
    }
    return EventSessionBlankSession;
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        session: formInitialValues()
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);

        // convert times to UTC for submission to back-end
        formData.set(
          "session[date_and_time]",
          zonedTimeToUtc(values.session.date_and_time, event.time_zone)
        );
          <GrowlModal
          content="Are you sure you want to delete this resource?"
          title="Delete Resource"
          trigger={<span className="sg-mgmt-link">delete</span>}
          actions={[
            {
              label: "Cancel",
              close: true
            },
            {
              label: "Delete",
              func: () => {
                deleteResource(r.gid);
              }
            }
          ]}
        />
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              <h2>Session Presentation Type</h2>
              {renderPresentationTypeField()}
            </div>
            {renderLocationField(values.session.presentation_type)}
            <div className="sg-mgmt-form-section">
              <h2>Session Date and Time</h2>
              <div className="sg-mgmt-form-row">{renderOnDemand()}</div>
              <div className="sg-mgmt-form-row">
                {renderDateTimeField(
                  `Date and Start Time (timezone: ${event.time_zone})`,
                  "date_and_time",
                  "sg-mgmt-form-input-date-time pr-8"
                )}
                {renderIntegerField("Length (minutes)", "length_minutes")}
              </div>
              <GrowlModal
                content="Are you sure you want to delete this resource?"
                title="Delete Resource"
                trigger={<span className="sg-mgmt-link">delete</span>}
                actions={[
                  {
                    label: "Cancel",
                    close: true
                  },
                  {
                    label: "Delete",
                    func: () => {
                      deleteResource(r.gid);
                    }
                  }
                ]}
              />
            </div>
            <div className="sg-mgmt-form-section">
              <h2>Enrollment</h2>
              {renderEnrollmentField()}
              {renderAssignAllField()}
            </div>
            <div className="sg-mgmt-form-section">
              <h2>Session Video</h2>
              {renderSessionVideo()}
            </div>
            {/* <div className="sg-mgmt-form-section"> */}
            {/*   <h2>Meeting Link</h2> */}
            {/*   {renderRequiredField("Meeting URL", "meeting_url")} */}
            {/* </div> */}
            <div className="sg-mgmt-form-section">
              <h2>Resources</h2>
              <div className="sg-mgmt-form-row">{renderResources()}</div>
              {renderResourceFields()}
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-link" onClick={addResourceField}>
                  + Add New Resource
                </div>
              </div>
            </div>
          </div>
          <Stack className="mt-4" spacing={2} direction="row">
            {renderSubmitButton(formConfig.saveButton, isSubmitting, {
              color: "secondary"
            })}
            {renderCancelButton("Cancel", cancel, {
              color: "secondary"
            })}
          </Stack>
        </Form>
      )}
    </Formik>
  );

  return <div className="max-w-screen-lg">{renderForm()}</div>;
};

EventSessionsFormDetails.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {}
};

EventSessionsFormDetails.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancel: PropTypes.func.isRequired
};

export default EventSessionsFormDetails;
