import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import EventDetailsBlankToken from "./EventDetailsBlankToken";
import EventDetailsTokenModal from "./EventDetailsTokenModal";
import EventDetailsTokensTable from "./EventDetailsTokensTable";
import EventDetailsTokenView from "./EventDetailsTokenView";

const EventDetailsTokens = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [tokens, setTokens] = useState([]);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalViewVisible, setModalViewVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [editToken, setEditToken] = useState(EventDetailsBlankToken);
  const [viewToken, setViewToken] = useState(EventDetailsBlankToken);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/details/tokens"));
        setTokens(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTokens();
  }, []);

  const modalAddOpen = () => {
    setModalAddVisible(true);
  };

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const modalEditOpen = () => {
    setModalEditVisible(true);
  };

  const modalEditReset = () => {
    setModalEditVisible(false);
  };

  const modalViewOpen = () => {
    setModalViewVisible(true);
  };

  const modalViewReset = () => {
    setModalViewVisible(false);
  };

  const addToken = token => {
    setTokens([token].concat(tokens));
  };

  const selectTokenToEdit = id => {
    setEditToken(tokens.find(t => t.id === id));
    modalEditOpen();
  };

  const selectTokenToView = id => {
    setViewToken(tokens.find(t => t.id === id));
    modalViewOpen();
  };

  const updateToken = token => {
    setTokens(tokens.map(t => (t.id === token.id ? token : t)));
  };

  const renderTokenTable = () => {
    if (fetched) {
      return (
        <EventDetailsTokensTable
          tokens={tokens}
          setEdit={selectTokenToEdit}
          setView={selectTokenToView}
        />
      );
    }
    return <Loading />;
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <PageHeader text="API Management" />
      {renderCreateButton("Create API Token", modalAddOpen)}
      {renderTokenTable()}
      <EventDetailsTokenModal
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create API Token"
        token={EventDetailsBlankToken}
        updateFunc={addToken}
      />
      <EventDetailsTokenModal
        modalVisible={modalEditVisible}
        resetModal={modalEditReset}
        title="Edit API Token"
        token={editToken}
        updateFunc={updateToken}
      />
      <EventDetailsTokenView
        modalVisible={modalViewVisible}
        resetModal={modalViewReset}
        title={viewToken.name}
        token={viewToken}
      />
    </div>
  );
};

export default EventDetailsTokens;
