import { useQuery } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchHousingBlocks = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/housing/blocks")
  });
};

const fetchHousingHotels = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/housing/hotels")
  });
};

const fetchHousingBillingInstructions = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/housing/billing_instructions")
  });
};

export const useHousingBlocks = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["housingBlocks", eventId],
    queryFn: () => fetchHousingBlocks(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useHousingHotels = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["housingHotels", eventId],
    queryFn: () => fetchHousingHotels(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useHousingBillingInstructions = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["housingBilling", eventId],
    queryFn: () => fetchHousingBillingInstructions(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};
