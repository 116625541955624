export default {
  meeting_url: "",
  meeting_type: "",
  date_and_time: null,
  date_and_time_local: null,
  length_minutes: 45,
  capacity: 0,
  data: {
    objective_1: "",
    objective_2: "",
    objective_3: "",
    outcome: "",
    use_case: "",
    company_overview: "",
    attendee_details: {},
    stage_account: "",
    potential_revenue: "",
    close_date: "",
    notes: ""
  },
  meeting_hosts: [],
  attendees: []
};
