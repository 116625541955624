import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Bookmarks as BookmarksIcon, UploadFile as UploadFileIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router-dom";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertInfo, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import PeopleEventParticipantsModal from "./PeopleEventParticipantsModal";
import PeopleEventParticipantsViewModal from "./PeopleEventParticipantsViewModal";
import PeopleEventParticipantsTagDialog from "./tagging/PeopleEventParticipantsTagDialog";

const PeopleEventParticipantIndex = (props) => {
  const { setTab } = props;
  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const growlTableRef = useRef();

  const [editParticipantId, setEditParticipantId] = useState(null);
  const [viewParticipantId, setViewParticipantId] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [tagDialogVisible, setTagDialogVisible] = useState(false);
  const [selectedForTagging, setSelectedForTagging] = useState([]);

  const participantQuery = useQuery({
    queryKey: ["participants"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/table"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const individualQuery = useQuery({
    queryKey: ["individuals"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(rootUrl, "/-/individuals"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const metaQuery = useQuery({
    queryKey: ["participantMeta"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/meta"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const refreshParticipants = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("participants");
    }
  });

  // TODO: Move axios code from form submit handlers into these mutations,
  // pass them down to the form submit handlers. Can then use the values
  // being passed in to define optimistic updates in an onMutate handler.
  //
  // Tricky part: the axios callbacks call methods that exist on the form/modal.
  // Have to wrap them in a function and pass the function as a value to onSuccess
  // somehow? Look into this.
  const addParticipant = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, participant) => {
      queryClient.setQueryData(["participants"], (existing) => {
        const newParticipants = [...existing.participants, participant];
        return { participants: newParticipants };
      });
    }
  });

  const updateParticipant = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, participant) => {
      queryClient.cancelQueries({ queryKey: ["participants"] });
      queryClient.setQueryData(["participants"], (existing) => {
        const newParticipants = existing.participants.map((p) => (p.gid === participant.gid ? participant : p));
        return { participants: newParticipants };
      });
    }
  });

  const addIndividual = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_result, individual) => {
      queryClient.setQueryData(["individuals"], (existing) => {
        const newIndividuals = [...existing.individuals, individual];
        return { individuals: newIndividuals };
      });
    }
  });

  const addIndividualCallback = (individual) => {
    addIndividual.mutate(individual);
    refreshParticipants.mutate();
  };

  const performDelete = (id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `participants/${id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess("Participant deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const deleteParticipant = useMutation({
    mutationFn: (id) => {
      performDelete(id);
    },
    onSuccess: (_result, participantId) => {
      queryClient.setQueryData(["participants"], (existing) => ({
        participants: existing.participants.filter((p) => p.id !== participantId)
      }));
    }
  });

  const viewPersonClick = (id) => {
    setViewParticipantId(id);
    setViewModalVisible(true);
  };

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const goToSettings = () => {
    setTab(1);
  };

  const confirmDelete = (id) => {
    confirm({
      title: "Confirm delete",
      description: "Are you sure you want to delete this Participant?"
    })
      .then(() => {
        deleteParticipant.mutate(id);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.participants_edit) {
      return false;
    }
    return true;
  };

  const deleteEnabled = () => {
    if (user.role === "basic" && !user.permission.participants_delete) {
      return false;
    }
    return true;
  };

  const renderViewAction = (id) => (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          viewPersonClick(id);
        }}
      >
        View
      </span>
    </>
  );

  const renderEditAction = (id) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderDeleteAction = (id) => {
    if (!deleteEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            confirmDelete(id);
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const columns = [
    {
      field: "name_first",
      headerName: "First Name"
    },
    {
      field: "name_last",
      headerName: "Last Name"
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200
    },
    {
      field: "company",
      headerName: "Company"
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 70
    },
    {
      field: "type",
      headerName: "Participant Type",
      renderCell: (params) => <span className="capitalize">{params.value}</span>
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: ["Preregistered", "Registered", "Cancelled", "Declined", "Disallowed"],
      valueGetter: (params) => {
        const str = params.value;
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
    {
      field: "attended",
      headerName: "Attended",
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      }
    },
    {
      field: "vault_saved_at",
      headerName: "Payment Info?",
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      }
    },
    {
      field: "test_flag",
      headerName: "Tester",
      renderCell: (params) => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      }
    },
    {
      field: "ticketing_participant_tickets",
      headerName: "Tickets",
      minWidth: 200,
      renderCell: (params) => {
        if (params.value) {
          let tickets = {};
          params.value.forEach((participant_ticket) => {
            if (tickets[participant_ticket.ticket_type.name] === undefined) {
              tickets[participant_ticket.ticket_type.name] = 1;
            } else {
              tickets[participant_ticket.ticket_type.name] = tickets[participant_ticket.ticket_type.name] + 1;
            }
          });
          return Object.entries(tickets)
            .map(([k, v]) => `${k} (${v})`)
            .join(", ");
        }
        return "";
      }
    },
    metaQuery?.data?.metadataFields.flatMap((mf) => ({
      field: mf.slug,
      headerName: mf.label,
      valueGetter: (params) => {
        return params?.row?.metadata?.find((md) => md?.field_slug == mf.slug)?.value;
      }
    })),
    {
      field: "tags",
      headerName: "Tags"
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      minWidth: 180,
      getActions: (params) => [
        renderViewAction(params.row.id),
        renderEditAction(params.row.id),
        renderDeleteAction(params.row.id)
      ]
    }
  ].flat();

  const openAddModal = () => {
    setAddModalVisible(true);
  };

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const resetViewModal = () => {
    closeViewModal();
    setViewParticipantId(null);
  };

  const filteredIndividuals = () => {
    const participantIndividualIds = participantQuery.data.participants.map((p) => p.individual_id);
    return individualQuery.data.individuals.filter((ind) => !participantIndividualIds.includes(ind.id));
  };

  const assignTagHandler = () => {
    const selected = growlTableRef.current.getSelectedRows();

    if (selected.length === 0) {
      alertInfo("To assign tags, please select participants first.");
      return;
    }

    // fetch GIDs for participants and put in state for dialog
    const participantGids = participantQuery.data.participants.filter((p) => selected.includes(p.id)).map((p) => p.gid);
    setSelectedForTagging(participantGids);
    setTagDialogVisible(true);
  };

  const renderAddParticipantButton = () => {
    if (!editEnabled()) {
      return <></>;
    }

    return renderCreateButton("Add Participant", openAddModal);
  };

  const renderTagDialog = () => (
    <PeopleEventParticipantsTagDialog
      goToSettings={goToSettings}
      open={tagDialogVisible}
      onClose={() => setTagDialogVisible(false)}
      selectedParticipants={selectedForTagging}
      tags={metaQuery.data.tags}
      onSuccess={() => {
        refreshParticipants.mutate();
      }}
    />
  );

  const renderAddModal = () => (
    <PeopleEventParticipantsModal
      apiRoot={apiRoot}
      closeModal={closeAddModal}
      addIndividual={addIndividualCallback}
      individuals={filteredIndividuals()}
      metadataFields={metaQuery.data.metadataFields}
      regFields={metaQuery.data.regFields}
      modalVisible={addModalVisible}
      resetModal={resetAddModal}
      rootUrl={rootUrl}
      tags={metaQuery.data.tags}
      types={metaQuery.data.types}
      updateFunc={(participant) => {
        addParticipant.mutate(participant);
      }}
    />
  );

  const renderViewModal = () => {
    if (!viewParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsViewModal
        apiRoot={apiRoot}
        participantId={viewParticipantId}
        metadataFields={metaQuery.data.metadataFields}
        modalVisible={viewModalVisible}
        regFields={metaQuery.data.regFields}
        resetModal={resetViewModal}
      />
    );
  };

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={addIndividualCallback}
        individuals={[]}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metaQuery.data.metadataFields}
        regFields={metaQuery.data.regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={metaQuery.data.tags}
        types={metaQuery.data.types}
        updateFunc={(participant) => {
          updateParticipant.mutate(participant);
        }}
      />
    );
  };

  const renderBody = () => {
    if (participantQuery.isPending || individualQuery.isPending || metaQuery.isPending) {
      return <Loading />;
    }

    if (participantQuery.error || individualQuery.error || metaQuery.error) {
      return <div>Error loading data. Please refresh and try again.</div>;
    }

    const defaultColumnVisibility = Object.fromEntries([
      ["vault_saved_at", false],
      ...metaQuery.data.metadataFields.map((mf) => [mf.slug, false])
    ]);

    return (
      <>
        <div>{renderAddParticipantButton()}</div>
        <div className="mt-2">
          <GrowlTable
            checkboxSelection
            columns={columns}
            componentRef={growlTableRef}
            defaultColumnVisibility={defaultColumnVisibility}
            items={participantQuery.data.participants}
            tableName={`${event.slug}-participants`}
            toolbarControls={[
              <Link to="/participants/import" key="button-import">
                <Button size="small" startIcon={<UploadFileIcon size="small" />}>
                  Import
                </Button>
              </Link>,
              <Button
                size="small"
                key="button-tags"
                onClick={assignTagHandler}
                startIcon={<BookmarksIcon size="small" />}
              >
                Assign Tags
              </Button>
            ]}
          />
        </div>
        {renderAddModal()}
        {renderEditModal()}
        {renderViewModal()}
        {renderTagDialog()}
      </>
    );
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <PageHeader text="Participants" />
      {renderBody()}
    </div>
  );
};

PeopleEventParticipantIndex.propTypes = {
  setTab: PropTypes.func.isRequired
};

export default PeopleEventParticipantIndex;
