import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const EventSessionTagEdit = ({ setEditTag, updateTag, tag }) => {
  const inputField = useRef(null);

  // submit on enter key
  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (inputField.current === document.activeElement) {
          submitTag();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const submitTag = () => {
    const name = inputField.current.value;
    updateTag(tag.id, name);
    setEditTag(null);
  };

  return (
    <div
      className="flex-initial py-1 px-2 mr-2 mb-1 text-white bg-opacity-70 bg-sg-orange"
      key={`tag-${tag.gid}-${tag.id}`}
    >
      <input
        ref={inputField}
        type="text"
        name="tag_name"
        defaultValue={tag.name}
      />
      <div
        className="cursor-pointer"
        style={{ display: "inline", padding: "0 4px" }}
        onClick={() => setEditTag(null)}
      >
        <i className="fas fa-solid fa-ban" />
      </div>
      <div
        className="cursor-pointer"
        style={{ display: "inline", padding: "0 4px" }}
        onClick={() => submitTag()}
      >
        <i className="fas fa-solid fa-check" />
      </div>
    </div>
  );
};

EventSessionTagEdit.propTypes = {
  setEditTag: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired
};

export default EventSessionTagEdit;
