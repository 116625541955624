import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchHousingHotel = async (apiRoot, id) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, `/housing/hotels/${id}`)
  });
};

const fetchHousingHotels = async (apiRoot) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, "/housing/hotels")
  });
};

const fetchHousingHotelAmenities = async (apiRoot, hotelId) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/amenities`)
  });
};

const fetchHousingHotelRoomTypes = async (apiRoot, hotelId) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/room_types`)
  });
};

const fetchHousingHotelRoomTypeDates = async (apiRoot, hotelId, roomTypeId) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/room_types/${roomTypeId}/dates`)
  });
};

const addHousingHotel = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/housing/hotels`),
    data: data
  });
};

const addHousingHotelAmenity = async (apiRoot, hotelId, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/amenities`),
    data: data
  });
};

const addHousingHotelRoomType = async (apiRoot, hotelId, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/room_types`),
    data: data
  });
};

const updateHousingHotel = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/hotels/${id}`),
    data: data
  });
};

const updateHousingHotelAmenity = async (apiRoot, hotelId, amenityId, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/amenities/${amenityId}`),
    data: data
  });
};

const updateHousingHotelRoomType = async (apiRoot, hotelId, roomTypeId, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/room_types/${roomTypeId}`),
    data: data
  });
};

const updateHousingHotelRoomTypeInventory = async (apiRoot, hotelId, roomTypeId, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/housing/hotels/${hotelId}/room_types/${roomTypeId}/dates/update_all`),
    data: data
  });
};

export const useAddHousingHotel = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addHousingHotel(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotels", eventId]);
    }
  });
};

export const useAddHousingHotelAmenity = (apiRoot, eventId, hotelId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addHousingHotelAmenity(apiRoot, hotelId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotelAmenities", eventId, hotelId]);
    }
  });
};

export const useAddHousingHotelRoomType = (apiRoot, eventId, hotelId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addHousingHotelRoomType(apiRoot, hotelId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotelRoomTypes", eventId, hotelId]);
    }
  });
};

export const useHousingHotel = (apiRoot, eventId, id) => {
  return useQuery({
    queryKey: ["housingHotels", eventId, id],
    queryFn: () => fetchHousingHotel(apiRoot, id),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useHousingHotels = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["housingHotels", eventId],
    queryFn: () => fetchHousingHotels(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useHousingHotelAmenities = (apiRoot, eventId, hotelId) => {
  return useQuery({
    queryKey: ["housingHotelAmenities", eventId, hotelId],
    queryFn: () => fetchHousingHotelAmenities(apiRoot, hotelId),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useHousingHotelRoomTypes = (apiRoot, eventId, hotelId) => {
  return useQuery({
    queryKey: ["housingHotelRoomTypes", eventId, hotelId],
    queryFn: () => fetchHousingHotelRoomTypes(apiRoot, hotelId),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useHousingHotelRoomTypeInventory = (apiRoot, eventId, hotelId, roomTypeId) => {
  return useQuery({
    queryKey: ["housingHotelRoomTypeInventories", eventId, hotelId, roomTypeId],
    queryFn: () => fetchHousingHotelRoomTypeDates(apiRoot, hotelId, roomTypeId),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useUpdateHousingHotel = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateHousingHotel(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotels", eventId]);
    }
  });
};

export const useUpdateHousingHotelAmenity = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ hotelId, amenityId, data }) => updateHousingHotelAmenity(apiRoot, hotelId, amenityId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotelAmenities", eventId]);
    }
  });
};

export const useUpdateHousingHotelRoomType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ hotelId, roomTypeId, data }) => updateHousingHotelRoomType(apiRoot, hotelId, roomTypeId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotelRoomTypes", eventId]);
    }
  });
};

export const useUpdateHousingHotelRoomTypeInventory = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ hotelId, roomTypeId, data }) =>
      updateHousingHotelRoomTypeInventory(apiRoot, hotelId, roomTypeId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["housingHotelRoomTypeInventories", eventId]);
    }
  });
};
