import React, { useContext } from "react";

import { Stack } from "@mui/material";
import { Formik, Form } from "formik";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderTextField, renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { useAddHousingBlock, useUpdateHousingBlock } from "@shared/hooks/useHousingBlocks";
import { requiredProp } from "@shared/PropUtils";

import HousingBlankRoomBlock from "./HousingBlankRoomBlock";

const HousingRoomBlockForm = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { callbackFailure = () => { }, callbackSuccess = () => { }, cancelButton = () => { }, block } = props;
  const addHousingBlock = useAddHousingBlock(apiRoot, event.id);
  const updateHousingBlock = useUpdateHousingBlock(apiRoot, event.id);
  requiredProp("block", block);

  const isEdit = () => {
    return block && block.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        formId: "sg-mgmt-form-token-edit",
        saveButton: "Update"
      };
    }
    return {
      formId: "sg-mgmt-form-token-add",
      saveButton: "Create"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: block.name || "",
        code: block.code || "",
        owner_name_first: block.owner_name_first || "",
        owner_name_last: block.owner_name_last || "",
        owner_email: block.owner_email || "",
        notes: block.notes || ""
      };
    }
    return HousingBlankRoomBlock;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          block: formInitialValues()
        }}
        onSubmit={({ setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);

          if (isEdit()) {
            updateHousingBlock.mutate(
              {
                id: block.id,
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                  } else {
                    callbackFailure();
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertHttpError(error);
                }
              }
            );
          } else {
            addHousingBlock.mutate(
              {
                data: formData
              },
              {
                onSuccess: (data) => {
                  if (data.error === null) {
                    callbackSuccess();
                  } else {
                    callbackFailure();
                    setSubmitting(false);
                  }
                },
                onError: (error) => {
                  alertHttpError(error);
                }
              }
            );
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">{renderTextField("Room Block Name", "block[name]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Code", "block[code]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Owner First Name", "block[owner_name_first]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Owner Last Name", "block[owner_name_last]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Owner Email", "block[owner_email]")}</div>
              <div className="sg-mgmt-form-row">{renderTextField("Notes", "block[notes]")}</div>
            </div>
            <Stack spacing={2} direction="row">
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default HousingRoomBlockForm;
