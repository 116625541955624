import React from "react";

import ContentFrame from "@shared/ContentFrame";

import EventDetailsIntegrations from "../details/integrations/EventDetailsIntegrations";
import EventDetailsTokens from "../details/tokens/EventDetailsTokens";

const EventApis = () => {
  return (
    <ContentFrame>
      <EventDetailsTokens />
      <EventDetailsIntegrations />
    </ContentFrame>
  );
};

export default EventApis;
