import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { renderCancelButton } from "@shared/FormUtils";
import EventSessionContext from "./EventSessionContext";

const EventSessionGeneralInfoView = props => {
  const { config, session } = useContext(EventSessionContext);
  const { setMode } = props;
  const renderEdit = () => {
    return (
      <div className="sg-mgmt-session-details-edit">
        <div className="sg-mgmt-link" onClick={() => setMode("edit")}>
          Edit
        </div>
      </div>
    );
  };

  const renderCode = () => {
    if (config.enabled_code) {
      return (
        <div>
          <span className="sg-mgmt-info-label">Code:&nbsp;</span>
          {session.code}
        </div>
      );
    }
    return <></>;
  };

  const renderSessionType = () => (
    <div>
      <span className="sg-mgmt-info-label">Session Type:&nbsp;</span>
      {session.session_type_name}
    </div>
  );

  const renderTrack = () => {
    if (config.enabled_track) {
      return (
        <div>
          <span className="sg-mgmt-info-label">Session Track:&nbsp;</span>
          {session.session_tracks.join(", ")}
        </div>
      );
    }
    return <></>;
  };

  const renderTags = () => {
    return (
      <div>
        <span className="sg-mgmt-info-label">Tags:&nbsp;</span>
        {session.session_tags.join(", ")}
      </div>
    );
  };

  const renderBackButton = () => (
    <div className="sg-mgmt-modal-actions">
      <Link to="/sessions">
        {renderCancelButton("Back to Session", () => {})}
      </Link>
    </div>
  );

  const viewSession = () => {
    if (!session) { return <></>; }
    return (
      <div>
        {renderEdit()}
        <div className="sg-mgmt-session-details-wrapper">
          <div className="sg-mgmt-info-label">Session Title:</div>
          <div className="sg-mgmt-session-title">{session.title}</div>
        </div>
        <div className="sg-mgmt-session-details-wrapper">
          <div className="sg-mgmt-info-label">Description:</div>
          <div>{parse(session.description)}</div>
        </div>
        <div className="sg-mgmt-session-details-wrapper">
          {renderCode()}
          {renderSessionType()}
          {renderTrack()}
          {renderTags()}
        </div>
        {renderBackButton()}
      </div>
    );
  }

  return <>{viewSession()}</>;
};

EventSessionGeneralInfoView.propTypes = {
  setMode: PropTypes.func.isRequired
};

export default EventSessionGeneralInfoView;
