import React from "react";

import { useParams, useHistory } from "react-router-dom";

import ContentFrame from "@shared/ContentFrame";

import FormsFormsForm from "./forms/FormsFormsForm";

const FormsEdit = () => {
  const history = useHistory();
  const { id, form_type } = useParams();

  return (
    <ContentFrame>
      <FormsFormsForm
        formId={id != "new" ? id : null}
        cancel={() => history.push(`/forms/${form_type}`)}
        form_type={form_type}
      />
    </ContentFrame>
  );
};

export default FormsEdit;
