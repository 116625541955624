import React from "react";

import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";

import CustomReportKey from "./CustomReportKey";

const CustomReportKeys = (props) => {
  const { updateKeys, selectedKeys } = props;
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  console.log(`render ReportKeys with ${selectedKeys}`);

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      const oldIndex = selectedKeys.findIndex((item) => item.value === active.id);
      const newIndex = selectedKeys.findIndex((item) => item.value === over.id);
      const newKeys = arrayMove(selectedKeys, oldIndex, newIndex);
      updateKeys(newKeys);
    }
  };

  const removeKey = (key) => {
    let newSelectedKeys = selectedKeys;
    if (selectedKeys.some((e) => e.value === key.value)) {
      newSelectedKeys = newSelectedKeys.filter(function(e) {
        return e.value !== key.value;
      });
      updateKeys(newSelectedKeys);
    }
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <div>
        <SortableContext items={selectedKeys}>
          {selectedKeys.map((item) => (
            <CustomReportKey
              key={`key-drag-${item.value}`}
              item={item}
              removeKey={removeKey}
            />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default CustomReportKeys;
