import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import EventSpeakersBlankSpeaker from "./EventSpeakersBlankSpeaker";
import EventSpeakersForm from "./EventSpeakersForm";

const EventSpeakersModal = props => {
  const { modalVisible, resetModal, speaker, updateFunc } = props;

  Modal.setAppElement("#root");

  const modalTitle = () => {
    if (speaker && speaker.id) {
      return `Speaker: ${speaker.name_first} ${speaker.name_last}`;
    }
    return "Add Speaker";
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Speaker"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{modalTitle()}</div>
        <div className="sg-mgmt-modal-content">
          <EventSpeakersForm
            reset={resetModal}
            speaker={speaker}
            update={updateFunc}
          />
        </div>
      </div>
    </Modal>
  );
};

EventSpeakersModal.defaultProps = {
  speaker: EventSpeakersBlankSpeaker
};

EventSpeakersModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  speaker: PropTypes.object,
  updateFunc: PropTypes.func.isRequired
};

export default EventSpeakersModal;
