import React, { useContext } from "react";
import PropTypes from "prop-types";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
const EventMenuExpandRetract = ({ menuExpanded, onClick }) => {

    return (
      <>
      <div
        className="mt-[50px] sg-mgmt-menu-nav-link sg-mgmt-menu-expand-retract "
        onClick={onClick}
      >
        <div className="sg-mgmt-menu-item flex items-center">
          <div className="flex h-[50px] w-[50px] shrink-0 items-center justify-center">
            {menuExpanded ? (
              <CloseIcon
                className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px] cursor-pointer"
                alt={`Expand Retract Menu`}
              />
            ):(
              <MenuIcon
                className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px] cursor-pointer"
                alt={`Expand Retract Menu`}
              />
            )}
      
          </div>
          <div className="sg-mgmt-menu-item-title font-['Roboto'] text-base font-medium">
            {menuExpanded ? "Collapse" : "Expand"} Menu
          </div>
        </div>
      </div>
      </>
    );
};

EventMenuExpandRetract.propTypes = {
  menuExpanded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EventMenuExpandRetract;
