import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckFieldSmall from "@shared/CheckFieldSmall";
const CheckFieldSmallDisabled = (props) => {
  const checkMark = () => {
    return checked ? (
      <div className="sg-mgmt-checkbox-disabled-checked" />
    ) : (
      <></>
    );
  };

  return (
    <CheckFieldSmall {...props} disabled={true}/>
  );
};

CheckFieldSmallDisabled.propTypes = {
  label: PropTypes.string.isRequired
};

export default CheckFieldSmallDisabled;
