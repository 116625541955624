import React, { useContext, useState } from "react";
import { isDeveloper } from "@shared/UserUtils";
import EventUserContext from "@event/EventUserContext";
import EventAnalyticsExternalReportForm from "./EventAnalyticsExternalReportForm";
import EventAnalyticsReportList from "./EventAnalyticsReportList";
import { renderButton } from "@shared/FormUtils";

const EventAnalyticsReports = () => {
  const [addExternal, setAddExternal] = useState(false);
  const [editExternal, setEditExternal] = useState(false);
  const { user } = useContext(EventUserContext);

  const renderAddButton = () => {
    if (isDeveloper(user)) {
      return (
        renderButton("Add External Report", () => setAddExternal(true))
      );  
    }
    return <></>;
  };

  const returnToReports = () => {
    setAddExternal(false);
  };

  const finishEdit = () => {
    setEditExternal(false);
    returnToReports();
  };

  const editReport = report => {
    setEditExternal(report);
  };

  const renderContent = () => {
    if (addExternal) {
      return (
        <>
          <EventAnalyticsExternalReportForm
            cancel={returnToReports}
            callbackSuccess={returnToReports}
          />
        </>
      );
    }

    if (editExternal) {
      return (
        <>
          <EventAnalyticsExternalReportForm
            cancel={finishEdit}
            callbackSuccess={finishEdit}
            externalReport={editExternal}
          />
        </>
      );
    }

    return (
      <>
        {renderAddButton()}
        <EventAnalyticsReportList
          canEditExternal={isDeveloper(user)}
          editReport={editReport}
        />
      </>
    );
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default EventAnalyticsReports;
